import styled from "styled-components"

export const CharlieButton = styled.div`
	position: relative;
	width: calc(100% - 40px);
	background: #ffd1da;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 24px rgba(88, 86, 214, 0.12);
	border-radius: 16px;
	color: #1c1b47;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;

	text-align: center;
	letter-spacing: -0.03em;

	padding: 14px;
	box-sizing: border-box;

	cursor: pointer;

	@media (orientation: landscape) {
		width: 360px;
	}
`

export const CharlieDesktopWrapper = styled.div`
	width: 100%;
	height: auto;
	position: relative;

	@media (orientation: portrait) {
		display: none;
	}
`

export const MainInfoDesktop = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	background: #32237d;
`

export const MainContentDesktop = styled.div`
	width: 50%;

	padding: 140px;

	display: flex;
	flex-direction: column;
	justify-content: center;

	z-index: 7600;

	> p {
		font-family: Inter;
		font-style: normal;
		font-weight: bold;
		font-size: 40px;
		line-height: 110%;
		/* or 44px */

		letter-spacing: -0.04em;

		color: #ffffff;
	}

	> small {
		font-family: Inter;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 34px;
		/* or 142% */

		letter-spacing: -0.03em;

		color: #fefefc;

		opacity: 0.6;
	}
`
export const ScreensDesktop = styled.div`
	/* position: relative; */
	height: 100%;
	width: 50%;
	/* max-width: 300px; */

	> img:first-child {
		position: absolute;
		/* bottom: -200px; */
		top: 50px;
		right: 10%;
		height: auto;
		width: 50%;
		max-width: 400px;
		/* height: calc(100% + 160px);
		width: auto; */
		z-index: 7500;

		@media (min-width: 1000px) {
			right: 16%;
		}
	}
	> img:last-child {
		position: absolute;
		right: 0;
		/* bottom: 30px; */
		top: 0;
		height: auto;
		/* height: calc(100% - 30px); */
		width: 50%;
		max-width: 600px;
		z-index: 700;
	}
`

export const LinesDesktop = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	z-index: 0;

	> img:first-child {
		position: absolute;
		top: 0;
		left: 0;
		height: auto;
		width: 50%;
		/* z-index: 800; */
	}
	> img:last-child {
		position: absolute;
		left: 70px;
		bottom: 0;
		height: auto;
		width: 60%;
		z-index: 700;
	}
`

export const FeaturesDesktop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	> img {
		width: auto;
		height: auto;
		max-height: 800px;
		/* margin-left: 160px; */
		margin-right: 20px;
		z-index: 9999;
	}
`

export const FactsDesktop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	> div {
		width: 388px;
		height: 400px;
		margin: 0 8px;
	}
`

export const BlueButtonDesktop = styled(CharlieButton)`
	background: #6967ec;
	color: #fff;
	position: relative;
	width: 480px;

	margin-top: 80px;

	transition: bottom 0.3s;

	z-index: 9000;
`

export const FloatingButtonDesktop = styled.div`
	z-index: 9999;
	position: fixed;
	top: 100px;
	/* right: 20px; */
	right: -200px;
	width: 191px;
	height: 50px;

	padding: 6px 12px;

	text-align: center;

	background: #5856d6;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 16px;

	font-family: Inter;
	font-style: normal;
	font-weight: 600;
	font-size: 24px;
	line-height: 38px;

	letter-spacing: -0.03em;

	color: #ffffff;

	box-sizing: border-box;
	cursor: pointer;

	transition: right 0.3s;
`
