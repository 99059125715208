import BlackSpinner from "@assets/BlackSpinner.svg"
import WhiteSpinner from "@assets/WhiteSpinner.svg"

import { LayoutType } from "@components/shared/layouts"
import { forwardRef, PropsWithChildren, ReactNode } from "react"

import { Spinner, BlueButton, PinkButton } from "./action-button.styles"

export enum State {
	Regular = "Regular",
	Loading = "Loading",
	Disabled = "Disabled",
}

export enum Appearance {
	Blue = "Blue",
	Pink = "Pink",
}

export interface IActionButtonProps {
	children: ReactNode
	onClick: (evt?: any) => void
	isStretch?: boolean
	state?: State
	appearance?: Appearance
	size?: keyof typeof LayoutType
}

export const ActionButton = forwardRef<
	unknown,
	PropsWithChildren<IActionButtonProps & JSX.IntrinsicElements["button"]>
>(
	(
		{ appearance = Appearance.Blue, state = State.Regular, onClick = () => void 0, size = LayoutType.Mobile, ...rest },
		ref: any
	) => {
		switch (appearance) {
			default:
			case Appearance.Blue:
				return (
					<BlueButton
						ref={ref}
						size={size}
						state={state}
						onClick={state === State.Regular ? onClick : () => void 0}
						{...rest}
					>
						{state === State.Loading ? <Spinner alt="" src={WhiteSpinner} /> : rest.children}
					</BlueButton>
				)
			case Appearance.Pink:
				return (
					<PinkButton
						ref={ref}
						size={size}
						state={state}
						onClick={state === State.Regular ? onClick : () => void 0}
						{...rest}
					>
						{state === State.Loading ? <Spinner alt="" src={BlackSpinner} /> : rest.children}
					</PinkButton>
				)
		}
	}
)
