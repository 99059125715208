// import { firestore } from "~/services/firebase/firebase-app"
import { firestore } from "~/services/firebase/firebase-app"
import { PAYPAL_SUBSCRIPTIONS } from "./paypal-firestore-collections"
import {
	PaypalSubscriptionDoc,
	PaypalSubscriptionDocContainer,
	PaypalSubscriptionDocConverter,
	PaypalSubscriptionDocField,
} from "./paypal-subscription-doc"

/**
 * Returns current user's subscription records or emty array.
 * if subscription has never been created then return an empty array.
 */
export const paypalSubscriptionGetDocs = async (userId: string): Promise<PaypalSubscriptionDocContainer[]> => {
	try {
		const query = firestore.collection(PAYPAL_SUBSCRIPTIONS).where(PaypalSubscriptionDocField.userId, "==", userId)
		const snapshots = await query.withConverter<PaypalSubscriptionDoc>(PaypalSubscriptionDocConverter).get()
		const docs: PaypalSubscriptionDocContainer[] = []

		snapshots.forEach((snapshot) => {
			const docId = snapshot.id
			const doc = snapshot.data()
			const container: PaypalSubscriptionDocContainer = {
				id: docId,
				doc,
			}
			docs.push(container)
		})

		return docs
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}
