import firebaseNameSpace from "firebase/app"
import { firestore } from "~/services/firebase/firebase-app"
// import { firestore } from "~/services/firebase/firebase-app"

/**
 * Writes the document referred to by this `collectionPath` and `documentPath`.
 * If the document does not yet exist, it will be created.
 * If you pass merge=true, the provided data can be merged into an
 * existing document.
 * @param collectionPath A slash-separated path to a collection.
 * @param documentPath A slash-separated path to a document.
 */

export const setFirestoreDocument = async <T = firebaseNameSpace.firestore.DocumentData>(
	collectionPath: string,
	documentPath: string | undefined,
	data: Partial<T>,
	merge: boolean
): Promise<string> => {
	const collectionRef = firestore.collection(collectionPath)

	let documentRef: firebaseNameSpace.firestore.DocumentReference<T>
	if (documentPath && documentPath.length > 0) {
		documentRef = collectionRef.doc(documentPath) as firebaseNameSpace.firestore.DocumentReference<T>
	} else {
		documentRef = collectionRef.doc() as firebaseNameSpace.firestore.DocumentReference<T>
	}

	await documentRef.set(data, {
		merge,
	})

	return documentRef.id
}
