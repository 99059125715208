import { Key, useMemo } from "react"
import { destructuringWithGroupsHelper } from "~/utils/destructuring-with-groups-helper"
import { StylableProps } from "~/utils/types"
import * as A from "./atoms"
import { CarouselValueProps } from "./types"

export type CarouselDotsProps<T> = {
	items: T[]
	keyGetter?: (item: T, index: number) => Key
} & CarouselValueProps &
	StylableProps &
	StylableProps<"dot-">

export function CarouselDots<T>({ items, keyGetter, value, onValueChanged, ...props }: CarouselDotsProps<T>) {
	const { styleProps, dotProps } = useMemo(() => {
		return destructuringWithGroupsHelper<typeof props>()({
			styleProps: ["className", "style"],
			dotProps: [{ "dot-className": "className", "dot-style": "style" }],
		} as const)()
	}, [])(props)

	return (
		<A.Dots {...styleProps}>
			{items.map((item, i) => (
				<A.DotButton key={keyGetter?.(item, i) ?? i} {...dotProps} onClick={() => onValueChanged(i)}>
					<A.Dot active={i === ((value % items.length) + items.length) % items.length} />
				</A.DotButton>
			))}
		</A.Dots>
	)
}
