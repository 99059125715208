import Back from "@assets/Back.svg"
import { LayoutType } from "@components/shared/layouts"
import React, { forwardRef, PropsWithChildren } from "react"
import { RadioBack } from "./radio-back-button.styles"

export interface IRadioBackButtonProps {
	onClick: () => void
	isDisabled?: boolean
	size?: keyof typeof LayoutType
}

export const RadioBackButton = forwardRef<
	unknown,
	PropsWithChildren<IRadioBackButtonProps & JSX.IntrinsicElements["button"]>
>(({ onClick = () => void 0, size = "Mobile", isDisabled = false, ...rest }, ref: any) => {
	return (
		<RadioBack size={size} isDisabled={isDisabled} onClick={!isDisabled ? onClick : () => void 0}>
			<img alt="" src={Back} />
		</RadioBack>
	)
})
