import { isPaypalSubscriptionCurrent } from "./domain/paypal-subscription-status"
import { PaypalSubscriptionDocContainer } from "./firestore/paypal-subscription-doc"
import { paypalSubscriptionGetDocs } from "./firestore/paypal-subscription-get-docs"

export const paypalSubscriptionGetCurrent = async (userId: string): Promise<PaypalSubscriptionDocContainer[]> => {
	try {
		const currentSubscriptions: PaypalSubscriptionDocContainer[] = []

		const docs = await paypalSubscriptionGetDocs(userId)

		docs.forEach((value) => {
			if (isPaypalSubscriptionCurrent(value.doc.subscriptionStatus)) {
				currentSubscriptions.push(value)
			}
		})

		return currentSubscriptions
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}
