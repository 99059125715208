import React, { RefObject } from "react"
import styled, { css } from "styled-components"

import { TextBody } from "../Text"

interface IInput {
	validationMessage?: string
	width?: string
	height?: string
	maxLength?: number
}

export interface IInputProps extends IInput {
	value: string
	name?: string
	defaultValue?: string
	type: "login" | "password" | "email" | "other"
	onChange: any
	inputRef?: RefObject<HTMLInputElement>
	textareaRef?: RefObject<HTMLTextAreaElement>
	placeholder?: string
	readonly?: boolean
}

const Wrapper = styled.div`
	position: relative;
	margin: 0;

	> p {
		position: absolute;
		display: block;
		width: 100%;
		top: 56px;
		text-align: center;
		color: #fd3e3e;
	}
`

// TODO: refactor reccuring code

const StyledInput = styled.input<IInput>`
	position: relative;

	display: block;
	width: ${({ width }) => width || "335px"};
	height: ${({ height }) => height || "56px"};

	@media (orientation: landscape) {
		width: ${({ width }) => width || "480px"};
		height: ${({ height }) => height || "64px"};
	}

	@media (max-width: 320px) {
		width: 280px;
	}

	margin: 8px 0;
	padding: 20px 14px;
	border-radius: 16px;
	background-color: #ffffff;

	font-family: Inter, sans-serif;
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;

	box-sizing: border-box;
	border: none;
	outline: none;

	:focus {
		caret-color: #5856d6;
		color: black;
	}

	::placeholder {
		color: #b7b9c0;
	}

	${({ validationMessage }) =>
		validationMessage && validationMessage.length > 0
			? css`
					color: #fd3e3e;
			  `
			: ""}
`

const StyledTextarea = styled.textarea<IInput>`
	position: relative;
	resize: none;

	display: block;
	width: ${({ width }) => width || "335px"};
	height: ${({ height }) => height || "56px"};

	@media (orientation: landscape) {
		width: ${({ width }) => width || "480px"};
		height: ${({ height }) => height || "56px"};
	}

	@media (max-width: 320px) {
		width: 280px;
	}

	margin: 8px 0;
	padding: 20px 14px;
	border-radius: 16px;
	background-color: #ffffff;

	font-family: Inter, sans-serif;
	font-size: 20px;
	line-height: 28px;
	font-weight: 500;

	box-sizing: border-box;
	border: none;
	outline: none;

	:focus {
		caret-color: #5856d6;
	}

	::placeholder {
		color: #b7b9c0;
	}

	${({ validationMessage }) =>
		validationMessage && validationMessage.length > 0
			? css`
					color: #fd3e3e;
			  `
			: ""}
`

export function Input(props: IInputProps) {
	return (
		<Wrapper>
			<StyledInput ref={props.inputRef} maxLength={props.maxLength} readonly={props.readonly} {...props} />
			{props.validationMessage && props.validationMessage.length > 0 && (
				<TextBody margin={props.type === "email" ? "100px 0" : "12px 0"} size="small">
					{props.validationMessage}
				</TextBody>
			)}
		</Wrapper>
	)
}

export function Textarea(props: IInputProps) {
	return (
		<Wrapper>
			<StyledTextarea ref={props.textareaRef} {...props} />
		</Wrapper>
	)
}
