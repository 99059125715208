import { loadStripe } from "@stripe/stripe-js"

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
export const stripeAppPromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "")
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
	console.error("**Stripe publishable key environment variable not set**")
	console.error("**Add an environment variable REACT_APP_STRIPE_PUBLISHABLE_KEY**")
}
