import { background } from "@components/shared/colors"
import styled from "styled-components"

export const Screen = styled.div`
	position: absolute;

	margin: 0;
	padding: 0;

	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-width: 0;
	box-sizing: border-box;

	transition: left 0.4s;

	background-color: ${background.app}; // #32237D;
`

export const MobileScreen = styled(Screen)``

export const DesktopScreen = styled(Screen)`
	justify-content: center;
	align-items: center;

	height: calc(100% - 64px);
	bottom: none;
	top: 0;

	min-height: 700px;
	overflow-y: scroll;
`

export const ScreenContent = styled.div`
	position: relative;

	margin: 0;
	padding: 0;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-width: 0;
	box-sizing: border-box;

	overflow: hidden;
`

export const BackgroundImage = styled.img<{ z?: number }>`
	position: absolute;
	left: 0;
	top: 1px;

	width: 100%;
	height: auto;
	z-index: ${({ z }) => z ?? 0};

	visibility: visible;
`

export const BackgroundImageDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	//top: 0;
	bottom: 0;

	width: 100%;
	height: calc(100% - 40px);
	z-index: ${({ z }) => z ?? 1};

	background: linear-gradient(180deg, rgba(6, 6, 23, 0) 40.48%, rgba(6, 6, 23, 0.8) 93.45%);

	visibility: hidden;
`

export const PurpleBackgroundDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	top: 1px;

	margin: 0;
	padding: 0;

	width: 100%;
	height: 100%;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: ${({ z }) => z ?? 1};

	opacity: 0;

	transition: opacity 0.7s;

	background-color: ${background.meteorite};
`

export const PurpleQuotesFadeDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	bottom: 100px;

	width: 100%;
	height: 75px; //calc(100% - 40px);
	z-index: ${({ z }) => z ?? 1};

	background: linear-gradient(180deg, rgba(50, 35, 125, 0) 40.48%, rgba(50, 35, 125, 0.9) 93.45%);

	visibility: hidden;
`

export const FadeDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	bottom: 0;

	width: 100%;
	height: 100px;
	z-index: ${({ z }) => z ?? 1};

	background: linear-gradient(
		180deg,
		rgba(50, 35, 125, 0) 0%,
		#32237d 62%
	); // linear-gradient(180deg, rgba(50, 35, 125, 0) 40.48%, rgba(50, 35, 125, 0.9) 93.45%);

	visibility: hidden;
`

export const Mask = styled.div`
	position: relative;
	width: 600px;
	height: 600px;
	max-height: 100%;

	border-radius: 50%;
	overflow: hidden;

	z-index: 500;

	display: flex;
	justify-content: center;
	align-items: center;

	margin: 20px 0;

	box-sizing: border-box;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	> img {
		position: absolute;
		top: 0;
		width: 100%;
		height: auto;
		z-index: 100;
		margin-top: -100px;
		object-fit: cover;
	}

	@media (min-width: 1800px) and (min-height: 840px) {
		width: 760px;
		height: 760px;
	}

	@media (min-width: 2800px) and (min-height: 1200px) {
		width: 1000px;
		height: 1000px;
	}
`

export const DesktopQuote = styled.span`
	text-align: center;
	position: absolute;
	top: 0;
	left: calc(50% - 220px);
	width: 440px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 9000;

	opacity: 0;

	transition: opacity 0.7s;
`

export const DesktopShadow = styled.div`
	position: absolute;
	bottom: -200px;
	left: 0;
	width: 100%;
	height: 140px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f6f6f6 27.5%);

	z-index: 1000;

	transition: bottom 0.7s;

	> button {
		opacity: 0;
		transition: opacity 0.7s;
	}
`

export const DesktopLineWrapper1 = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 640px;
	height: auto;
	z-index: 9999;
	opacity: 0;
	transition: opacity 0.4s;
`

export const DesktopLineWrapper2 = styled.div`
	position: absolute;
	bottom: 50px;
	right: -100px;
	width: 500px;
	height: auto;
	z-index: 9999;
	opacity: 0;
	transition: opacity 0.4s;
`

export const MobileButtonWrapper = styled.div<{ z?: number }>`
	position: absolute;
	width: 100%;
	left: 0;
	bottom: 44px;
	padding: 0 20px;
	box-sizing: border-box;

	display: flex;
	justify-content: center;
	align-items: center;

	opacity: 0;
	box-sizing: border-box;

	transition: opacity 0.3s;

	z-index: ${({ z }) => z ?? 500};
`

export const DesktopPurpleBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 110%;
	background: linear-gradient(180deg, rgba(246, 246, 246, 0) 55.21%, #f6f6f6 100%),
		linear-gradient(0deg, #32237d, #32237d),
		linear-gradient(180deg, rgba(6, 6, 23, 0) 40.48%, rgba(6, 6, 23, 0.64) 93.45%);

	z-index: 7000;
	opacity: 0;
	transition: opacity 0.4s;
`

export const Background = styled.span<{ imgSrc: string }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 110%;
	background: ${({ imgSrc }) => `url(${imgSrc})`};
	background-position: top;
	background-size: cover;
	margin-top: -10%;
`
