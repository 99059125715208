import { LayoutType } from "@components/shared/layouts"
import { forwardRef, PropsWithChildren } from "react"
import { Wrapper } from "./screen-layout.styles"

export interface IScreenLayoutProps {
	children: React.ReactNode
	size?: keyof typeof LayoutType
}

export const ScreenLayout = forwardRef<unknown, PropsWithChildren<IScreenLayoutProps & JSX.IntrinsicElements["div"]>>(
	({ size = "Mobile", ...rest }, ref: any) => {
		return (
			<Wrapper size={size} ref={ref}>
				{rest.children}
			</Wrapper>
		)
	}
)
