import { PaymentMethod } from "@stripe/stripe-js"
import { getFirestoreDocument } from "../server-api/get-firestore-document"

export type StripeSubscriptionStatus =
	// Subscription that is currently in a trial period
	| "trialing"
	// Subscription is active and service is provided
	| "active"
	//  If the first/initial payment attempt fails.
	| "incomplete"
	//  If the first invoice is not paid within 23 hours, the subscription transitions to incomplete_expired
	| "incomplete_expired"
	// When payment to renew subscription fails
	| "past_due"
	// Canceled or unpaid (depending on your subscriptions settings) when Stripe has exhausted all payment retry attempts.
	| "unpaid"
	| "canceled"
	// My own statuses
	// new - before starting creat a subscription
	// error - subscription creation failed with internal server error
	| "new"
	| "error"

export interface StripeSubscriptionRecord {
	/* eslint-disable camelcase */
	// true if the object exists in live mode
	live?: boolean

	status: StripeSubscriptionStatus

	// If the subscription has a trial, the beginning/end of that trial.
	trial_start?: number
	trial_end?: number

	// Start/end of the current period that the subscription has been invoiced for.
	current_period_start?: number
	current_period_end?: number

	// If the subscription has been canceled with the at_period_end flag set to true,
	// cancel_at_period_end on the subscription will be true.
	cancel_at_period_end?: boolean
	// A date in the future at which the subscription will automatically get canceled
	cancel_at?: number

	start_date?: number
	// If the subscription has ended, the date the subscription ended.
	ended_at?: number
	// If the subscription has been canceled, the date of that cancellation.
	// If the subscription was canceled with cancel_at_period_end, canceled_at will reflect
	// the time of the most recent update request, not the end of the subscription period
	// when the subscription is automatically moved to a canceled state.
	canceled_at?: number

	// The most recent invoice this subscription has generated.
	latest_invoice_id: string

	price_id?: string
	product_id?: string

	interval?: "day" | "month" | "week" | "year"
	interval_count?: number

	currency?: string

	unit_amount?: number
	unit_amount_decimal?: string

	idempotence_key?: string
	error?: string

	id: string
	/* eslint-enable camelcase */
}

type InvoiceStatus = "deleted" | "draft" | "open" | "paid" | "uncollectible" | "void"

export interface InvoiceRecord {
	id: string
	status: InvoiceStatus | null
	//payment_intent_id?: string,
}

export interface StripeCustomerRecord {
	cid: string
	uid: string
	card?: PaymentMethod
	subs?: StripeSubscriptionRecord[]
}

export const isStripeSubscriptionInTerminalStatus = (status: StripeSubscriptionStatus | ""): boolean => {
	const res = status === "error" || status === "canceled" || status === "incomplete_expired"
	return res
}

export const isStripeSubscriptionInActiveStatus = (status: StripeSubscriptionStatus | ""): boolean => {
	const res = status === "trialing" || status === "active"
	return res
}

export const isStripeSubscriptionToBePaid = (status: StripeSubscriptionStatus | ""): boolean => {
	const res = status === "incomplete" || status === "past_due"
	return res
}

/**
 * Returns current user's subscription record and current user's subscription status.
 * if subscription has never been created then return null for currSubscription and
 * empty string for currSubscriptionStatus;
 */
export const getCurrentSubscription = async (userId: string) => {
	try {
		const stripeDoc = await getFirestoreDocument<StripeCustomerRecord>("STRIPE_CUSTOMER", userId)
		const currSubscription = stripeDoc && stripeDoc.subs && stripeDoc.subs.length > 0 ? stripeDoc.subs[0] : null
		const currSubscriptionStatus: StripeSubscriptionStatus | "" = currSubscription ? currSubscription.status : ""
		return {
			currSubscription,
			currSubscriptionStatus,
		}
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}

export const stripeSubscriptionsGetCurrent = async (userId: string): Promise<StripeSubscriptionRecord[]> => {
	const subscriptions: StripeSubscriptionRecord[] = []

	const result = await getCurrentSubscription(userId)
	const subscription = result.currSubscription
	if (!subscription) {
		return subscriptions
	}

	if (isStripeSubscriptionInActiveStatus(subscription.status) || isStripeSubscriptionToBePaid(subscription.status)) {
		subscriptions.push(subscription)
	}

	return subscriptions
}
