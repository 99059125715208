import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router-dom"
import { useUserStatus } from "../../../subscriptions-web/hooks/use-user-status"

export const LoginFlowRoute = (props: RouteProps) => {
	const {
		userSignedInAnonymously,
		userSignedInWithEmailNotVerified,
		userSignedInWithEmailVerified,
		isProSubscriptionActive,
	} = useUserStatus()

	const renderRouter = (routeProps: RouteComponentProps<any>) => {
		if (userSignedInWithEmailVerified) {
			return <Redirect to={"/account"} />
		} else if (userSignedInAnonymously && isProSubscriptionActive) {
			return <Redirect to={"/confirm-email"} />
		} else if (userSignedInWithEmailNotVerified) {
			return <Redirect to={"/confirm-email"} />
		} else {
			return <Redirect to={"/signin"} />
		}
	}

	return <Route {...props} render={renderRouter} />
}
