import { useAuthContext } from "../context"

export const useUserStatus = () => {
	const { isUserSignedIn, isUserVerified, isUserAnonymous, isPro, isWeb, currentUser } = useAuthContext()

	const hasEmail = !!currentUser?.email

	// 1. SIGN STATUS
	const userNotSignedIn = !isUserSignedIn
	const userSignedInAny = isUserSignedIn
	const userSignedInAnonymously = isUserSignedIn && isUserAnonymous && !hasEmail
	const userSignedInWithEmailAny = isUserSignedIn && hasEmail
	const userSignedInWithEmailVerified = isUserSignedIn && isUserVerified
	const userSignedInWithEmailNotVerified = isUserSignedIn && hasEmail && !isUserVerified

	if (isUserSignedIn) {
		// console.log("user is signed in")
	}
	if (isUserVerified) {
		// console.log("user is verified")
	}

	// 2. PAYMENT ACCOUNTS
	const hasUserWebPaymentAccount = isWeb

	// 3. USER SUBSCRIPTION STATUS
	const isProSubscriptionActive = isPro

	if (isProSubscriptionActive) {
		// console.log("pro subscription is active")
	}

	return {
		userNotSignedIn,
		userSignedInAny,
		userSignedInAnonymously,
		userSignedInWithEmailAny,
		userSignedInWithEmailVerified,
		userSignedInWithEmailNotVerified,
		hasUserWebPaymentAccount,
		isProSubscriptionActive,
	}
}
