import { useEffect, useState } from "react"
import { RESPONSE_CODE } from "../server-api/response-codes"
import { useSendSignInOTP } from "./use-send-signin-otp"
import { useUpgradeAnonymousUser } from "./use-upgrade-anonymous-user"
import { ProgressStatus } from "."

export const useUpgradeUserAndSendOtp = (onSuccess?: () => void) => {
	const [state, setState] = useState(false)
	const { sendSignInOTP, sendSignInOTPState } = useSendSignInOTP(onSuccess)
	const { isUserEmailUpdating, userEmailUpdatingError, upgradeAnonymousUser } = useUpgradeAnonymousUser(
		async (email: string) => {
			if (sendSignInOTPState.status === ProgressStatus.START) {
				await sendSignInOTP(email, "LINK", "/signin-callback")
			}
		}
	)

	useEffect(() => {
		if (isUserEmailUpdating) {
			setState(true)
		}
	}, [isUserEmailUpdating])

	useEffect(() => {
		if (
			!isUserEmailUpdating &&
			sendSignInOTPState.status === ProgressStatus.DONE &&
			!userEmailUpdatingError &&
			sendSignInOTPState.response &&
			state
		) {
			if (onSuccess) {
				onSuccess()
			}
		}
	}, [state, onSuccess, isUserEmailUpdating, userEmailUpdatingError, sendSignInOTPState])

	const upgradeUserAndSendOtp = async (email: string) => {
		await upgradeAnonymousUser(email)
	}

	const isInProgress = isUserEmailUpdating || sendSignInOTPState.status === ProgressStatus.WORKING
	const sendOTPErrorMessage =
		sendSignInOTPState.response && sendSignInOTPState.response.code !== RESPONSE_CODE.OTP_SENT
			? sendSignInOTPState.response.message
			: ""
	const confirmEmailErrorMessage = (userEmailUpdatingError && userEmailUpdatingError.message) ?? ""
	const errorMessage = "" + (sendOTPErrorMessage || confirmEmailErrorMessage)

	return {
		upgradeUserAndSendOtp,
		isInProgress,
		errorMessage,
	}
}
