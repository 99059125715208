import { color, background } from "@components/shared/colors"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled, { css } from "styled-components"

import { IReviewProps } from "./review"

export const Wrapper = styled.div<IReviewProps>`
	position: relative;

	${({ size }) =>
		size === "Mobile"
			? css`
					width: 335px;
					min-height: 230px;
			  `
			: css`
					width: 440px;
					min-heigh: 208px;
			  `};

	padding: ${spacing.padding.verticalBig}px ${spacing.padding.horizontalExtraSmall}px;

	width: ${({ isStretch }) => isStretch && "100%"};
	height: auto;
	min-height: auto;

	position: relative;

	background: ${background.white};

	border-radius: ${spacing.borderRadius.small}px;
	box-sizing: border-box;

	overflow: hidden;

	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);
`

export const TitleContainer = styled.div`
	padding: 0;
	margin: 0;

	width: 100%;
	height: auto;

	border-width: 0;
	box-sizing: border-box;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`

const DefaultText = styled.p`
	padding: 0;
	margin: 0;

	box-sizing: border-box;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${typography.fontSize.b2}px;
	line-height: ${typography.lineSize.bm2}px;
	color: ${color.black};
`

export const Title = styled(DefaultText)`
	font-weight: ${typography.fontWeight.extrabold};
	color: ${color.black};
`

export const Created = styled(DefaultText)`
	line-height: ${typography.lineSize.bm1}px;
	color: ${color.smoke};
	letter-spacing: ${typography.letterSpacing.b};
`

export const Author = styled(DefaultText)`
	line-height: ${typography.lineSize.bm1}px;
	color: ${color.smoke};
	letter-spacing: ${typography.letterSpacing.b};
`

export const Text = styled(DefaultText)`
	width: 100%;
`

export const Spacer = styled.div<{ width?: string; height?: string }>`
	width: ${({ width }) => width ?? "0"};
	height: ${({ height }) => height ?? "0"};
`
