import { color } from "@components/shared/colors"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled from "styled-components"

const DefaultText = styled.p`
	text-align: center;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
`

// MOBILE STYLES:
export const MobileWrapper = styled.div`
	position: relative;

	padding: 0 ${spacing.padding.horizontalExtraSmall}px;
`

export const DescriptionWithLinks = styled(DefaultText)`
	width: 100%;

	font-size: ${typography.fontSize.bm3}px;
	line-height: ${typography.lineSize.bm3}px;

	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.smoke};

	> a {
		text-decoration: none;
		color: ${color.black};
	}
`

// DESKTOP STYLES:
export const DesktopWrapper = styled.div`
	position: relative;
	width: 400px;
`

export const Description = styled(DefaultText)`
	display: flex;
	flex-direction: column;
	align-items: center;

	font-size: ${typography.fontSize.b2}px;
	line-height: ${typography.lineSize.b3}px;

	text-align: center;

	color: ${color.smoke};
`

export const Links = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	margin-top: 16px;

	> a {
		margin: 0 8px;

		font-family: ${typography.family.inter};
		font-style: ${typography.fontStyle.default};
		font-weight: ${typography.fontWeight.bold};

		font-size: ${typography.fontSize.b2}px;
		line-height: ${typography.lineSize.b3}px;

		letter-spacing: ${typography.letterSpacing.b}em;

		color: ${color.black};

		text-decoration: none;
	}
`
