import styled from "styled-components"

const DefaultStyles = styled.div`
	position: absolute;
	width: 100%;

	overflow: hidden;

	padding: 0;
	margin: 0;

	border-width: 0;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
`

export const Hole = styled(DefaultStyles)<{ z?: number }>`
	left: 0;
	top: 0;

	height: calc(100% + 200px);

	z-index: ${({ z }) => z ?? 100};
	transition: height 1s ease-in-out;
`

export const HoleContainer = styled(DefaultStyles)`
	position: relative;
	height: 100%;
	transition: width 1s ease-in-out;
`

export const HoleCircle = styled.div`
	position: absolute;
	left: 50%;
	top: 100%;

	width: 100%;
	height: 400px;

	border-radius: 50%;

	transform: translate(-50%, -50%);
	box-shadow: 0 0 0 3000px #f6f6f6;

	z-index: 1;

	transition: height 1s ease-in-out;

	border-width: 0;
	box-sizing: border-box;
`
