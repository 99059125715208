import { callFirebaseFunction } from "../server-api/call-firebase-function"
import { ApiResponse, API_RESPONSE_STATUS, ErrorData } from "./api/api-response"
import { PaypalSubscriptionCreateApiResponse } from "./domain/paypal-subscription-create-api-response"

export interface PaypalSubscriptionCreateParams {
	planId: string
	analytics?: {
		email?: string
		fbc?: string
		fbp?: string
		amplitudeDeviceId?: string
		amplitudeUserId?: string
		domain: string
	}
}

export const paypalSubscriptionCreate = async (
	params: PaypalSubscriptionCreateParams
): Promise<PaypalSubscriptionCreateApiResponse> => {
	const response = await callFirebaseFunction<
		PaypalSubscriptionCreateParams,
		ApiResponse<PaypalSubscriptionCreateApiResponse>
	>("paypalSubscriptionCreateV3", params)
	if (response.status !== API_RESPONSE_STATUS.SUCCESS) {
		throw new Error(
			`SERVER returned error: status:${response.status} message:${(response.data as ErrorData).message} code:${
				(response.data as ErrorData).error
			}.`
		)
	}
	return response.data as PaypalSubscriptionCreateApiResponse
}
