import { Trans } from "@lingui/macro"
import * as A from "./atoms"

export function TermsAndConditionsLink() {
	return (
		<A.AgreementsLink
			href={"/terms.pdf"}
			// href={"https://glowbeweb.s3.us-east-2.amazonaws.com/glowbe_terms_web+NEW.pdf"}
			target="_blank"
			rel="noreferrer"
		>
			<Trans>Terms and Conditions</Trans>
		</A.AgreementsLink>
	)
}
export function PrivacyPolicyLink() {
	return (
		<A.AgreementsLink href={"/privacy.pdf"} target="_blank" rel="noreferrer">
			<Trans>Privacy Policy</Trans>
		</A.AgreementsLink>
	)
}
export function CopyrightsText() {
	return (
		<A.Copyrights>
			{/* Copyright © 2019-2022 Glowbe. */}
			<Trans>Copyright © 2019-2021 Glowbe.</Trans>
			<br />
			<Trans>All rights reserved</Trans>
		</A.Copyrights>
	)
}

export function CopyrightsFooter() {
	return (
		<A.Footer>
			<A.AgreementsLinks>
				<TermsAndConditionsLink />
				<PrivacyPolicyLink />
			</A.AgreementsLinks>
			<CopyrightsText />
		</A.Footer>
	)
}
