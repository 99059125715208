import { animated } from "@react-spring/web"
import styled, { css } from "styled-components"
import { Bubble } from "~/components2/atoms/Bubble"
import { InterText, TextBody } from "~/components2/atoms/Text"

export const MiniCarouselWrapper = styled.div`
	width: 100%;
	position: relative;
`
export const MiniCarouselSpacer = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 104px;
	background-color: #ffffff;
`
export const Slide = styled.div<{ main: boolean }>`
	width: 100%;
	height: 100%;
	max-width: ${({ main }) => (main ? 194 : 189)}px;
	max-height: ${({ main }) => (main ? 190 : 150)}px;
	margin: 0px 2px 40px 2px;
	position: relative;
	overflow: hidden;

	background: #5856ea;
	box-shadow: ${({ main }) =>
		main
			? "0px 4.5283px 36.2264px rgba(0, 0, 0, 0.16), 0px 0.566038px 0px rgba(0, 0, 0, 0.02)"
			: "0px 3.43053px 27.4443px rgba(0, 0, 0, 0.16), 0px 0.428816px 0px rgba(0, 0, 0, 0.02)"};
	box-sizing: border-box;
	border-radius: 12px;

	> img {
		width: 100%;
	}
`
export const SlideInfo = styled.div<{ main: boolean }>`
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	/* width: 100%; */

	display: flex;
	flex-direction: ${({ main }) => (main ? "column-reverse" : "column")};
	box-sizing: border-box;

	${({ main }) =>
		main
			? css`
					height: 60px;
					padding: 10px 11px;
			  `
			: css`
					height: 44px;
					background: #fff;
					padding: 6px 9px;
			  `}
`
export const SlideTitle = styled(InterText)<{ main: boolean }>`
	margin: 0;

	${({ main }) =>
		main
			? css`
					font-weight: 700;
					font-size: 16px;
					line-height: 18px;
					color: #ffffff;
			  `
			: css`
					font-weight: 500;
					font-size: 10px;
					line-height: 15px;
					color: #222533;
			  `}
`
export const SlideSubTitle = styled(InterText)<{ main: boolean }>`
	margin: 0;

	${({ main }) =>
		main
			? css`
					font-weight: 600;
					font-size: 10px;
					line-height: 9px;
					color: #ffffff;
					opacity: 0.8;
					text-transform: uppercase;
					margin-bottom: 4px;
			  `
			: css`
					font-weight: 500;
					font-size: 10px;
					line-height: 15px;
					color: #9599a6;
			  `}
`

export const BigCarouselContainer = styled.div`
	margin-top: 14px;
	width: 100%;
`
export const BigSlide = styled(Slide)`
	max-width: 280px;
	max-height: ${({ main }) => (main ? 274 : 222)}px;
	margin: 10px 4px 38px 4px;

	> div {
		height: 66px;
		> p {
			font-size: 16px;
			line-height: 22px;
		}
		> small {
			font-size: 16px;
			line-height: 22px;
		}
	}
`
export const BigSlideInfo = styled(SlideInfo)`
	height: 65px;

	display: flex;
	flex-direction: ${({ main }) => (main ? "column-reverse" : "column")};
	justify-content: center;

	${({ main }) =>
		main
			? css`
					padding: 12px 13px;
			  `
			: css`
					background: #fff;
					padding: 8px 13px;
			  `}
`
export const BigSlideTitle = styled(SlideTitle)<{ main: boolean }>`
	${({ main }) =>
		main
			? css`
					font-size: 23px;
					line-height: 26px;
			  `
			: css`
					font-size: 15px;
					line-height: 22px;
			  `}
`
export const BigSlideSubTitle = styled(SlideSubTitle)<{ main: boolean }>`
	${({ main }) =>
		main
			? css`
					font-size: 14px;
					line-height: 13px;
					margin-bottom: 6px;
			  `
			: css`
					font-size: 15px;
					line-height: 22px;
			  `}
`
export const BigCarouselFeatures = styled.div`
	width: 100%;
	height: 210px;
	position: relative;
`
export const BigCarouselFeature = styled(animated.div)`
	position: absolute;
	top: 0;
	width: 100%;
`

export const FactsCarouselContainer = styled.div`
	width: 100%;
	margin: 24px 0 0 0;
	padding: 0 20px;
	position: relative;

	display: flex;
	flex-direction: row;
	box-sizing: border-box;
`
export const FactWrapper = styled(Bubble)`
	position: relative;
	min-width: 320px;
	height: 420px;
	margin: 3px 2px 4px 2px;

	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 24px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;

	> div {
		width: 100%;
		height: 100%;
	}
`
export const FactLogo = styled.img.attrs({ alt: "" })`
	position: relative;
	width: auto;
	height: auto;
	max-height: 56px;
	margin: 0;
`
export const FactMessage = styled(TextBody).attrs({
	margin: "16px 0",
	color: "#000000",
	centered: true,
	size: "big",
})``
export const FactName = styled(TextBody).attrs({
	margin: "12px 0",
	color: "#828691",
	centered: true,
	size: "small",
})``

export const Stars = styled.img.attrs({ alt: "" })`
	position: relative;
	width: 105px;
	height: auto;
	margin: 16px 0;
`

export const FeatureTitle = styled(TextBody).attrs({
	size: "big",
	centered: true,
})`
	width: 100%;
	margin-bottom: 8px;

	font-weight: 700;
	color: #1c1b47;
`

export const FeatureDescription = styled(TextBody).attrs({
	size: "big",
	centered: true,
})`
	width: 100%;
	color: #828691;
`
