import { background } from "@components/shared/colors"
import styled, { css } from "styled-components"
import { IRadioBackButtonProps } from "./radio-back-button"

export const RadioBack = styled.div<IRadioBackButtonProps>`
	${({ size }) =>
		size === "Mobile"
			? css`
					width: 40px;
					height: 40px;
			  `
			: css`
					width: 56px;
					height: 56px;
			  `};

	background: ${background.lightIndigo};
	box-sizing: border-box;
	border-radius: 50%;

	display: flex;
	justify-content: center;
	align-items: center;

	> img {
		width: auto;
		height: 24px;
	}
`
