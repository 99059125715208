import Feature1 from "@assets/Feature1.svg"
import Feature2 from "@assets/Feature2.svg"
import Feature3 from "@assets/Feature3.svg"
import Feature4 from "@assets/Feature4.svg"
import FiveStars from "@assets/FiveStars.png"
import GLogoIcon from "@assets/GLogo.svg"
import LaptopScreen from "@assets/LaptopScreen.png"
import Line1 from "@assets/Line1.svg"
import Line2 from "@assets/Line2.svg"
import PhoneFull from "@assets/PhoneFull.png"
import PhoneLogo from "@assets/PhoneLogo.png"
import PhoneMockup from "@assets/PhoneMockup.png"
import { Trans } from "@lingui/macro"
import { useState, useEffect, useRef } from "react"
import { CopyrightsFooter } from "~/components2/atoms/copyrights"
import { Flexed } from "~/components2/atoms/Flexed"
import DesktopLine1 from "./assets/DesktopLine1.svg"
import DesktopLine2 from "./assets/DesktopLine2.svg"

import * as A from "./atoms"
import { BigCarousel } from "./big-carousel"
import {
	CharlieDesktopWrapper,
	MainInfoDesktop,
	MainContentDesktop,
	ScreensDesktop,
	LinesDesktop,
	FeaturesDesktop,
	FactsDesktop,
	BlueButtonDesktop,
	FloatingButtonDesktop,
} from "./charlie-desktop.styles"
import {
	CharlieWrapper,
	MainInfo,
	Lines,
	GLogo,
	PinkButton,
	MediumInfo,
	PhoneMockupWrapper,
	Features,
	BlueText,
	Title,
	Feature,
	GreenButton,
	LowerInfo,
	FactsTitle,
	FloatingButton,
	FeatureIcon,
	PurpleText,
} from "./charlie-landing.styles"
import { facts } from "./facts-carousel"
import { FactsCarousel } from "./facts-carousel"
import { MiniCarousel } from "./mini-carousel"

export interface ICharlieLandingProps {
	onClickCourse: () => void
}

export const CharlieLanding = (props: ICharlieLandingProps) => {
	const floatingBtnRef = useRef<HTMLDivElement>(null)
	const floatingBtnDesktopRef = useRef<HTMLDivElement>(null)

	const [visibleButton, setVisibleButton] = useState(false)
	const [visibleDesktopButton, setVisibleDesktopButton] = useState(false)

	useEffect(() => {
		if (floatingBtnDesktopRef.current) {
			if (visibleDesktopButton) {
				floatingBtnDesktopRef.current.style.right = "20px"
			} else {
				floatingBtnDesktopRef.current.style.right = "-200px"
			}
		}
	}, [visibleDesktopButton])

	useEffect(() => {
		window.onscroll = () => {
			const mediumDesktop = document.getElementById("mediumDesktop")

			if (window && mediumDesktop && window.pageYOffset >= mediumDesktop.offsetTop) {
				setVisibleDesktopButton(true)
			} else {
				setVisibleDesktopButton(false)
			}

			const showFloatingButton = document.getElementById("showFloatingButton")

			if (window && showFloatingButton && window.pageYOffset >= showFloatingButton.offsetTop) {
				setVisibleButton(true)
			} else {
				setVisibleButton(false)
			}
		}
	}, [])

	return (
		<>
			<CharlieWrapper id="wrapper">
				<FloatingButton ref={floatingBtnRef} visible={visibleButton} onClick={props.onClickCourse}>
					<Trans>Get Started</Trans>
				</FloatingButton>
				<MainInfo>
					<Lines>
						<img alt="" src={Line1} />
						<img alt="" src={Line2} />
					</Lines>
					<GLogo alt="" src={GLogoIcon} />
					<p>
						<Trans>“I can't believe it's that easy to get rid of wrinkles. Wish I had found this app ages ago.”</Trans>{" "}
						—{" "}
						<PurpleText>
							<Trans>Christiana M</Trans>
						</PurpleText>
					</p>
					<small>
						<Trans>Fall in love with yourself by taking care of your skin, hair, body and mind.</Trans>
					</small>
					<PinkButton onClick={props.onClickCourse}>
						<Trans>Get Started</Trans>
					</PinkButton>
					<MiniCarousel />
				</MainInfo>
				<MediumInfo>
					<PhoneMockupWrapper alt="" src={PhoneMockup} />
					<Features>
						<BlueText id="howItWorks">
							<Trans>How it works</Trans>
						</BlueText>
						<Title>
							<Trans>What do you get?</Trans>
						</Title>
						<Feature>
							<FeatureIcon alt="" src={Feature1} />
							<A.FeatureTitle>
								<Trans>You'll Get Access</Trans>
								<br />
								<Trans>to a Personalised Plan</Trans>
							</A.FeatureTitle>
							<A.FeatureDescription>
								<Trans>Also additional courses, and exclusive content on skin, hairand body care</Trans>
							</A.FeatureDescription>
						</Feature>
						<Feature>
							<FeatureIcon alt="" src={Feature2} />
							<A.FeatureTitle>
								<Trans>Daily Exercises</Trans>
							</A.FeatureTitle>
							<A.FeatureDescription>
								<Trans>You can choose to receive notifications so as not to miss any workouts</Trans>
							</A.FeatureDescription>
						</Feature>
						<Feature id="showFloatingButton">
							<FeatureIcon alt="" src={Feature3} />
							<A.FeatureTitle>
								<Trans>Get Feedback</Trans>
							</A.FeatureTitle>
							<A.FeatureDescription>
								<Trans>We'll answer all your questions</Trans>
							</A.FeatureDescription>
						</Feature>
						<Feature>
							<FeatureIcon alt="" src={Feature4} />
							<A.FeatureTitle>
								<Trans>Track Your Progress</Trans>
							</A.FeatureTitle>
							<A.FeatureDescription>
								<Trans>Get visible results from your first session</Trans>
							</A.FeatureDescription>
						</Feature>
						<GreenButton onClick={props.onClickCourse}>
							<Trans>Get Started</Trans>
						</GreenButton>
						<BlueText>
							<Trans>What's Inside</Trans>
						</BlueText>
						<Title>
							<Trans>The complete self-care guide for all your needs</Trans>
						</Title>
						<BigCarousel />
					</Features>
				</MediumInfo>
				<LowerInfo>
					<BlueText>
						<Trans>Will this really work?</Trans>
					</BlueText>
					<FactsTitle>
						<Trans>Science-Backed and Dermatologists-Approved</Trans>
					</FactsTitle>
					<FactsCarousel />
					<CopyrightsFooter />
				</LowerInfo>
			</CharlieWrapper>
			<CharlieDesktopWrapper>
				<MainInfoDesktop id="mainDesktop">
					<LinesDesktop>
						<img alt="" src={DesktopLine1} />
						<img alt="" src={DesktopLine2} />
					</LinesDesktop>
					<MainContentDesktop>
						<p>
							<Trans>
								“I can't believe it's that easy to get rid of wrinkles. Wish I had found this app ages ago.”
							</Trans>{" "}
							—{" "}
							<PurpleText>
								<Trans>Christiana M</Trans>
							</PurpleText>
						</p>
						<small>
							<Trans>Fall in love with yourself by taking care of your skin, hair, body and mind.</Trans>
						</small>
						<PinkButton onClick={props.onClickCourse}>
							<Trans>Get Started</Trans>
						</PinkButton>
					</MainContentDesktop>
					<ScreensDesktop>
						<img alt="" src={PhoneLogo} />
						<img alt="" src={LaptopScreen} />
					</ScreensDesktop>
				</MainInfoDesktop>
				<FloatingButtonDesktop ref={floatingBtnDesktopRef} onClick={props.onClickCourse}>
					<Trans>Get the course</Trans>
				</FloatingButtonDesktop>
				<MediumInfo id="mediumDesktop">
					<BlueText id="howItWorks">
						<Trans>How it works</Trans>
					</BlueText>
					<Title>
						<Trans>What do you get?</Trans>
					</Title>
					<FeaturesDesktop>
						<img alt="" src={PhoneFull} />
						<Features>
							<Feature>
								<FeatureIcon alt="" src={Feature1} />
								<div>
									<A.FeatureTitle>
										<Trans>Access to a Personalised Plan</Trans>
									</A.FeatureTitle>
									<A.FeatureDescription>
										<Trans>Also additional courses, and exclusive content on skin, hairand body care</Trans>
									</A.FeatureDescription>
								</div>
							</Feature>
							<Feature>
								<FeatureIcon alt="" src={Feature2} />
								<div>
									<A.FeatureTitle>
										<Trans>Daily Exercises</Trans>
									</A.FeatureTitle>
									<A.FeatureDescription>
										<Trans>You can choose to receive notifications so as not to miss any workouts</Trans>
									</A.FeatureDescription>
								</div>
							</Feature>
							<Feature>
								<FeatureIcon alt="" src={Feature3} />
								<div>
									<A.FeatureTitle>
										<Trans>Get Feedback</Trans>
									</A.FeatureTitle>
									<A.FeatureDescription>
										<Trans>We'll answer all your questions</Trans>
									</A.FeatureDescription>
								</div>
							</Feature>
							<Feature>
								<FeatureIcon alt="" src={Feature4} />
								<div>
									<A.FeatureTitle>
										<Trans>Track Your Progress</Trans>
									</A.FeatureTitle>
									<A.FeatureDescription>
										<Trans>Get visible results from your first session</Trans>
									</A.FeatureDescription>
								</div>
							</Feature>
							<GreenButton onClick={props.onClickCourse}>
								<Trans>Get Started</Trans>
							</GreenButton>
						</Features>
					</FeaturesDesktop>
					<BlueText style={{ marginTop: 0 }}>
						<Trans>What's Inside</Trans>
					</BlueText>
					<Title>
						<Trans>The complete self-care guide for all your needs</Trans>
					</Title>
					<BigCarousel />
				</MediumInfo>
				<LowerInfo>
					<BlueText>
						<Trans>Will this really work?</Trans>
					</BlueText>
					<Title>
						<Trans>Science-Backed and Dermatologists-Approved</Trans>
					</Title>
					<FactsDesktop>
						<div>
							<A.FactWrapper>
								<A.Stars src={FiveStars} />
								<A.FactLogo alt="" src={facts[0].logo} />
								<A.FactMessage>{facts[0].message}</A.FactMessage>
								<Flexed.Spacer />
								<A.FactName>{facts[0].name}</A.FactName>
							</A.FactWrapper>
						</div>
						<div>
							<A.FactWrapper>
								<A.Stars src={FiveStars} />
								<A.FactLogo alt="" src={facts[2].logo} />
								<A.FactMessage>{facts[2].message}</A.FactMessage>
								<Flexed.Spacer />
								<A.FactName>{facts[2].name}</A.FactName>
							</A.FactWrapper>
						</div>
					</FactsDesktop>
					<BlueButtonDesktop onClick={props.onClickCourse}>
						<Trans>Get Started</Trans>
					</BlueButtonDesktop>
					<CopyrightsFooter />
				</LowerInfo>
			</CharlieDesktopWrapper>
		</>
	)
}
