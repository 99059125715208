import styled, { keyframes } from "styled-components"
import { Flexed } from "~/components2/atoms/Flexed"

export const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	@media (orientation: landscape) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`

export const LandingWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	overflow: hidden;

	z-index: 80;

	@media (orientation: landscape) {
		width: 702px;
	}
`

export const LandingBody = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;
	z-index: 50;

	margin-top: -60px;

	> img {
		margin: 0;
	}

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 110%;
		/* or 35px */
		text-align: center;
		letter-spacing: -0.04em;

		color: #fefefc;

		margin: 16px 0 24px;

		@media (max-width: 320px) {
			font-size: 28px;
		}
	}

	> div {
		display: flex;
		flex-direction: row;
		width: 100%;

		> button {
			margin: 0 4px;
		}
	}

	@media (max-width: 375px) and (max-height: 667px) {
		margin-top: -40px;
	}

	@media (max-width: 320px) {
		margin-top: -10px;
	}

	@media (orientation: landscape) {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 0;
		margin-top: -120px;

		> div {
			margin-top: 16px;
		}
	}
`

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #5856ea;

	z-index: 5;
	overflow: hidden;
`

export const Logo = styled.div`
	position: absolute;
	top: 20px;
	left: 0;
	width: 100%;
	height: 24px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> img {
		width: auto;
		height: 100%;
	}
`

export const MobileBreak = styled.br`
	@media (orientation: landscape) {
		display: none;
	}
`

export const Copyright = styled.p`
	position: absolute;
	bottom: 32px;
	left: 0;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	letter-spacing: -0.03em;

	color: #f6f6f6;

	opacity: 0.4;

	width: 100%;
	text-align: center;

	z-index: 900;
`

export const LongTextButton = styled.div`
	position: relative;

	width: calc(50% - 4px);

	background: #ffe1e7;
	box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(193, 138, 202, 0.1);
	border-radius: 16px;
	margin: 0 4px 0 0;

	padding: 8px 30px;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	text-align: center;
	letter-spacing: -0.03em;

	color: #000000;
	box-sizing: border-box;

	margin-top: 84px !important;
`

export const BlueButton = styled(LongTextButton)`
	background: #d3fffc;
	margin: 0 0 0 4px;
`

export const move = keyframes`
0% {
bottom: -26px;
	opacity: 0;
}
	84% {
		bottom: -56px;
		opacity: 1;
	}
	100% {
		bottom: -56px;
		opacity: 0;
	}
`

export const TextWithImage = styled.p`
	width: 100%;
	text-align: center;
	position: relative;

	> img {
		width: 28px;
		height: 14px;
		position: absolute;
		opacity: 1;
		bottom: -26px;
		left: calc(50% - 14px);

		animation: ${move} 1.4s ease-out infinite;
	}
`

export const AlphaButtons = styled(Flexed)`
	position: relative;
	margin-top: 84px;

	@media (orientation: landscape) {
		padding-top: 84px;
	}
`

export const CharlieWrapper = styled.div`
	position: absolute;

	left: 0px;
	top: 0px;

	width: 100%;
	height: auto;
	overflow-y: scroll;

	overflow-x: hidden;

	box-sizing: border-box;

	background: #f6f6f6;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	display: flex;
	flex-direction: column;
	align-items: center;

	@media (orientation: landscape) {
		display: none;
	}
`

export const MainInfo = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	background: #32237d;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	z-index: 800;

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 32px;
		line-height: 40px;
		/* or 125% */
		text-align: center;
		letter-spacing: -0.04em;

		color: #ffffff;

		padding: 0 20px;
		box-sizing: border-box;
		width: 100%;

		margin: 24px 0 16px;
	}
	> small {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;

		text-align: center;
		letter-spacing: -0.03em;

		color: #ffffff;

		padding: 0 20px;
		box-sizing: border-box;
		width: 100%;
	}
`

export const Lines = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	z-index: 400;

	> img {
		position: absolute;

		&:first-child {
			width: 84%;
			right: 16px;
			top: 0;
			z-index: 470;
		}

		&:last-child {
			width: 24%;
			right: -10%;
			top: 0;
			transform: rotate(20deg);

			z-index: 480;
		}
	}
`

export const GLogo = styled.img`
	width: 90px;
	height: auto;
	margin-top: 18.5px;
	z-index: 450;
`

export const PurpleText = styled.span`
	color: #9787e4;
`

export const BlueText = styled.div`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.03em;

	color: #5856ea;

	margin: 48px 0 8px;

	@media (orientation: landscape) {
		margin: 80px 0 8px;
	}
`

export const CharlieButton = styled.div`
	position: relative;
	width: calc(100% - 40px);
	background: #ffd1da;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 24px rgba(88, 86, 214, 0.12);
	border-radius: 16px;
	color: #1c1b47;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 28px;

	text-align: center;
	letter-spacing: -0.03em;

	padding: 14px;
	box-sizing: border-box;

	cursor: pointer;

	@media (orientation: landscape) {
		width: 360px;
	}
`

export const PinkButton = styled(CharlieButton)`
	margin: 40px 0 24px;
`

export const GreenButton = styled(CharlieButton)`
	background: #15ce28;
	color: #fff;
	margin: 24px 0 0;
`

export const CardsInfo = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	margin-top: 24px;
	margin-bottom: 66px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`

export const MediumInfo = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	padding-top: 40px;

	overflow: hidden;

	z-index: 7000;

	background: #fff;
`

export const LowerInfo = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background: #f6f6f6;

	z-index: 7000;

	margin-bottom: 40px;

	@media (orientation: landscape) {
		margin-bottom: 0;
	}
`

export const PhoneMockupWrapper = styled.img`
	width: 300px;
	height: auto;
`

export const Title = styled.div`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 28px;
	line-height: 32px;

	text-align: center;
	letter-spacing: -0.04em;

	color: #1c1b47;

	padding: 0 20px;
	width: 100%;
	box-sizing: border-box;

	@media (orientation: landscape) {
		font-size: 40px;
		line-height: 110%;
		/* or 44px */

		text-align: center;
		letter-spacing: -0.04em;

		width: 560px;
		padding: 0;
		margin-bottom: 56px;
	}
`

export const FactsTitle = styled(Title)`
	margin-bottom: 24px;
`

export const Features = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	@media (orientation: landscape) {
		width: 460px;
		align-items: flex-start;
	}
`

export const Feature = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	/* stylelint-disable-next-line no-descending-specificity */
	> img {
		width: 120px;
		height: 88px;

		margin: 40px 0 16px;
	}

	@media (orientation: landscape) {
		width: 460px;
		flex-direction: row;

		> div {
			width: 300px;
		}

		> img {
			margin-right: 28px;
		}
	}
`

export const FeatureIcon = styled.img`
	height: 90px;
	width: auto;
`

export const Facts = styled.div`
	margin-top: 24px;
`

export const Footer = styled.div`
	position: relative;
	width: 100%;
	height: 220px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		/* or 144% */

		text-align: center;
		letter-spacing: -0.03em;

		color: #828691;

		width: 100%;
		padding: 0 20px;
	}
`

export const FloatingButton = styled(CharlieButton)<{ visible: boolean }>`
	background: #6967ec;
	color: #fff;
	position: fixed;
	/* top: calc(100% - 80px); */
	bottom: ${({ visible }) => (visible ? 24 : -100)}px;
	left: 20px;

	height: 56px;

	transition: bottom 0.3s;

	z-index: 9999;
`

export const Question = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	letter-spacing: -0.03em;

	margin: 48px 0 8px;
`

export const ButtonWrapper = styled.div`
	margin: 40px 0 24px;
`
