import { useCallback } from "react"
import { useHistory } from "react-router"
import { amplitudeService } from "~/services/amplitude"
import { CharlieLanding } from "./charlie-landing"

export const CharlieLandingContainer = () => {
	const history = useHistory()

	const onClickCourse = useCallback(() => {
		amplitudeService.logEvent("[All] Button | Get course", { name: "Get Started" })

		history.push("/personal-course")
	}, [history])

	return <CharlieLanding onClickCourse={onClickCourse} />
}
