import styled from "styled-components"

export const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	@media (orientation: landscape) {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
	}
`

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #32237d;

	z-index: 5;
	overflow: hidden;
`
export const LandingWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 100%;

	overflow: hidden;

	z-index: 80;
`

export const ActionButtonWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 200px;
	bottom: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
`
