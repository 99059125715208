import { StripeSubscriptionRecord } from "~/subscriptions-web/stripe/get-current-subscription"
import { Subscription, SubscriptionProvider, SubscriptionStatus } from "../subscription"

const subscriptionStatusFromStripe = (stripeSubscriptionDoc: StripeSubscriptionRecord): SubscriptionStatus => {
	switch (stripeSubscriptionDoc.status) {
		case "active":
			if (stripeSubscriptionDoc.cancel_at_period_end) {
				return SubscriptionStatus.CANCELLED
			}
			return SubscriptionStatus.ACTIVE

		case "canceled":
			return SubscriptionStatus.CLOSED

		case "incomplete":
			return SubscriptionStatus.INCOMPLETE

		case "incomplete_expired":
			return SubscriptionStatus.INCOMPLETE_EXPIRED

		case "new":
			return SubscriptionStatus.DRAFT

		case "past_due":
			if (stripeSubscriptionDoc.cancel_at_period_end) {
				return SubscriptionStatus.CANCELLED
			}
			return SubscriptionStatus.PAST_DUE

		case "trialing":
			if (stripeSubscriptionDoc.cancel_at_period_end) {
				return SubscriptionStatus.CANCELLED
			}
			return SubscriptionStatus.TRIALING

		case "unpaid":
			return SubscriptionStatus.UNPAID

		default:
			return SubscriptionStatus.ERROR
	}
}

export const subscriptionFromStripe = (stripeSubscriptionDoc: StripeSubscriptionRecord): Subscription => {
	let subscription: Subscription = {
		id: stripeSubscriptionDoc.id,
		status: subscriptionStatusFromStripe(stripeSubscriptionDoc),
		provider: SubscriptionProvider.Stripe,
		planId: stripeSubscriptionDoc.price_id,
		productId: stripeSubscriptionDoc.product_id,
	}

	if (stripeSubscriptionDoc.current_period_start) {
		subscription = {
			...subscription,
			periodStart: new Date(stripeSubscriptionDoc.current_period_start * 1000),
		}
	}

	if (stripeSubscriptionDoc.current_period_end) {
		subscription = {
			...subscription,
			periodEnd: new Date(stripeSubscriptionDoc.current_period_end * 1000),
		}
	}

	return subscription
}
