import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: ${background.app};
	box-sizing: border-box;

	z-index: 50;
`

export const Form = styled.div`
	position: absolute;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	padding-bottom: 44px;
`

export const ConfirmationWrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	top: ${({ size }) => (size === "Mobile" ? 64 : 104)}px;
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: ${({ size }) => (size === "Mobile" ? "space-between" : "flex-start")};
	align-items: center;

	box-sizing: border-box;

	transition: left 0.4s;
	width: 100%;
	height: ${({ size }) => `calc(100% - ${size === "Mobile" ? 64 : 104}px)`};
	overflow-y: scroll;
`

export const Content = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	padding: 0 ${spacing.padding.horizontalSmall}px;

	display: flex;
	flex-direction: column;
	align-items: center;

	box-sizing: border-box;

	> p {
		text-align: center;
	}

	> span {
		margin: 30px 0 44px;
	}
`

export const Title = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.fontSize.h2)}px;
	line-height: 110%;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.h}em;

	color: ${color.black};

	margin: 10px 0 0;
`

export const Description = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.bm1 : typography.fontSize.b1)}px;
	line-height: ${({ size }) => (size === "Mobile" ? typography.lineSize.bm1 : typography.lineSize.b1)}px;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.smoke};

	margin: 16px 0 30px;
`
