import firebaseNameSpace from "firebase/app"
import { firebaseApp } from "~/services/firebase/firebase-app"
// import { firebaseApp } from "~/services/firebase/firebase-app"
import { RESPONSE_CODE } from "./response-codes"

export interface SignInAnonymouslyResponse {
	code: RESPONSE_CODE.UNEXPECTED_ERROR | RESPONSE_CODE.SUCCESS

	message: string

	data?: {
		userCredential?: firebaseNameSpace.auth.UserCredential
		error?: any
	}
}

/**
 * Anonymously signs in as an anonymous user.
 * If there is already an anonymous user signed in, that user will be returned;
 * otherwise, a new anonymous user identity will be created and returned.
 * You must enable Anonymous auth in the Firebase Console.
 * - Any error codes - exceptions!!!
 */
export const signInAnonymously = async (): Promise<SignInAnonymouslyResponse> => {
	try {
		const userCredential = await firebaseApp.auth().signInAnonymously()
		return {
			code: RESPONSE_CODE.SUCCESS,
			message: "Anonymous account has benn successfylly created.",
			data: {
				userCredential,
			},
		}
	} catch (error) {
		// More error codes https://firebase.google.com/docs/auth/admin/errors?hl=uk
		const errorCode = (error as any).code
		const errorMessage = (error as any).message
		if (errorCode === "auth/operation-not-allowed") {
			console.error("You must enable Anonymous auth in the Firebase Console.")
		}
		console.error(error)
		return {
			code: RESPONSE_CODE.UNEXPECTED_ERROR,
			message: errorMessage,
			data: {
				error,
			},
		}
	}
}
