import { Trans } from "@lingui/macro"
import React from "react"
import styled, { css, keyframes } from "styled-components"

import BlackSpinner from "~/assets/BlackSpinner.svg"
import WhiteSpinner from "~/assets/WhiteSpinner.svg"
import { TextBody } from "../Text"

type TButtonColor = "pink" | "blue"

interface IButton {
	width?: string
	margin?: string
	bold?: boolean
	color?: TButtonColor
	disabled?: boolean
	isLoading?: boolean
	type?: "button" | "submit" | "reset"
}

export interface IButtonProps extends IButton {
	title: string
	onClick?: () => void
}

const rotate = keyframes`
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	`

const StyledButton = styled.button<IButton>`
	display: flex;
	justify-content: center;
	align-items: center;

	outline: none;
	border: none;

	padding: 14px 0;
	width: ${({ width }) => width || "100%"};
	height: 56px;
	border-radius: 16px;
	box-sizing: border-box;
	text-align: center;
	cursor: pointer;

	margin: ${({ margin }) => margin || "12px 0"};

	@media (max-width: 320px) {
		width: ${({ width }) => width || "280px"};
	}

	@media (orientation: landscape) {
		${({ color }) =>
			color === "pink"
				? css`
						/* width: 352px; */
						/* height: 80px; */

						@media (orientation: landscape) {
							width: 234px;
							height: 64px;
						}
				  `
				: css`
						width: 480px;
						height: 64px;
				  `}
	}

	${({ color }) =>
		color === "pink"
			? css`
					background-color: #ffd1da;
					box-shadow: 0 4px 6px rgba(255, 209, 218, 0.08), 0 24px 40px rgba(255, 209, 218, 0.2);
			  `
			: css`
					background-color: #5856d6;
					box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06), 0 10px 16px rgba(88, 86, 214, 0.16);
			  `}

	${({ disabled }) =>
		disabled
			? css`
					cursor: not-allowed;
					pointer-events: none;
					/* opacity: 0.5; */
					background-color: #706feb !important;
			  `
			: css``}

	:hover {
		background-color: ${({ color }) => (color === "pink" ? "#FFE1E7" : "#706FEB")};
	}
`

const Spinner = styled.img`
	width: 28px;
	height: 28px;
	animation: ${rotate} 2s linear infinite;
`

export function Button(props: IButtonProps) {
	return (
		<StyledButton type={props.type || "button"} {...props}>
			{props.isLoading ? (
				<Spinner alt="" src={props.color === "pink" ? BlackSpinner : WhiteSpinner} />
			) : (
				<TextBody centered margin="0" size="big" bold color={props.color === "pink" ? "#1c1b47" : "#ffffff"}>
					{props.title}
				</TextBody>
			)}
		</StyledButton>
	)
}
