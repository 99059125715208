import { LayoutType } from "@components/shared/layouts"
import { forwardRef, PropsWithChildren, ReactNode } from "react"
import { Button } from "./choice-button.styles"

export interface IChoiceButtonProps {
	children: ReactNode
	onClick: any
	isDisabled?: boolean
	isStretch?: boolean
	isCentered?: boolean
	isCheckable?: boolean
	isChecked?: boolean
	picture?: string
	size?: keyof typeof LayoutType
}

export const ChoiceButton = forwardRef<unknown, PropsWithChildren<IChoiceButtonProps>>(
	(
		{
			isCentered = false,
			isDisabled = false,
			size = LayoutType.Mobile,
			isStretch = false,
			isCheckable = false,
			isChecked = false,
			...rest
		},
		ref: any
	) => {
		return (
			<Button
				{...rest}
				ref={ref}
				size={size}
				isDisabled={isDisabled}
				isStretch={isStretch}
				isCentered={isCentered}
				isCheckable={isCheckable}
				isChecked={isChecked}
				onClick={!isDisabled && rest.onClick}
			>
				{rest.picture && <img alt="" src={rest.picture} />}
				{rest.picture && isCheckable && <span />}

				<div>
					<p>{rest.children}</p>
					{isCheckable && !rest.picture && <span />}
				</div>
			</Button>
		)
	}
)
