import { ABTestValueDTO } from "./config-dto-types"

function randomIndex(ab: ABTestValueDTO) {
	let minimum: number

	const variants = Object.keys(ab.variants).map((key) => {
		const chance = parseInt(ab.variants[key].value, 10) / 100
		let comparison

		if (chance < 0.5) {
			comparison = "minimum"
			minimum = chance
		} else if (chance > 0.5) {
			comparison = "maximum"
		} else {
			comparison = "equal"
		}

		return {
			sortIndex: ab.variants[key].sortIndex,
			chance,
			comparison,
		}
	})

	const random = Math.random()

	const ind = variants.find((variant, index) => {
		if (variant.comparison === "minimum") {
			return random <= minimum
		} else if (variant.comparison === "maximum") {
			return random >= minimum
		} else {
			if (index < 1) {
				return random <= 0.5
			} else {
				return random > 0.5
			}
		}
	})

	const value = ind?.sortIndex ?? 0
	localStorage.setItem("RANDOM_INDEX", value.toString())
	return value
}

export const getABIndex = (abValue: ABTestValueDTO): number => {
	const index = localStorage.getItem("RANDOM_INDEX")

	if (index !== null) return Number(index)

	return randomIndex(abValue)
}
