import { LayoutType } from "@components/shared/layouts"
import styled, { css } from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: relative;
	width: 100%;
	height: 100%;

	display: flex;

	box-sizing: border-box;

	padding: 0px;
	margin: 0px;

	${({ size }) =>
		size === "Mobile"
			? css`
					min-width: 320px;
					min-height: 640px;
			  `
			: css`
					min-width: 993px;
					min-height: 800px;
			  `}
`
