import { ActionButton } from "@components/buttons"
import { Spacer } from "@components/spacer"
import { Trans } from "@lingui/macro"
import { useCallback, useEffect, useRef, useState } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { CircleOpen } from "../components/circle-open/circle-open"
import { LowerLineLottie } from "../components/lotties/lower-line-lottie"
import { UpperLineLottie } from "../components/lotties/upper-line-lottie"
import { Quote } from "../components/quote"
import { IQuoteScreenProps } from "./quote-screen"
import {
	MobileScreen,
	BackgroundImage,
	BackgroundImageDiv,
	ScreenContent,
	MobileButtonWrapper,
} from "./quote-screen.styles"

export const QuoteMobileScreen = ({ background, label, author, onNext, isShown }: IQuoteScreenProps) => {
	const size = useWindowLayout()

	const [loaded, setLoaded] = useState(false)
	const [circleAnimationStopped, setCircleAnimationStopped] = useState(true)
	const [linesAnimationStopped, setLinesAnimationStopped] = useState(true)

	const backgroundImgRef = useRef<HTMLDivElement>(null)
	const backgroundPhotoRef = useRef<HTMLImageElement>(null)
	const titleRef = useRef<HTMLDivElement>(null)
	const buttonRef = useRef<HTMLDivElement>(null)

	const onTitleAnimationFinished = useCallback(() => {
		if (buttonRef.current) {
			buttonRef.current.style.opacity = "1"
			// until now the button is disabled
			// as we do not want user to press the button
			// until it appears
			buttonRef.current.style.pointerEvents = "auto"
		}
	}, [buttonRef])

	const onCircleAnimationFinished = useCallback(() => {
		// immidiately launch tilte animation - duration 0.8s
		if (titleRef.current) {
			titleRef.current.style.bottom = "150px"
			titleRef.current.style.opacity = "1"
		}
		// immidiately launch lines animation
		if (linesAnimationStopped) {
			setLinesAnimationStopped(false)
		}
		// // in 1.2s launch button animation - duration 0.3s
		// setTimeout(() => {
		// 	if (buttonRef.current) {
		// 		buttonRef.current.style.opacity = "1"
		// 		// until now the button is disabled
		// 		// as we do not want user to press the button
		// 		// until it appears
		// 		buttonRef.current.style.pointerEvents = "auto"
		// 	}
		// }, 1200)
	}, [titleRef, setLinesAnimationStopped, linesAnimationStopped])

	useEffect(() => {
		if (circleAnimationStopped && loaded && isShown) {
			// loanch animation when ready
			setCircleAnimationStopped(false)
			if (backgroundImgRef.current && backgroundPhotoRef.current) {
				backgroundPhotoRef.current.style.visibility = "visible"
				backgroundImgRef.current.style.visibility = "visible"
			}
		}
	}, [loaded, isShown, circleAnimationStopped, backgroundImgRef, backgroundPhotoRef])

	return (
		<MobileScreen>
			<Spacer height="24px" />
			<ScreenContent>
				<BackgroundImage
					ref={backgroundPhotoRef}
					z={0}
					alt=""
					src={background}
					onLoad={() => {
						setLoaded(true)
					}}
				/>
				<BackgroundImageDiv z={2} ref={backgroundImgRef} />
				<UpperLineLottie z={4} isStopped={linesAnimationStopped} />
				<LowerLineLottie z={4} isStopped={linesAnimationStopped} />
				<Quote size={size} z={6} ref={titleRef} quote={label} author={author} onFinish={onTitleAnimationFinished} />
				<MobileButtonWrapper ref={buttonRef} z={10}>
					<ActionButton size={size} onClick={onNext}>
						<Trans>Continue</Trans>
					</ActionButton>
				</MobileButtonWrapper>
				<CircleOpen z={12} onFinish={onCircleAnimationFinished} isStopped={circleAnimationStopped} />
			</ScreenContent>
		</MobileScreen>
	)
}
