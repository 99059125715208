import { callFirebaseFunction } from "../server-api/call-firebase-function"
import { ApiResponse, API_RESPONSE_STATUS, ErrorData } from "./api/api-response"

interface PaypalSubscriptionCancelParams {
	domain: string
	subscriptionId?: string
}

export const paypalSubscriptionCancel = async (params: PaypalSubscriptionCancelParams): Promise<boolean> => {
	const response = await callFirebaseFunction<PaypalSubscriptionCancelParams, ApiResponse<boolean>>(
		"paypalSubscriptionCancelV3",
		params
	)
	if (response.status !== API_RESPONSE_STATUS.SUCCESS) {
		throw new Error(`SERVER returned error:
				status:${response.status}
				message:${(response.data as ErrorData).message}
				code:${(response.data as ErrorData).error}.`)
	}
	return response.data as boolean
}
