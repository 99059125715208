import { background } from "@components/shared/colors"
import styled, { css } from "styled-components"
import { IProgressLineProps } from "./progress-line"

export const Wrapper = styled.div<IProgressLineProps>`
	${({ size }) =>
		size === "Mobile"
			? css`
					width: 90px;
					height: 4px;
			  `
			: css`
					width: 180px;
					height: 6px;
			  `};

	position: relative;
	width: ${({ isStretch }) => isStretch && "100%"};
	border-radius: 4px;
	box-sizing: border-box;
	overflow: hidden;

	background: ${background.snow};
`

export const Line = styled.div<{ progress: number }>`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: ${({ progress }) => `${progress}%`};
	background: ${background.indigo};
	border-radius: 4px;

	transition: width 0.2s;
`
