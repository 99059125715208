import styled, { css } from "styled-components"

export const EchoUserReviews = styled.div`
	margin-top: 33px;
	margin-bottom: 30px;

	.title {
		padding: 10px 16px;
		background-color: #f6f7f9;
		border: #dbdde1 solid 1px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		font-family: Inter, sans-serif;
		font-weight: 700;
		font-size: 15px;
		line-height: 22px;
		color: #7b7b7d;
	}

	.review {
		padding: 16px;
		padding-top: 13px;
		background-color: #ffffff;
		border: #dbdde1 solid 1px;
		border-radius: 4px;
		margin-top: 8px;

		:first-child {
			margin-top: 0;
			border-top: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}

		.avatar {
			width: 44px;
			height: 44px;
			border-radius: 22px;
			margin-right: 10px;
		}

		.name {
			font-family: Inter, sans-serif;
			font-weight: 700;
			font-size: 15px;
			line-height: 22px;
			color: #4c6cbc;

			display: flex;
			flex-direction: row;
			align-items: flex-start;

			img {
				margin-left: 2px;
				width: 24px;
				height: 22px;
			}
		}

		.recommends {
			font-family: Inter, sans-serif;
			font-weight: 500;
			font-size: 15px;
			line-height: 21px;
			color: #7b7b7d;

			display: flex;
			flex-direction: row;
			align-items: flex-start;

			.power {
				color: #4c6cbc;
				line-height: 22px;
			}

			img {
				margin-left: 3px;
				width: 22px;
				height: 21px;
			}
		}

		.row {
			display: flex;
			flex-direction: row;
		}

		.col {
			display: flex;
			flex-direction: column;
		}

		.message {
			margin-top: 12px;
			font-family: Inter, sans-serif;
			font-weight: 500;
			font-size: 15px;
			line-height: 22px;
			color: #000000;
		}
	}

	.view-more {
		margin-top: 16px;
		width: 100%;
		text-align: center;
		font-family: Inter, sans-serif;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		color: #5856d6;
		cursor: pointer;

		appearance: none;
		background-color: transparent;
		border: 0;

		:active {
			transform: translateY(2px);
		}
	}
`

export const PaymentMethodWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: calc(50% - 12px);
	height: 55px;
	margin: 6px;
	box-sizing: content-box;
`

export const PaymentMethod = styled(PaymentMethodWrapper)<{ active?: boolean; isPaypal?: boolean }>`
	background: #ffffff;
	box-shadow: 0px 1.3985px 5.59402px rgba(0, 0, 0, 0.08), 0px 1.3985px 4.19551px rgba(0, 0, 0, 0.02);
	border-radius: 12px;

	padding: 0 25px;

	> img:nth-child(2) {
		margin-left: 10px;
	}

	z-index: 2;

	${({ active }) =>
		active
			? css`
					:before {
						content: "";
						position: absolute;
						top: -6px;
						left: -6px;
						width: 100%;
						height: 100%;
						border: 6px solid #c4c4c4;
						opacity: 0.24;
						border-radius: 16px;
						z-index: 1;
					}
			  `
			: ""}
`

export const PayPalButtonWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(50% - 12px);
	min-width: calc(50% - 12px);
	height: 55px;
	margin: 6px;

	box-sizing: content-box;

	background: #ffffff;
	box-shadow: 0px 1.3985px 5.59402px rgba(0, 0, 0, 0.08), 0px 1.3985px 4.19551px rgba(0, 0, 0, 0.02);
	border-radius: 12px;

	/* padding: 0 25px; */
	padding: 0;
	/* overflow: hidden; */
	/* cursor: pointer; */
	z-index: 3000;

	/* stylelint-disable-next-line no-descending-specificity */
	> img {
		pointer-events: none;
		z-index: 2000;
		width: auto;
		height: 26px;
		position: absolute;
		/* top: calc(50% - 13px); */
		/* left: 0; */
		margin-left: 0;
	}

	/* filter: invert(1); */
`

export const BenefitsList = styled.div`
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	.item {
		margin-top: 8px;
		display: flex;
		flex-direction: row;

		img {
			width: 24px;
			height: 24px;
		}
		.text {
			margin-left: 8px;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			color: #828691;
		}
	}
	.item:first-child {
		margin-top: 0;
	}
`
