//import AsyncStorage from "@react-native-community/async-storage"
//TODO: change to roborox service

export const saveToLocalStorage = async (key: string, value: string) => {
	//use async storage in react native
	//await AsyncStorage.setItem(key, value)
	//use window.localStorage in react js
	window.localStorage.setItem(key, value)
}
export const getFromLocalStorage = async (key: string) => {
	//use async storage in react native
	//return (await AsyncStorage.getItem(key)) || ""
	//use window.localStorage in react js
	return window.localStorage.getItem(key) || ""
}
export const removeFromLocalStorage = async (key: string) => {
	//use async storage in react native
	//await AsyncStorage.removeItem(key)
	//use window.localStorage in react js
	window.localStorage.removeItem(key)
}
