import AlbertHoward from "@assets/Albert Howard.png"
import BrookeFleming from "@assets/Brooke Fleming.png"
import ChadKirk from "@assets/Chad Kirk.png"
import ChristianGeis from "@assets/Christian Geis.png"
import FordBush from "@assets/Ford Bush.png"
import PaulJenning from "@assets/Paul Jenning.png"
import { t } from "@lingui/macro"

export type IReview = {
	name: string
	avatar: string
	message: string
}

export const reviews: IReview[] = [
	{
		name: t`Ivan Morsi`,
		avatar: require("./assets/Ivan Morsi.png").default,
		message: t`The exercises and massages are simple, so I enjoy doing them everyday. They have given me amazing results. Thank you so much for bringing them to us.`,
	},
	{
		name: t`Rosy Walker`,
		avatar: require("./assets/Rosy Walker.png").default,
		message: t`Evening beauty massage! I felt even more beautiful this evening ❤️`,
	},
	{
		name: t`Sarah Rosalie`,
		avatar: require("./assets/Sarah Rosalie.png").default,
		message: t`It is very simple and you can see immediate results , no chemicals`,
	},
	{
		name: t`Carla Flatcher`,
		avatar: require("./assets/Carla Flatcher.png").default,
		message: t`Enjoying the face magic very much! Thank you!`,
	},
	{
		name: t`Terri Stolpa`,
		avatar: require("./assets/Terri Stolpa.png").default,
		message: t`These exercises are not only helping to refresh the appearance of my face but they are helping to clear up my brain fog as well. This is a great program for self care!`,
	},
	{
		name: t`Scarlett Oherav`,
		avatar: require("./assets/Scarlett Oherav.png").default,
		message: t`I'm turning 45 this year and even though people guess me 10 years younger, I have been starting to notice the signs of aging on my face. I've been considering facial yoga for some time now as a natural anti aging solution. This massage was excellent, really made my face feel great, and I can feel my sinuses draining. Can't wait to see the changes in my face over time!`,
	},
	{
		name: t`Ketryn Gilbert`,
		avatar: require("./assets/Ketryn Gilbert.png").default,
		message: t`Felt tighter in my face after the first session! I was skeptical but feeling immediate results helped ease my skepticism! I will say I feel $10 a week is outrageous!`,
	},
]

export const reviewsMan: IReview[] = [
	{
		name: "Ivan Morsi",
		avatar: require("./assets/Ivan Morsi.png").default,
		message:
			"The exercises and massages are simple, so I enjoy doing them everyday. They have given me amazing results. Thank you so much for bringing them to us.",
	},
	{
		name: "Paul Jenning",
		avatar: PaulJenning,
		message: "Evening beauty massage! I felt even more beautiful this evening ❤️",
	},
	{
		name: "Brooke Fleming",
		avatar: BrookeFleming,
		message: "It is very simple and you can see immediate results , no chemicals",
	},
	{
		name: "Albert Howard",
		avatar: AlbertHoward,
		message: "Enjoying the face magic very much! Thank you!",
	},
	{
		name: "Christian Geis",
		avatar: ChristianGeis,
		message:
			"These exercises are not only helping to refresh the appearance of my face but they are helping to clear up my brain fog as well. This is a great program for self care!",
	},
	{
		name: "Chad Kirk",
		avatar: ChadKirk,
		message:
			"I'm turning 45 this year and even though people guess me 10 years younger, I have been starting to notice the signs of aging on my face. I've been considering facial yoga for some time now as a natural anti aging solution. This massage was excellent, really made my face feel great, and I can feel my sinuses draining. Can't wait to see the changes in my face over time!",
	},
	{
		name: "Ford Bush",
		avatar: FordBush,
		message:
			"Felt tighter in my face after the first session! I was skeptical but feeling immediate results helped ease my skepticism! I will say I feel $10 a week is outrageous!",
	},
]
