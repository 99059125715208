export const background = {
	white: "#FFFFFF",
	milk: "#FEFEFC",
	concrete: "#F6F6F6",
	lotion: "#FAFAFA",
	ghost: "#F9F9F9",
	mercury: "#ECECEC",
	rain: "#B7B9C0",
	smoke: "#828691",

	app: "#F6F6F6",

	snow: "#E8E9EB",

	meteorite: "#32237D",

	hepatica: "#6967EC",

	sea: "#5856EA",
	indigo: "#5856D6",
	lightIndigo: "rgba(88, 86, 214, 0.08)",

	moon: "#C0C0F2",
	cornflower: "#706FEB",

	pale: "#FFD1DA",
	pippin: "#FFDFE6",
	pig: "#FFE1E7",

	black: "#000000",

	malachite: "#15CE28",
} as const

export const color = {
	meteorite: "#32237D",
	sea: "#5856EA",
	indigo: "#5856D6",

	lilac: "#C4C2FF",

	blue: "4C6CBC",

	white: "#FFFFFF",
	milk: "#FEFEFC",
	concrete: "#F6F6F6",
	ghost: "#F9F9F9",
	mercury: "#ECECEC",
	rain: "#B7B9C0",
	smoke: "#828691",
	steel: "#7b7b7b",

	yankees: "#1C1B47",
	dark: "#222533",
	black: "#000000",

	border: "#B4B7BE",

	lightShadow: "rgba(0, 0, 0, 0.04)",
	mediumShadow: "rgba(0, 0, 0, 0.05)",
	darkShadow: "rgba(0, 0, 0, 0.1)",

	malachite: "#15CE28",
	coral: "#FD3E3E",
	desert: "#FFBA6A",
} as const
