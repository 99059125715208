import { LayoutType } from "@components/shared/layouts"
import styled, { css } from "styled-components"

export const ScreenViewPort = styled.div<{ z?: number }>`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100vh;
	min-height: -webkit-fill-available;
	max-height: -webkit-fill-available;

	background-color: #f6f6f6;

	border-width: 0px;
	box-sizing: border-box;

	z-index: ${({ z }) => z ?? 50};

	overflow: hidden;
`

export const ScreenContent = styled.div`
	position: absolute;

	width: 0;
	height: 0;

	left: 0;
	bottom: 0;

	border-width: 0px;
	box-sizing: border-box;

	transition: left 0.3s ease-in-out;
`

export const Wrapper = styled.div<{ z?: number }>`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100vh;
	min-height: -webkit-fill-available;
	max-height: -webkit-fill-available;

	background-color: #f6f6f6;

	border-width: 0px;
	box-sizing: border-box;

	z-index: ${({ z }) => z ?? 50};

	overflow: hidden;
`

export const CurrentStepWrapper = styled.div<{
	transitionEnabled: boolean
	isPaywall?: boolean
	isTopBarHidden?: boolean
	size: keyof typeof LayoutType
}>`
	position: relative;

	${({ isPaywall, transitionEnabled, isTopBarHidden, size }) =>
		isPaywall
			? css`
					display: none;
			  `
			: css`
					position: absolute;

					width: 100%;
					height: ${!!isTopBarHidden || size === "Desktop" ? "100%" : "calc(100% - 64px)"};

					border: 2px solid red;

					left: 0;
					bottom: 0;

					border-width: 0px;
					box-sizing: border-box;

					transition: ${transitionEnabled ? "left 0.3s" : "none"};
			  `}
`

export const FirstStepWrapper = styled.div<{ transitionEnabled: boolean }>`
	${({ transitionEnabled }) => css`
		position: absolute;

		width: 100%;
		height: calc(100% - 64px);

		left: 0;
		bottom: -100%;

		opacity: 0;

		transition: ${transitionEnabled ? "bottom 0.3s, left 0.3s, opacity 2s ease-out" : "none"};
	`}
`

export const PrevStepWrapper = styled(CurrentStepWrapper)`
	left: -100%;
`

export const NextStepWrapper = styled(CurrentStepWrapper)`
	left: 100%;
`

export const ProgressWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const DesktopProgressWrapper = styled(ProgressWrapper)`
	position: absolute;
	left: 0;
	bottom: 0;
`

export const BackButtonWrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	z-index: 9999;
	${({ size }) =>
		size === "Mobile"
			? css`
					top: 12px;
					left: 20px;
			  `
			: css`
					top: 32px;
					left: 80px;
			  `};
`
