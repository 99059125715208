import React, { CSSProperties } from "react"
import styled from "styled-components"

interface IBubble {
	height?: number
	padding?: string
	margin?: string
	shadow?: boolean
	value?: string
	onClick?: () => void
}

export interface IBubbleProps extends IBubble {
	style?: CSSProperties
	className?: string
	children: React.ReactNode
}

const StyledBubble = styled.div<IBubble>`
	position: relative;
	width: 100%;
	height: auto;
	background: #ffffff;
	border-radius: 16px;
	box-sizing: border-box;

	min-height: ${({ height }) => `${height}px` || "auto"};
	padding: ${({ padding }) => padding || "14px 20px"};
	margin: ${({ margin }) => margin || 0};

	box-shadow: ${({ shadow }) =>
		shadow
			? "0px 10px 10px rgba(0, 0, 0, 0.04), 0px 24px 32px rgba(0, 0, 0, 0.1)"
			: "0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05)"};
`

export function Bubble(props: IBubbleProps) {
	return (
		<StyledBubble {...props} data-value={props.value} onClick={props.onClick}>
			{props.children}
		</StyledBubble>
	)
}
