import MiniSlide1 from "@assets/MiniSlide-1.jpeg"
import MiniSlide2 from "@assets/MiniSlide-2.png"
import Slide1 from "@assets/Slide1.png"
import { t } from "@lingui/macro"
import { Carousel, CarouselItemProps } from "~/components2/atoms/carousel"

import * as A from "./atoms"

export const miniSlides = [
	{
		main: true,
		img: <img alt="" src={Slide1} />,
		title: t`My Personal Course`,
		subtitle: t`day 3, week 1`,
	},
	{
		main: false,
		img: <img alt="" src={MiniSlide2} style={{ width: 279, position: "relative", top: 0, left: -37 }} />,
		title: t`Double Chin Destroyer`,
		subtitle: t`Reduce swelling and fat v...`,
	},
	{
		main: false,
		img: (
			<img
				alt=""
				src={MiniSlide1}
				style={{ width: 219, position: "relative", top: -31, left: -30, transform: "scale(-1, 1)" }}
			/>
		),
		title: t`Morning Wake Up Call`,
		subtitle: t`Get ready for the day`,
	},
]

export type MiniSlideProps = CarouselItemProps<typeof miniSlides[number]>
export function MiniSlide({ item: { img, main, title, subtitle }, selectItem }: MiniSlideProps) {
	return (
		<A.Slide main={main} onClick={selectItem}>
			{img}
			<A.SlideInfo main={main}>
				<A.SlideTitle main={main}>{title}</A.SlideTitle>
				<A.SlideSubTitle main={main}>{subtitle}</A.SlideSubTitle>
			</A.SlideInfo>
		</A.Slide>
	)
}

export function MiniCarousel() {
	return (
		<A.MiniCarouselWrapper>
			<A.MiniCarouselSpacer />
			<Carousel
				items={miniSlides}
				keyGetter={(item) => item.title}
				renderItem={(props) => <MiniSlide {...props} />}
				itemSize={{ width: 204, height: 230 }}
			/>
		</A.MiniCarouselWrapper>
	)
}
