import { color } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { typography } from "@components/shared/typography"
import styled, { keyframes } from "styled-components"

export const move = keyframes`
0% {
bottom: -26px;
	opacity: 0;
}
	84% {
		bottom: -56px;
		opacity: 1;
	}
	100% {
		bottom: -56px;
		opacity: 0;
	}
`

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	width: 100%;
	height: 100%;

	overflow-y: scroll;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}
`

export const LandingWrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: relative;
	width: ${({ size }) => (size === "Mobile" ? "100%" : "702px")};
	height: 100%;

	overflow: hidden;

	z-index: 80;
`

export const LandingBody = styled.div<{ size: keyof typeof LayoutType }>`
	position: relative;
	width: 100%;
	height: 100%;
	padding: 0 20px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;
	z-index: 50;

	margin-top: -60px;

	> img {
		margin: 0;
	}

	> p {
		max-width: 100%;

		font-family: ${typography.family.inter};
		font-style: ${typography.fontStyle.default};
		font-weight: ${typography.fontWeight.extrabold};
		letter-spacing: ${typography.letterSpacing.h}em;
		font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm3 : typography.fontSize.h2)}px;
		line-height: 110%;
		/* or 35px */
		text-align: center;

		color: ${color.milk};

		margin: 16px 0 24px;
	}

	> div {
		display: flex;
		flex-direction: row;
		width: 100%;

		> button {
			margin: 0 4px;
		}
	}
`

export const Background = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #5856ea;

	z-index: 5;
	overflow: hidden;
`

export const Logo = styled.div`
	position: absolute;
	top: 20px;
	left: 0;
	width: 100%;
	height: 24px;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	> img {
		width: auto;
		height: 100%;
	}
`

export const Copyright = styled.p`
	position: absolute;
	bottom: 32px;
	left: 0;
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	letter-spacing: ${typography.letterSpacing.b}em;
	font-size: ${typography.fontSize.bm2}px;
	line-height: ${typography.lineSize.bm4}px;

	color: ${color.concrete};

	opacity: 0.4;

	width: 100%;
	text-align: center;

	z-index: 900;
`

export const TextWithImage = styled.p`
	width: 100%;
	text-align: center;
	position: relative;

	> img {
		width: 28px;
		height: 14px;
		position: absolute;
		opacity: 1;
		bottom: -26px;
		left: calc(50% - 14px);

		animation: ${move} 1.4s ease-out infinite;
	}
`

export const ButtonsWrapper = styled.div`
	position: relative;
	margin-top: 84px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`
