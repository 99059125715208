import { callFirebaseFunction } from "./call-firebase-function"
import { RESPONSE_CODE } from "./response-codes"

export interface GetSignInTokenParams {
	email?: string
	code?: string
}
export interface GetSignInTokenResponse {
	// This sub enum helps us to control possible error codes returns by the function
	// and provide front-end developers with consitent data.
	code:
		| RESPONSE_CODE.FORBIDDEN
		| RESPONSE_CODE.BAD_REQUEST
		| RESPONSE_CODE.USER_NOT_EXIST
		| RESPONSE_CODE.NOT_FOUND
		| RESPONSE_CODE.NOT_VALID
		| RESPONSE_CODE.TOO_MANY_REQUESTS
		| RESPONSE_CODE.INCORRECT
		| RESPONSE_CODE.UNEXPECTED_ERROR
		| RESPONSE_CODE.SUCCESS

	message: string

	data?: {
		token?: string
		waitTime?: number
	}
}

/**
 * Returns token to sign in. User should exist. When call this function no one user should be signed in.
 * Process the following codes, throw an error in other cases.
 * RESPONSE_CODE.USER_NOT_EXIST
 * RESPONSE_CODE.TOO_MANY_REQUESTS
 * RESPONSE_CODE.INCORRECT
 * RESPONSE_CODE.SUCCESS
 * RESPONSE_CODE.NOT_VALID
 */
export const getSignInToken = async (params: GetSignInTokenParams): Promise<GetSignInTokenResponse> => {
	const response = await callFirebaseFunction<GetSignInTokenParams, GetSignInTokenResponse>("getSignInToken", params)

	return response
}
