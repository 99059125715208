import { ActionButton } from "@components/buttons"
import { Spacer } from "@components/spacer"
import { Trans } from "@lingui/macro"
import { useCallback, useEffect, useRef, useState } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { CircleOpen } from "../components/circle-open/circle-open"
import { ContentScrollable } from "../components/content-scrollable/content-scrollable"
import { LowerLineLottie2 } from "../components/lotties/lower-line-lottie-2"
import { UpperLineLottie2 } from "../components/lotties/upper-line-lottie-2"
import { QuoteList } from "../components/quote-list"
import { Screen1Title } from "../components/screen-1-title"
import { Screen2Title } from "../components/screen-2-title"
import { IQuoteListScreenProps } from "./quote-list-screen"
import {
	MobileScreen,
	BackgroundImage,
	BackgroundImageDiv,
	ScreenContent,
	PurpleBackgroundDiv,
	FadeDiv,
	MobileButtonWrapper,
} from "./quote-list-screen.styles"

export const QuoteListMobileScreen = ({
	background,
	label,
	title2,
	quote1,
	author1,
	quote2,
	author2,
	onNext,
	isShown,
}: IQuoteListScreenProps) => {
	const size = useWindowLayout()

	const [backgroundImageLoaded, setBackgroundImageLoaded] = useState(false)
	const [circleAnimationStopped, setCircleAnimationStopped] = useState(true)
	const [curvesAnimationStopped, setCurvesAnimationStopped] = useState(true)

	const backgroundImgRef = useRef<HTMLDivElement>(null)
	const backgroundPhotoRef = useRef<HTMLImageElement>(null)
	const title1Ref = useRef<HTMLDivElement>(null)
	const title2Ref = useRef<HTMLDivElement>(null)
	const quoteListRef = useRef<HTMLDivElement>(null)
	const purpleBackgroundRef = useRef<HTMLDivElement>(null)
	const buttonRef = useRef<HTMLDivElement>(null)
	const fadeRef = useRef<HTMLDivElement>(null)

	const [linesOpacity, setLinesOpacity] = useState(1)

	// MOBILE ANIMATIONS
	const onCircleAnimationFinished = useCallback(() => {
		// immidiately launch title animation - duration 0.8s
		if (title1Ref.current) {
			title1Ref.current.style.bottom = "180px"
			title1Ref.current.style.opacity = "1"
		}
		// in 0.5s launch curves animation - duration 0.8s
		setTimeout(() => {
			if (curvesAnimationStopped) {
				setCurvesAnimationStopped(false)
			}
		}, 500)
		// in 3.6s show purple background - duration 0.7s
		setTimeout(() => {
			if (purpleBackgroundRef.current) {
				setLinesOpacity(0.2)
				purpleBackgroundRef.current.style.opacity = "1"
			}
		}, 3600)
		// in 3.9s launch title2 animation - duration 0.8s
		setTimeout(() => {
			if (title2Ref.current) {
				title2Ref.current.style.top = "80px"
				title2Ref.current.style.opacity = "1"
			}
		}, 3900)
		// in 4.2s launch title2 animation - duration 0.8s
		setTimeout(() => {
			if (quoteListRef.current) {
				quoteListRef.current.style.top = "182px"
				quoteListRef.current.style.opacity = "1"
			}
		}, 4200)
		// in 4.9s launch button animation - duration 0.3s
		setTimeout(() => {
			if (buttonRef.current && fadeRef.current) {
				fadeRef.current.style.visibility = "visible"
				buttonRef.current.style.opacity = "1"
				// until now the button is disabled
				// as we do not want user to press the button
				// until it appears
				buttonRef.current.style.pointerEvents = "auto"
			}
		}, 4900)
	}, [title1Ref, buttonRef, purpleBackgroundRef, title2Ref, curvesAnimationStopped, setCurvesAnimationStopped])

	useEffect(() => {
		// start animation when screen is ready
		if (circleAnimationStopped && backgroundImageLoaded && isShown) {
			setCircleAnimationStopped(false)
			if (backgroundImgRef.current && backgroundPhotoRef.current) {
				backgroundPhotoRef.current.style.visibility = "visible"
				backgroundImgRef.current.style.visibility = "visible"
			}
		}
	}, [backgroundImageLoaded, isShown, circleAnimationStopped, backgroundImgRef, backgroundPhotoRef])

	return (
		<MobileScreen>
			<Spacer height="24px" />
			{/** relative full sized div for positions absolute children elements */}
			<ScreenContent>
				<BackgroundImage
					ref={backgroundPhotoRef}
					z={0}
					alt=""
					src={background}
					onLoad={() => {
						setBackgroundImageLoaded(true)
					}}
				/>
				<BackgroundImageDiv z={2} ref={backgroundImgRef} />
				<Screen1Title z={4} size={size} ref={title1Ref} title={label} />
				<PurpleBackgroundDiv z={6} ref={purpleBackgroundRef} />
				<UpperLineLottie2 z={8} isStopped={curvesAnimationStopped} opacity={linesOpacity} />
				<LowerLineLottie2 z={8} isStopped={curvesAnimationStopped} opacity={linesOpacity} />
				<ContentScrollable z={10}>
					<Screen2Title z={2} ref={title2Ref} title={title2} size={size} />
					<QuoteList
						z={4}
						size={size}
						ref={quoteListRef}
						quote1={quote1}
						author1={author1}
						quote2={quote2}
						author2={author2}
					/>
				</ContentScrollable>
				<CircleOpen z={14} onFinish={onCircleAnimationFinished} isStopped={circleAnimationStopped} />
				<FadeDiv z={12} ref={fadeRef} />
				<MobileButtonWrapper z={15} ref={buttonRef}>
					<ActionButton size={size} onClick={onNext}>
						<Trans>Continue</Trans>
					</ActionButton>
				</MobileButtonWrapper>
			</ScreenContent>
		</MobileScreen>
	)
}
