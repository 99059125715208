import { useHistory } from "react-router"
import { useQueryParams } from "~/hooks/use-query-params"
import { DeltaLanding } from "./delta-landing"

export const DeltaLandingContainer = () => {
	const history = useHistory()
	const onboardingType = useQueryParams().get("onboardingType")

	const onNext = () => {
		history.push(`/personal-course${onboardingType ? `?onboardingType=${onboardingType}` : ""}`)
	}

	return <DeltaLanding onNext={onNext} onboardingType={onboardingType} />
}
