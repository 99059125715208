import { animated } from "@react-spring/web"
import styled from "styled-components"

export const Container = styled.div`
	width: 100%;
`

export const Items = styled.div<{ height: number }>`
	/* overflow-x: hidden; */
	/* position: relative; */
	width: 100%;
	height: ${({ height }) => height}px;
`

export const Item = styled(animated.div)`
	user-select: none;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	max-width: 500px;

	& img {
		-webkit-user-drag: none;
	}
`

export const Dots = styled.div`
	width: 100%;
	margin-top: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

export const DotButton = styled.div`
	width: 12px;
	height: 12px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
`
export const Dot = styled.div<{ active: boolean }>`
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: ${({ active }) => (active ? "#6967EC" : "#DBDBF6")};
`
