import { useEffect, useRef } from "react"
import { Hole, HoleCircle, HoleContainer } from "./circle-open.styles"

export interface IComponentProps {
	isStopped: boolean
	onFinish: () => void
	z?: number
}

export const CircleOpen = ({ isStopped, onFinish, z }: IComponentProps) => {
	const holeRef = useRef<HTMLDivElement>(null)
	const holeContainerRef = useRef<HTMLDivElement>(null)
	const holeCircleRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (!isStopped) {
			if (holeRef.current && holeCircleRef.current && holeContainerRef.current) {
				// padding from the top after animation
				holeRef.current.style.height = "35px"
				// height of the box, circle(hole) inscribed in, after animation
				// it should be twice as big as padding from the top after animation
				holeCircleRef.current.style.height = "70px"
				// width of the box, circle(hole) inscribed in, after animation
				// it affects the radius of the circle
				holeContainerRef.current.style.width = "120%"
			}
		}
	}, [holeRef, holeCircleRef, holeContainerRef, isStopped])

	return (
		<Hole ref={holeRef} z={z} onTransitionEnd={onFinish}>
			<HoleContainer ref={holeContainerRef}>
				<HoleCircle ref={holeCircleRef} />
			</HoleContainer>
		</Hole>
	)
}
