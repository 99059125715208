import firebase from "firebase/app"
// Required for side-effects
import "firebase/auth"
import "firebase/remote-config"
require("firebase/functions")
require("firebase/firestore")

let firebaseConfig: any = {
	apiKey: process.env.REACT_APP_FIREBASE_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

if (process.env.REACT_APP_FIREBASE_DATABASE) {
	firebaseConfig = {
		...firebaseConfig,
		databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
	}
}

if (process.env.REACT_APP_FIREBASE_MEASUREMENT_ID) {
	firebaseConfig = {
		...firebaseConfig,
		measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
	}
}

export const firebaseApp = firebase.initializeApp(firebaseConfig)
export const firebaseFunctions = firebaseApp.functions()
export const firestore = firebaseApp.firestore()
export const firebaseRemoteConfig = firebaseApp.remoteConfig()
