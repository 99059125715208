import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import styled, { css } from "styled-components"

export const Wrapper = styled.div<{ windowHeight: number }>`
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: ${({ windowHeight }) => windowHeight}px;
	min-height: ${({ windowHeight }) => windowHeight}px;
	margin: 0;
	overflow: hidden;

	box-sizing: border-box;
`
// export const Wrapper = styled.div`
// 	position: relative;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	margin: 0;
// 	overflow: hidden;

// 	box-sizing: border-box;

// 	height: 100vh;
// 	min-height: -webkit-fill-available;
// 	max-height: -webkit-fill-available;
// `

export const Background = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;

	background: ${background.sea};

	z-index: 1;
`

export const Content = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	overflow-x: hidden;
	box-sizing: border-box;

	${({ size }) =>
		size === "Mobile"
			? css`
					width: 100%;
					left: 0;
					padding: 0 ${spacing.padding.horizontalSmall}px;
			  `
			: css`
					width: 560px;
					left: calc(50% - 280px);
			  `};

	z-index: 100;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	padding-bottom: ${({ size }) => (size === "Mobile" ? "128px" : "160px")};

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: transparent;
		outline: none;
		border-radius: ${spacing.borderRadius.enormous}px;
	}
`

export const PaywallCardWrapper = styled.img<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? "100%" : "380px")};
	margin: ${({ size }) => (size === "Mobile" ? "16px 0 -50px" : "16px 0 -60px")};

	max-width: 380px;
`

export const Title = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};

	line-height: 110%;
	letter-spacing: ${typography.letterSpacing.h};
	text-align: center;

	margin: ${({ size }) => (size === "Mobile" ? "16px 0 8px" : "24px 0 12px")};

	${({ size }) =>
		size === "Mobile"
			? css`
					font-size: ${typography.fontSize.hm2}px;
					color: ${color.milk};
			  `
			: css`
					font-size: ${typography.fontSize.h2}px;
					color: ${color.white};
			  `};
`

export const Description = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};

	letter-spacing: ${typography.letterSpacing.b};
	text-align: center;

	opacity: ${typography.transparency.almost};

	margin: 0;

	padding: 0 40px;
	box-sizing: border-box;

	${({ size }) =>
		size === "Mobile"
			? css`
					font-size: ${typography.fontSize.bm1}px;
					line-height: ${typography.lineSize.bm1}px;

					color: ${color.milk};
			  `
			: css`
					font-size: ${typography.fontSize.b1}px;
					line-height: ${typography.lineSize.b1}px;

					color: ${color.white};
			  `};
`

export const Areas = styled.div<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? "100%" : "380px")};
	margin: ${({ size }) => (size === "Mobile" ? "8px 0" : "24px 0")};

	> small {
		font-family: ${typography.family.inter};
		font-style: ${typography.fontStyle.default};
		font-weight: ${typography.fontWeight.bold};

		letter-spacing: ${typography.letterSpacing.b};

		opacity: ${typography.transparency.almost};

		${({ size }) =>
			size === "Mobile"
				? css`
						font-size: ${typography.fontSize.bm1}px;
						line-height: ${typography.lineSize.bm1}px;

						color: ${color.milk};
				  `
				: css`
						font-size: ${typography.fontSize.b1}px;
						line-height: ${typography.lineSize.b1}px;

						color: ${color.concrete};
				  `};
	}

	> span {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		> p {
			font-family: ${typography.family.inter};
			font-style: ${typography.fontStyle.default};
			font-weight: ${typography.fontWeight.bold};

			letter-spacing: ${typography.letterSpacing.b};

			padding: 6px 12px;
			background: ${background.hepatica};
			border-radius: ${spacing.borderRadius.huge}px;

			margin: 4px 2px;

			${({ size }) =>
				size === "Mobile"
					? css`
							font-size: ${typography.fontSize.bm1}px;
							line-height: ${typography.lineSize.bm3}px;

							color: ${color.white};
					  `
					: css`
							font-size: ${typography.fontSize.b1}px;
							line-height: ${typography.lineSize.b2}px;

							color: ${color.concrete};
					  `};
		}
	}
`

export const PinkCircleWrapper = styled.img`
	position: absolute;
	bottom: 15%;
	left: 0;
	width: 30%;
	height: auto;
`

export const OrangeCircleWrapper = styled.img`
	position: absolute;
	bottom: 0;
	right: 0;
	width: 30%;
	height: auto;
`

export const WhiteStarWrapper = styled.img`
	position: absolute;
	height: auto;
`

export const MobileWhiteStarWrapper = styled(WhiteStarWrapper)`
	bottom: 100px;
	right: -10px;
	width: 80px;
`

export const DesktopWhiteStarWrapper = styled(WhiteStarWrapper)`
	bottom: 12%;
	left: 8%;
	width: 154px;
`

export const PinkStarWrapper = styled.img`
	position: absolute;
	bottom: 44%;
	right: 10%;
	width: 120px;
	height: auto;
`

export const ActionButtonWrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	bottom: 0;
	left: ${({ size }) => (size === "Mobile" ? 0 : "calc(50% - 240px)")};
	width: ${({ size }) => (size === "Mobile" ? "100%" : "480px")};
	height: ${({ size }) => (size === "Mobile" ? "144px" : "200px")};
	padding: ${({ size }) => size === "Mobile" && `0 ${spacing.padding.horizontalSmall}px`};
	z-index: 1000;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(88, 86, 234, 0) 0%, #5856ea 38%);

	> button {
		max-width: 380px;
	}
`

// export const ActionButtonWrapper = styled.div`
// 	height: 100px;
// 	width: 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	position: fixed;
// 	bottom: 0;
// 	left: 0;
// 	z-index: 9999;
// `
