import RatingImage from "@assets/rating.svg"
import { LayoutType } from "@components/shared/layouts"
import { Spacer } from "@components/spacer"
import React from "react"
import { AuthorParagraph, QuoteParagraph, RatingImg, QuoteContainerDiv } from "../quote-screen/quote-screen.styles"

export const Quote = React.forwardRef(
	(
		{
			z,
			quote,
			author,
			size,
			onFinish,
		}: {
			z: number
			quote: string
			author: string
			size: keyof typeof LayoutType
			onFinish?: () => void
		},
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		return (
			<QuoteContainerDiv z={z} ref={ref} size={size} onTransitionEnd={onFinish}>
				<RatingImg alt="" src={RatingImage} />
				<Spacer height="15px" />
				<QuoteParagraph size={size}>{quote}</QuoteParagraph>
				<Spacer height="12px" />
				<AuthorParagraph size={size}>{author}</AuthorParagraph>
			</QuoteContainerDiv>
		)
	}
)
