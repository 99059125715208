import { useCallback } from "react"
import { useEffect, useMemo, useState } from "react"
import { useResizeDetector } from "react-resize-detector"
import { destructuringWithGroupsHelper } from "~/utils/destructuring-with-groups-helper"
import { StylableProps } from "~/utils/types"
import * as A from "./atoms"
import { CarouselDots, CarouselDotsProps } from "./dots"
import { CarouselItems, CarouselItemsProps } from "./items"
import { CarouselValueProps, Size } from "./types"

export type CarouselProps<T> = Omit<
	CarouselItemsProps<T>,
	"wrapperWidth" | "itemSize" | keyof CarouselValueProps | keyof StylableProps
> &
	Omit<CarouselDotsProps<T>, keyof CarouselValueProps | keyof StylableProps> & {
		itemSize?: Partial<Size>
		dots?: boolean
		autoPlay?: boolean
		playInterval?: number
		initialValue?: number
	} & Partial<CarouselValueProps> &
	StylableProps &
	StylableProps<"items-"> &
	StylableProps<"dots-">

export function Carousel<T>({
	itemSize: propItemSize = {},
	dots = false,
	autoPlay = false,
	playInterval = 3000,
	initialValue = 0,
	value: propValue,
	onValueChanged: propOnValueChange,
	...props
}: CarouselProps<T>) {
	const { styleProps, itemsProps, dotsProps } = useMemo(() => {
		return destructuringWithGroupsHelper<typeof props>()({
			styleProps: ["className", "style"],
			itemsProps: [
				"items",
				"keyGetter",
				["items-className", "className"],
				["items-style", "style"],
				"item-className",
				"item-style",
			],
			dotsProps: [
				"items",
				"keyGetter",
				["dots-className", "className"],
				["dots-style", "style"],
				"dot-className",
				"dot-style",
			],
		} as const)({ allowMerge: true, rest: "itemsProps" })
	}, [])(props)

	const valueState = useState(initialValue)
	const value = propValue ?? valueState[0]
	const setValue = propOnValueChange ?? valueState[1]
	const valueProps: CarouselValueProps = {
		value,
		onValueChanged: useCallback(
			(value: number) => {
				const length = itemsProps.items.length
				setValue(((value % length) + length) % length)
			},
			[itemsProps.items.length, setValue]
		),
	}

	const wrapper = useResizeDetector()
	const wrapperSize = useMemo(() => {
		return { width: wrapper.width ?? 0, height: wrapper.height ?? 0 }
	}, [wrapper.height, wrapper.width])

	const itemSize = useMemo(() => {
		const itemWidth = propItemSize.width || 1
		const itemHeight = propItemSize.height || 1
		return {
			width: itemWidth < 2 ? wrapperSize.width * itemWidth : itemWidth,
			height: itemHeight < 2 ? wrapperSize.height * itemHeight : itemHeight,
		}
	}, [propItemSize, wrapperSize.height, wrapperSize.width])

	useEffect(() => {
		if (!autoPlay) return
		const timeout = setTimeout(() => {
			setValue(value + 1)
		}, playInterval)
		return () => {
			clearTimeout(timeout)
		}
	}, [autoPlay, playInterval, setValue, value])

	return (
		<A.Container {...styleProps}>
			<A.Container ref={wrapper.ref}>
				<CarouselItems {...itemsProps} {...valueProps} wrapperWidth={wrapperSize.width} itemSize={itemSize} />
			</A.Container>
			{dots ? <CarouselDots {...dotsProps} {...valueProps} /> : null}
		</A.Container>
	)
}
