import BMJ from "@assets/BMJ.png"
import JAMA from "@assets/JAMA.png"
import NCBI from "@assets/NCBI.png"
import { t } from "@lingui/macro"
import FiveStars from "~/assets/FiveStars.png"

import { Carousel, CarouselItemProps } from "~/components2/atoms/carousel"
import { Flexed } from "~/components2/atoms/Flexed"

import * as A from "./atoms"

export const facts = [
	{
		logo: JAMA,
		name: t`JAMA Dermatology journal`,
		message: t`According to a JAMA Dermatology report a regimen of at-home facial exercises maintained for 20 weeks improves mid-face and lower face fullness`,
	},
	{
		logo: NCBI,
		name: t`National Center for Biotechnology Information`,
		message: t`Mental health affects almost every aspect of our lives. Research has highlighted the inextricable link between mental health and skin disease.`,
	},
	{
		logo: BMJ,
		name: t`British Medical Journal`,
		message: t`Research in several medical journals shows that people who eat healthy food notice positive skin changes`,
	},
]

export type FactSlideProps = CarouselItemProps<typeof facts[number]>
export function FactSlide({ item: { logo, name, message }, selectItem }: FactSlideProps) {
	return (
		<A.FactWrapper onClick={selectItem}>
			<A.Stars src={FiveStars} />
			<A.FactLogo alt="" src={logo} />
			<A.FactMessage>{message}</A.FactMessage>
			<Flexed.Spacer />
			<A.FactName>{name}</A.FactName>
		</A.FactWrapper>
	)
}

export function FactsCarousel() {
	return (
		<A.FactsCarouselContainer>
			<Carousel
				items={facts}
				keyGetter={(item) => item.name}
				renderItem={(props) => <FactSlide {...props} />}
				itemSize={{ height: 391 }}
				itemMargin={20}
				dots
			/>
		</A.FactsCarouselContainer>
	)
}
