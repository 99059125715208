import firebaseNameSpace from "firebase/app"
import { firebaseApp } from "~/services/firebase/firebase-app"
// import { firebaseApp } from "~/services/firebase/firebase-app"
import { RESPONSE_CODE } from "./response-codes"
import { setFirestoreDocument } from "./set-firestore-document"
//import { callFirebaseFunction } from "./call-firebase-function";

export interface SavePreferencesParams {
	email?: string
	name?: string
	goals?: string[]
	areas?: string[]
}

export interface SavePreferencesResponse {
	// This sub enum helps us to control possible error codes returns by the function
	// and provide front-end developers with consitent data.
	code:
		| RESPONSE_CODE.FORBIDDEN
		| RESPONSE_CODE.BAD_REQUEST
		| RESPONSE_CODE.TOO_MANY_REQUESTS
		| RESPONSE_CODE.UNEXPECTED_ERROR
		| RESPONSE_CODE.SUCCESS

	message: string

	data?: {
		waitTime?: number
	}
}

/**
 * Saves user preferences data to server for authenticated user.
 * User must be authenticated to get successfull result.
 * Process these responces and in other cases - throw an error.
 *  RESPONSE_CODE.TOO_MANY_REQUESTS
 *  RESPONSE_CODE.SUCCESS
 */
// export const savePreferences = async (params: SavePreferencesParams): Promise<SavePreferencesResponse> => {

// 	const response = await callFirebaseFunction<SavePreferencesParams,
// 		SavePreferencesResponse>("savePreferences", params)
// 	return response
// }

export const savePreferences = async (params: SavePreferencesParams): Promise<SavePreferencesResponse> => {
	const user = firebaseApp.auth().currentUser

	if (!user) {
		return {
			code: RESPONSE_CODE.FORBIDDEN,
			message: "Preferences are not saved. User must be authenticated",
		}
	}

	await setFirestoreDocument(
		"USER_DATA",
		`${user.uid}`,
		{
			prefs: params,
			upd: firebaseNameSpace.firestore.FieldValue.serverTimestamp(),
		},
		true
	)

	// console.log(`SAVE PREFERENSES: UID ${user.uid}: PREFS:${JSON.stringify(params)}`)
	// const response = await callFirebaseFunction<UserPreferences, StoreUserPreferencesResponse>(
	// 	"savePreferences", params
	// )
	return {
		code: RESPONSE_CODE.SUCCESS,
		message: "Preferences are successfully saved.",
	}
}
