import DesktopLine1 from "@assets/DesktopLine1.svg"
import DesktopLine2 from "@assets/DesktopLine2.svg"
import { ActionButton } from "@components/buttons"
import { Trans } from "@lingui/macro"
import { useEffect, useRef } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { QuoteList } from "../components/quote-list"
import { Screen1Title } from "../components/screen-1-title"
import { Screen2Title } from "../components/screen-2-title"
import { IQuoteListScreenProps } from "./quote-list-screen"
import {
	DesktopScreen,
	DesktopShadow,
	DesktopLineWrapper1,
	DesktopLineWrapper2,
	DesktopQuote,
	DesktopPurpleBackground,
	Mask,
	Background,
} from "./quote-list-screen.styles"

export const QuoteListDesktopScreen = ({
	background,
	label,
	title2,
	quote1,
	author1,
	quote2,
	author2,
	onNext,
}: IQuoteListScreenProps) => {
	const size = useWindowLayout()

	const desktopQuoteRef = useRef<HTMLDivElement>(null)
	const desktopButtonWrapperRef = useRef<HTMLDivElement>(null)
	const desktopButtonRef = useRef<HTMLButtonElement>(null)
	const desktopBackgroundRef = useRef<HTMLImageElement>(null)
	const desktopTitle1Ref = useRef<HTMLDivElement>(null)
	const desktopPurpleBackgroundRef = useRef<HTMLDivElement>(null)
	const desktopLine1Ref = useRef<HTMLDivElement>(null)
	const desktopLine2Ref = useRef<HTMLDivElement>(null)

	// DESKTOP ANIMATIONS
	useEffect(() => {
		setTimeout(() => {
			if (desktopTitle1Ref.current) {
				desktopTitle1Ref.current.style.opacity = "1"
			}
			if (desktopLine1Ref.current && desktopLine2Ref.current) {
				desktopLine1Ref.current.style.opacity = "1"
				desktopLine2Ref.current.style.opacity = "1"
			}
		}, 700)

		setTimeout(() => {
			if (desktopTitle1Ref.current) {
				desktopTitle1Ref.current.style.opacity = "0"
			}
		}, 3000)

		setTimeout(() => {
			if (desktopPurpleBackgroundRef.current) {
				desktopPurpleBackgroundRef.current.style.opacity = "1"
			}
		}, 3400)

		setTimeout(() => {
			if (desktopQuoteRef.current) {
				desktopQuoteRef.current.style.opacity = "1"
			}
		}, 4100)

		setTimeout(() => {
			if (desktopButtonWrapperRef.current) {
				desktopButtonWrapperRef.current.style.bottom = "0"
			}
			if (desktopButtonRef.current) {
				desktopButtonRef.current.style.opacity = "1"
			}
		}, 4400)
	}, [])

	return (
		<DesktopScreen>
			<Mask>
				<Background imgSrc={background} ref={desktopBackgroundRef} />
				<DesktopLineWrapper1 ref={desktopLine1Ref}>
					<img alt="" src={DesktopLine1} />
				</DesktopLineWrapper1>
				<DesktopLineWrapper2 ref={desktopLine2Ref}>
					<img alt="" src={DesktopLine2} />
				</DesktopLineWrapper2>
				<Screen1Title size={size} ref={desktopTitle1Ref} z={9990} title={label} />
				<DesktopPurpleBackground ref={desktopPurpleBackgroundRef}>
					<DesktopQuote ref={desktopQuoteRef}>
						<Screen2Title z={9990} title={title2} size={size} />
						<QuoteList size={size} z={9990} quote1={quote1} author1={author1} quote2={quote2} author2={author2} />
					</DesktopQuote>
				</DesktopPurpleBackground>
			</Mask>
			<DesktopShadow ref={desktopButtonWrapperRef}>
				<ActionButton ref={desktopButtonRef} size={size} onClick={onNext}>
					<Trans>Continue</Trans>
				</ActionButton>
			</DesktopShadow>
		</DesktopScreen>
	)
}
