import { LayoutType } from "@components/shared/layouts"
import React from "react"
import { TitleParagraph, Title2ContainerDiv } from "../quote-screen/quote-screen.styles"

export const Screen2Title = React.forwardRef(
	(
		{
			z,
			title,
			size,
		}: {
			z: number
			title: string
			size: keyof typeof LayoutType
		},
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		return (
			<Title2ContainerDiv z={z} ref={ref} size={size}>
				<TitleParagraph size={size}>{title}</TitleParagraph>
			</Title2ContainerDiv>
		)
	}
)
