import { background, color } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"
import React from "react"
import styled, { css } from "styled-components"

export const StyledInputWrapper = styled.div`
	display: flex;
	flex-direction: column;

	padding: 0;
`

export const InputContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	height: auto;

	padding: 0;
`

export const StyledInput = styled.input<
	React.HTMLProps<HTMLInputElement> & { layout: keyof typeof LayoutType; isStretch: boolean; isError: boolean }
>`
	font-family: ${typography.family.inter};
	font-style: normal;
	font-weight: ${typography.fontWeight.bold};

	${({ layout, isStretch }) =>
		layout === "Mobile"
			? css`
					width: ${isStretch ? "100%" : "335px"};
					height: 56px;

					font-size: ${typography.fontSize.bm1}px;
					line-height: ${typography.lineSize.bm1}px;

					padding: ${spacing.padding.verticalSmall}px ${spacing.padding.horizontalSmall}px;
			  `
			: css`
					width: ${isStretch ? "100%" : "480px"};
					height: 64px;

					font-size: ${typography.fontSize.b1}px;
					line-height: ${typography.lineSize.b1}px;

					padding: ${spacing.padding.verticalSmall}px ${spacing.padding.horizontalBig}px;
			  `};

	color: ${({ isError }) => isError && color.coral};

	box-sizing: border-box;
	border-radius: ${spacing.borderRadius.small}px;
	outline: none;
	border: none;

	background: ${background.white};

	:focus {
		caret-color: ${color.indigo};
		color: ${color.black};
	}

	::placeholder {
		color: ${color.rain};
		opacity: ${typography.transparency.half};
	}
`

export const InputError = styled.p<{
	layout: keyof typeof LayoutType
	isStretch: boolean
	errorPosition: "close" | "far"
}>`
	position: absolute;
	top: ${({ errorPosition }) => (errorPosition === "close" ? 80 : 148)}px;
	top: ${({ errorPosition, layout }) => errorPosition === "far" && layout === "Desktop" && "168px"};
	left: 0;
	font-family: ${typography.family.inter};
	font-style: normal;
	font-weight: ${typography.fontWeight.bold};
	font-size: ${typography.fontSize.b2}px;
	line-height: ${typography.lineSize.b2}px;
	/* identical to box height, or 156% */

	text-align: center;
	letter-spacing: ${typography.letterSpacing.b};

	color: ${color.coral};

	height: 28px;

	margin: 6px 0 0;
	padding: 0;
	box-sizing: border-box;

	${({ layout, isStretch }) =>
		layout === "Mobile"
			? css`
					width: ${isStretch ? "100%" : "335px"};
			  `
			: css`
					width: ${isStretch ? "100%" : "480px"};
			  `};
`
