import RatingImage from "@assets/rating.svg"
import { LayoutType } from "@components/shared/layouts"
import { Spacer } from "@components/spacer"
import React from "react"
import {
	RatingImg,
	QuoteListContainerDiv,
	AuthorParagraph2,
	QuoteParagraph2,
} from "../quote-screen/quote-screen.styles"

export const QuoteList = React.forwardRef(
	(
		{
			z,
			quote1,
			author1,
			quote2,
			author2,
			size,
		}: {
			z: number
			quote1: string
			author1: string
			quote2: string
			author2: string
			size: keyof typeof LayoutType
		},
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		return (
			<QuoteListContainerDiv z={z} ref={ref} size={size}>
				<RatingImg alt="" src={RatingImage} />
				<Spacer height="12px" />
				<QuoteParagraph2 size={size}>{quote1}</QuoteParagraph2>
				<AuthorParagraph2 size={size} textAlign={"right"}>
					{author1}
				</AuthorParagraph2>
				<Spacer height="18px" />
				<RatingImg alt="" src={RatingImage} />
				<Spacer height="12px" />
				<QuoteParagraph2 size={size}>{quote2}</QuoteParagraph2>
				<AuthorParagraph2 size={size} textAlign={"right"}>
					{author2}
				</AuthorParagraph2>
				<Spacer height={size === "Mobile" ? "75px" : "150px"} />
			</QuoteListContainerDiv>
		)
	}
)
