import { color } from "@components/shared/colors"
import { typography } from "@components/shared/typography"
import styled from "styled-components"
import { IIconButtonProps } from "./icon-button"

export const StyledButton = styled.button<IIconButtonProps>`
	display: flex;
	flex-direction: row;
	justify-content: ${({ isStretch }) => isStretch && "center"};
	align-items: center;

	width: ${({ isStretch }) => isStretch && "100%"};

	background: none;
	border: none;
	outline: none;

	cursor: pointer;

	> img {
		width: 24px;
		height: auto;
		margin: 0 11px;
	}

	> p {
		font-family: ${typography.family.inter};
		font-style: ${typography.fontStyle.default};
		font-weight: ${typography.fontWeight.bold};
		font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.bm1 : typography.fontSize.b1)}px;
		line-height: ${({ size }) => (size === "Mobile" ? typography.lineSize.bm1 : typography.lineSize.b1)}px;
		/* identical to box height, or 142% */

		letter-spacing: ${typography.letterSpacing.b}em;

		color: ${color.indigo};

		margin: 0;
	}
`
