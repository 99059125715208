import Lottie, { LottieRefCurrentProps } from "lottie-react"
import React, { useEffect, useRef } from "react"
import DataFile from "./circle.json"

export const LottieCircle = ({
	isStopped,
	z,
	onFinished,
}: {
	isStopped: boolean
	z?: number
	onFinished?: () => void
}) => {
	const lottieRef = useRef<LottieRefCurrentProps>(null)

	const lottieStyle: React.CSSProperties = {
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: z ?? 1,
		width: "48px",
		height: "48px",
		padding: "0px",
	}

	useEffect(() => {
		if (!isStopped && lottieRef.current) {
			lottieRef.current.play()
		}
	}, [isStopped, lottieRef])

	return (
		<>
			<Lottie
				lottieRef={lottieRef}
				animationData={DataFile}
				loop={false}
				autoplay={false}
				rendererSettings={{ preserveAspectRatio: "xMidYMin meet" }}
				onComplete={onFinished}
				style={lottieStyle}
			/>
		</>
	)
}
