import React, { ReactElement } from "react"
import ym, { YMInitializer } from "react-yandex-metrika"

export interface IYandexMetrikaService {
	getYMInitializer: () => ReactElement
	ymTrackPage: (pagePath: string) => void
}

/**
 * Yandex metrika tag initialization parameters
 * Read more: https://yandex.ru/support/metrica/code/counter-initialize.html
 */
export type YMInitializerOptions = {
	defer?: boolean
}

const defaultOptions: YMInitializerOptions = { defer: true }

export const yandexMetrikaService: IYandexMetrikaService = {
	getYMInitializer: function (): ReactElement {
		return <YMInitializer accounts={[Number(process.env.YANDEX_METRIKA_ACCOUNT)]} options={defaultOptions} />
	},
	ymTrackPage: function (pagePath: string): void {
		ym("hit", pagePath)
	},
}
