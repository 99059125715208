export const userAgent = {
	Android: navigator.userAgent.match(/Android/i),
	BlackBerry: navigator.userAgent.match(/BlackBerry/i),
	iOS: navigator.userAgent.match(/iPhone|iPad|iPod/i),
	OperaMini: navigator.userAgent.match(/Opera Mini/i),
	WindowsMobile: navigator.userAgent.match(/IEMobile|WPDesktop/i),
}

export const platform = {
	isMobile:
		userAgent.Android || userAgent.BlackBerry || userAgent.iOS || userAgent.OperaMini || userAgent.WindowsMobile,
}
