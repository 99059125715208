import { callFirebaseFunction } from "../call-firebase-function"

export interface StripeCancelSubscriptionParams {
	domain: string
	cancelAtPeriodEnd: boolean
	fbc?: string
	fbp?: string
}
export interface StripeCancelSubscriptionResponse {
	success: boolean
	error: any
}

export const stripeCancelSubscription = async (
	params: StripeCancelSubscriptionParams
): Promise<StripeCancelSubscriptionResponse> => {
	const response = await callFirebaseFunction<StripeCancelSubscriptionParams, StripeCancelSubscriptionResponse>(
		"stripeSubscriptionCancelV3",
		params
	)

	return response
}
