import React from "react"
import styled from "styled-components"

interface IBlock {
	margin?: string
	padding?: string
	height?: number
}

export interface IBlockProps extends IBlock {
	children: React.ReactNode
}

const StyledBlock = styled.div<IBlock>`
	width: 100%;
	height: ${({ height }) => `${height}` || "auto"};
	margin: ${({ margin }) => margin || 0};
	padding: ${({ padding }) => padding || 0};
`

export function Block(props: IBlockProps) {
	return <StyledBlock {...props}>{props.children}</StyledBlock>
}
