import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"

import styled from "styled-components"

export const Screen = styled.div`
	position: absolute;

	margin: 0;
	padding: 0;

	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	overflow: hidden;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-width: 0;
	box-sizing: border-box;

	transition: left 0.4s;

	background-color: ${background.app}; // #32237D;
`

export const MobileScreen = styled(Screen)``

export const DesktopScreen = styled(Screen)`
	justify-content: center;
	align-items: center;

	height: calc(100% - 64px);
	bottom: none;
	top: 0;

	min-height: 700px;
	overflow-y: scroll;
`

export const ScreenContent = styled.div`
	position: relative;

	margin: 0;
	padding: 0;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-width: 0;
	box-sizing: border-box;

	overflow: hidden;
`

export const BackgroundImage = styled.img<{ z?: number }>`
	position: absolute;
	left: 0;
	top: 1px;

	width: 100%;
	height: auto;
	z-index: ${({ z }) => z ?? 0};

	visibility: visible;
`

export const BackgroundImageDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	bottom: 0;

	width: 100%;
	height: calc(100% - 40px);
	z-index: ${({ z }) => z ?? 1};

	background: linear-gradient(180deg, rgba(6, 6, 23, 0) 40.48%, rgba(6, 6, 23, 0.8) 93.45%);

	visibility: hidden;
`

export const PurpleBackgroundDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	top: 1px;

	margin: 0;
	padding: 0;

	width: 100%;
	height: 100%;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: ${({ z }) => z ?? 1};

	opacity: 0;

	transition: opacity 0.7s;

	background-color: ${background.meteorite};
`

export const PurpleQuotesFadeDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	bottom: 100px;

	width: 100%;
	height: 75px; //calc(100% - 40px);
	z-index: ${({ z }) => z ?? 1};

	background: linear-gradient(180deg, rgba(50, 35, 125, 0) 40.48%, rgba(50, 35, 125, 0.9) 93.45%);

	visibility: hidden;
`

export const FadeDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	bottom: 0;

	width: 100%;
	height: 100px;
	z-index: ${({ z }) => z ?? 1};

	background: linear-gradient(
		180deg,
		rgba(50, 35, 125, 0) 0%,
		#32237d 62%
	); // linear-gradient(180deg, rgba(50, 35, 125, 0) 40.48%, rgba(50, 35, 125, 0.9) 93.45%);

	visibility: hidden;
`

export const MobileButtonWrapper = styled.div<{ z?: number }>`
	position: absolute;
	width: 100%;
	bottom: 44px;

	opacity: 0;

	transition: opacity 0.3s;

	z-index: ${({ z }) => z ?? 5};

	display: flex;
	justify-content: center;
	align-items: center;
`

export const QuoteContainerDiv = styled.div<{ z?: number; size?: keyof typeof LayoutType }>`
	position: absolute;
	left: 0;
	bottom: 100px; // animation to 150

	width: 100%;
	height: auto;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: ${({ z }) => z ?? 2};

	opacity: ${({ size }) => (size === "Desktop" ? 1 : 0)}; // animation to 1

	transition: bottom 0.8s, opacity 0.8s;
`

export const QuoteListContainerDiv = styled.div<{ z?: number; size?: keyof typeof LayoutType }>`
	position: absolute;
	left: 0;
	top: 232px; // animation to 182

	width: 100%;
	height: auto;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: ${({ z }) => z ?? 2};

	opacity: ${({ size }) => (size === "Desktop" ? 1 : 0)}; // animation to 1

	transition: top 0.8s, opacity 0.8s;

	box-sizing: border-box;
`

export const Title1ContainerDiv = styled(QuoteContainerDiv)`
	left: 0;
	right: unset;
	top: unset;
	bottom: 130px; // animation to 150px

	opacity: 0; // animation to 1

	transition: bottom 0.8s, opacity 0.8s;
`

export const Title2ContainerDiv = styled(QuoteContainerDiv)<{ size?: keyof typeof LayoutType }>`
	left: 0;
	right: unset;
	top: ${({ size }) => (size === "Mobile" ? "130px" : "110px")}; // animation to 80px
	bottom: unset;

	opacity: ${({ size }) => (size === "Desktop" ? 1 : 0)}; // animation to 1

	transition: top 0.8s, opacity 0.8s;
`

export const RatingImg = styled.img`
	padding: 0;
	margin: 0;

	width: 136px;
	height: 24px;

	border-width: 0;
`

export const QuoteParagraph = styled.p<{ size: keyof typeof LayoutType }>`
	margin: 0;
	padding: 0 ${spacing.padding.horizontalSmall}px;

	text-align: center;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? `${typography.fontSize.hm2}px` : "36px")};
	line-height: 110%;
	letter-spacing: ${typography.letterSpacing.h}em;
	text-align: center;

	color: ${({ size }) => (size === "Mobile" ? color.white : color.ghost)};
`
export const QuoteParagraph2 = styled.p<{ size: keyof typeof LayoutType }>`
	margin: 0;
	padding: 0 ${spacing.padding.horizontalHuge}px;

	text-align: center;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? `${typography.fontSize.bm1}px` : `${typography.fontSize.b1}px`)};
	line-height: ${({ size }) => (size === "Mobile" ? `${typography.lineSize.bm1}px` : `${typography.lineSize.b1}px`)};
	text-align: center;
	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${({ size }) => (size === "Mobile" ? color.white : color.ghost)};
`

export const TitleParagraph = styled(QuoteParagraph)<{ size?: keyof typeof LayoutType }>`
	width: ${({ size }) => size === LayoutType.Desktop && "400px"};
	margin-bottom: ${({ size }) => size === LayoutType.Desktop && "90px"};
`

export const AuthorParagraph = styled.p<{ textAlign?: "right" | "center" | "left"; size: keyof typeof LayoutType }>`
	margin: 0;
	padding: 0 ${spacing.padding.horizontalSmall}px;

	width: 100%;
	height: auto;
	box-sizing: border-box;

	text-align: ${({ textAlign }) => textAlign ?? "center"};

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? `${typography.fontSize.bm2}px` : `${typography.fontSize.b2}px`)};
	line-height: ${({ size }) => (size === "Mobile" ? `${typography.lineSize.bm4}px` : `${typography.lineSize.b1}px`)};

	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${({ size }) => (size === "Mobile" ? color.white : color.ghost)};

	opacity: ${({ size }) => (size === "Mobile" ? 0.5 : 0.6)};
`
export const AuthorParagraph2 = styled(AuthorParagraph)`
	padding: 0 ${spacing.padding.horizontalHuge}px;
`

export const Mask = styled.div`
	position: relative;
	width: 600px;
	height: 600px;

	border-radius: 50%;
	overflow: hidden;

	z-index: 500;

	display: flex;
	justify-content: center;
	align-items: center;

	margin: 20px 0;

	box-sizing: border-box;

	> img {
		position: absolute;
		top: 0;
		width: 100%;
		height: auto;
		z-index: 100;
		margin-top: -100px;
	}

	@media (min-width: 1800px) and (min-height: 840px) {
		width: 760px;
		height: 760px;
	}

	@media (min-width: 2800px) and (min-height: 1200px) {
		width: 1000px;
		height: 1000px;
	}
`

export const DesktopQuote = styled.span<{ size?: keyof typeof LayoutType }>`
	text-align: center;
	position: absolute;
	bottom: 4%;
	left: 5%;
	width: 90%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	z-index: 9000;

	opacity: 0;
	transition: opacity 0.7s;
`

export const DesktopShadow = styled.div`
	position: absolute;
	bottom: -200px;
	left: 0;
	width: 100%;
	height: 140px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f6f6f6 27.5%);

	z-index: 1000;

	transition: bottom 0.7s;

	> button {
		opacity: 0;
		transition: opacity 0.7s;
	}
`

const DesktopLineDefault = styled.div`
	position: absolute;
	height: auto;
	z-index: 100;

	opacity: 0;
	transition: opacity 0.7s;

	> img {
		width: 100%;
	}
`

export const DesktopLineWrapper1 = styled(DesktopLineDefault)`
	top: 30px;
	left: 60px;
	width: 560px;
`

export const DesktopLineWrapper2 = styled(DesktopLineDefault)`
	bottom: -50px;
	left: 140px;
	width: 500px;
`
