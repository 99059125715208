import { color } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { typography } from "@components/shared/typography"
import styled from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	width: 100%;
	height: ${({ size }) => (size === "Mobile" ? "100%" : "calc(100% - 64px)")};
	display: flex;
	flex-direction: column;
	align-items: center;
`

export const Label = styled.p<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? "100%" : "544px")};
	box-sizing: border-box;
	padding: 0 20px;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.fontSize.h2)}px;
	line-height: 110%;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.h}em;

	color: ${color.black};

	margin: ${({ size }) => (size === "Mobile" ? "24px 0" : "40px 0 32px")};
`
