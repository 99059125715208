import { useEffect } from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { ConfirmAndSignInPage } from "~/components2/organisms/Page/confirm-and-sign-in-page"
import { MobileProfilePage } from "~/components2/organisms/Page/mobile-profile-page"
import { NotFoundPage } from "~/components2/organisms/Page/not-found-page"
import { PersonalCoursePage } from "~/components2/organisms/Page/personal-course-page"
import { ProfilePage } from "~/components2/organisms/Page/profile-page"
import { SignInPage } from "~/components2/organisms/Page/sign-in-page"
import { SignInCallbackPage } from "~/components2/organisms/Page/signin-callback-page"
import { analytics } from "~/services/analytics"
import { LandingPage } from "~/views/landings/landing-page"
import { ConfirmEmailScreenFlow } from "~/views/success/screens/confirm-email-screen-flow"
import { DownloadAppScreenContainer } from "~/views/success/screens/download-screen/download-app-screen.container"
import { ConfirmEmailRoute } from "../Routers/confirm-email-route"
import { LoginFlowRoute } from "../Routers/login-flow-route"
import { NotVerifiedUserRoute } from "../Routers/not-verified-user-route"
import { SignInRoute } from "../Routers/sign-in-route"
import { VerifiedUserRoute } from "../Routers/verified-user-route"

export function App() {
	useEffect(() => {
		analytics.page()
	}, [])

	// 2. WHEN MAIN PROVIDERS ARE LOADED WE MAKES MAIN NAVIGATION AVAILABLE
	return (
		<Switch>
			<Route exact path="/" component={LandingPage} />
			<Route exact path="/personal-course" component={PersonalCoursePage} />
			<LoginFlowRoute exact path="/login" />
			<SignInRoute exact path="/signin" component={SignInPage} />
			<ConfirmEmailRoute exact path="/confirm-email" component={ConfirmAndSignInPage} />
			<VerifiedUserRoute exact path="/account" component={ProfilePage} />
			<NotVerifiedUserRoute exact path="/signin-callback" component={SignInCallbackPage} elsePath="/account" />
			<Route exact path="/confirm" component={ConfirmEmailScreenFlow} />
			<Route exact path="/profile" component={MobileProfilePage} />
			<Route exact path="/download" component={DownloadAppScreenContainer} />
			<Route exact path="/page-not-found" component={NotFoundPage} />
			<Redirect to="/page-not-found" />
		</Switch>
	)
}
