import { Trans } from "@lingui/macro"
import React, { useState } from "react"
import { SignInEmailScreen } from "../Screens/signin-email-screen"

export const SignInPage = () => {
	const [currentStep, setCurrentStep] = useState(0)

	const onNext = () => {
		if (currentStep !== 1) {
			setCurrentStep(currentStep + 1)
		}
	}

	// const onPrev = () => {
	// 	if (currentStep !== 0) {
	// 		setCurrentStep(currentStep - 1)
	// 	}
	// }

	switch (currentStep) {
		case 0:
			return <SignInEmailScreen onNext={onNext} />
		case 1:
			// TODO: Create a separate component with a back button
			return (
				<>
					<Trans>EMAIL WITH THE LINK TO ENTER HAS JUST BEEN SENT</Trans>
				</>
				//<SignInLinkSentScreen onPrev={onPrev} />
			)
	}

	return (
		<div>
			<Trans>Error on sign in page.</Trans>
		</div>
	)
}
