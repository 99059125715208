import { Cache } from "@anion155/rixio-utils"
import Airtable from "airtable"
import memoize from "lodash.memoize"

export class AirtableService {
	private base: Airtable.Base

	constructor() {
		const client = new Airtable({
			apiKey: process.env.REACT_APP_AIRTABLE_KEY,
		})
		this.base = client.base(process.env.REACT_APP_AIRTABLE_BASE!)
	}

	tableFetcher = memoize(<T extends Airtable.FieldSet & { id: string }>(tableName: string): AirtableFetcher<T, T> => {
		const table = this.base(tableName)
		return new AirtableFetcher(table as any, (d) => d.fields)
	})
	tableFetcherValidated<T extends Airtable.FieldSet, U extends { id: string }>(
		tableName: string,
		validateAndTransform: (data: Airtable.Record<T>) => U | undefined
	): AirtableFetcher<T, U> {
		const table = this.base(tableName)
		return new AirtableFetcher(table as any, validateAndTransform)
	}
}

export const airtableService = new AirtableService()

export class AirtableFetcher<T extends Airtable.FieldSet, U extends { id: string }> {
	readonly recordsRaw: Promise<Airtable.Records<T>>
	readonly records: Promise<Airtable.Records<U>>

	constructor(table: Airtable.Table<T>, validateAndTransform: (data: Airtable.Record<T>) => U | undefined) {
		this.recordsRaw = table.select({ pageSize: 20 }).all()
		this.records = this.recordsRaw.then((records) => {
			return records
				.map((r) => {
					const fields = validateAndTransform(r)
					return fields ? { ...r, fields } : undefined!
				})
				.filter((r) => !!r)
		})
	}

	recordsMap = new Cache(async () => {
		const records = await this.records
		return new Map(records.map((r) => [r.fields.id, r.fields]))
	})
	async findRecord(id: string) {
		const records = await this.recordsMap.get()
		return records.get(id)
	}
	findRecords(ids: string[]) {
		return Promise.all(ids.map((id) => this.findRecord(id)))
	}
}
