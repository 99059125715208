import { Trans } from "@lingui/macro"
import { useState } from "react"
import { useUpgradeUserAndSendOtp } from "~/subscriptions-web/hooks/use-upgrade-user-and-send-otp"
import { ConfirmAndSignInScreen } from "../Screens/confirm-and-signin-screen"

export const ConfirmAndSignInPage = () => {
	// const history = useHistory();
	// const { userSignedInWithEmailVerified } = useUserStatus();
	const [currentStep, setCurrentStep] = useState(0)
	const { upgradeUserAndSendOtp, isInProgress, errorMessage } = useUpgradeUserAndSendOtp(() => {
		if (currentStep !== 1) {
			setCurrentStep(currentStep + 1)
		}
	})

	// if(userSignedInWithEmailVerified) {
	//     history.push('/profile');
	// }

	const requestOTPLink = async (email: string) => {
		await upgradeUserAndSendOtp(email)
	}

	// const onPrev = () => {
	// 	if (currentStep !== 0) {
	// 		setCurrentStep(currentStep - 1)
	// 	}
	// }

	switch (currentStep) {
		case 0:
			return (
				<ConfirmAndSignInScreen
					errorMessage={errorMessage}
					isControlsDisabled={isInProgress}
					onConfirmAndSendOtp={requestOTPLink}
				/>
			)
		case 1:
			// TODO: Create a separate component with a back button
			//<SignInLinkSentScreen onPrev={onPrev} />
			return (
				<>
					<Trans>EMAIL WITH THE LINK TO ENTER HAS JUST BEEN SENT {errorMessage}</Trans>
				</>
			)
	}

	return (
		<div>
			<Trans>Error on sign in page.</Trans>
		</div>
	)
}
