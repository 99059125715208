import { LayoutType } from "@components/shared/layouts"
import React, { useEffect, useRef } from "react"
import { TitleParagraph, TitleContainerDiv } from "../final-loader-screen.styles"

export const ScreenTitle = React.forwardRef(
	(
		{
			isStarted,
			z,
			title,
			size,
			onTransitionEnd,
		}: {
			isStarted: boolean
			z: number
			title: string
			size?: keyof typeof LayoutType
			onTransitionEnd: (event: React.TransitionEvent<HTMLDivElement>) => void
		},
		ref: React.ForwardedRef<HTMLDivElement>
	) => {
		const refDiv = useRef<HTMLDivElement>(null)

		useEffect(() => {
			if (isStarted && refDiv.current) {
				refDiv.current.style.opacity = "1" // from 0
				refDiv.current.style.top = "56px" // from 106px
			} else if (!isStarted && refDiv.current) {
				refDiv.current.style.opacity = "0"
				refDiv.current.style.top = "106px"
			}
		}, [isStarted, refDiv])

		return (
			<TitleContainerDiv z={z} ref={refDiv} onTransitionEnd={onTransitionEnd}>
				<TitleParagraph size={size}>{title}</TitleParagraph>
			</TitleContainerDiv>
		)
	}
)
