import LandingArrow from "@assets/LandingArrow.svg"
import OrangeLogo from "@assets/OrangeLogo.svg"
import { Trans } from "@lingui/macro"

import { Flexed } from "~/components2/atoms/Flexed"
import { useWindowLayout } from "~/hooks/use-window-layout"

import {
	Wrapper,
	Background,
	LandingWrapper,
	Logo,
	LandingBody,
	TextWithImage,
	Copyright,
	LongTextButton,
	BlueButton,
} from "./bravo-landing.styles"

export interface IBravoLandingProps {
	onClickSkinCourse: () => void
	onClickYoungerCourse: () => void
}

export const BravoLanding = (props: IBravoLandingProps) => {
	const size = useWindowLayout()

	return (
		<Wrapper size={size}>
			<Background />
			<LandingWrapper size={size}>
				<Logo>
					<img alt="" src={OrangeLogo} />
				</Logo>
				<LandingBody size={size}>
					<p>
						<Trans>Take this quiz to get visible results in</Trans>
						<br />
						<Trans>2 weeks</Trans>
					</p>
					<TextWithImage style={{ marginBottom: 0 }}>
						<Trans>I want to</Trans>:
						<img alt="" src={LandingArrow} />
					</TextWithImage>
					<Flexed direction="row" justify="center">
						<LongTextButton color="pink" title="Healthy skin for good" onClick={props.onClickSkinCourse}>
							<Trans>Healthy skin</Trans>
							<br />
							<Trans>for good</Trans>
						</LongTextButton>
						<BlueButton color="pink" title="Look younger for good" onClick={props.onClickYoungerCourse}>
							<Trans>Look younger</Trans>
							<br />
							<Trans>for good</Trans>
						</BlueButton>
					</Flexed>
				</LandingBody>
			</LandingWrapper>
			<Copyright>
				{/* Copyright ® 2019-2022 Glowbe.{size === "Mobile" && <br />}
				All Rights Reserved */}
				<Trans>Copyright ® 2019-2022 Glowbe.</Trans>
				{size === "Mobile" && <br />}
				<Trans>All Rights Reserved</Trans>
			</Copyright>
		</Wrapper>
	)
}
