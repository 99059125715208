// RESPONSE CODE SERVER PRINCIPALS. PLEASE, TRY TO FOLLOW THE RULES.
//      1xx informational response – the request was received, continuing process
//      2xx successful – the request was successfully received, understood, and accepted
//      3xx redirection – further action needs to be taken in order to complete the request
//      4xx client error – the request contains bad syntax or cannot be fulfilled
//      5xx server error – the server failed to fulfil an apparently valid request

export enum RESPONSE_CODE {
	// SERVER INTERNAL ERRORS CODES
	UNEXPECTED_ERROR = 500,

	// SUCCESSFUL CODES
	SUCCESS = 200,
	OTP_SENT = 201,

	// AUTHENTICATION & AUTHORIZATION CODES
	UNAUTHORIZED = 401,
	FORBIDDEN = 402,
	USER_EXISTS = 403,
	USER_NOT_EXIST = 404,

	// PARAMETER FAIL VALIDATION CODES
	BAD_REQUEST = 411,

	// RATE LIMITER CODES
	TOO_MANY_REQUESTS = 421,

	// REQUESTED RESOURCE NOT FOUND
	NOT_FOUND = 431,
	// OTP VALIDATION CODES
	INCORRECT = 441,
	NOT_VALID = 442,
}
