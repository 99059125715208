import React from "react"
import { Route, Redirect, RouteProps } from "react-router-dom"
import { useUserStatus } from "../../../subscriptions-web/hooks/use-user-status"

export const NotVerifiedUserRoute = ({
	component: RouteComponent,
	elsePath,
	...restProps
}: { component: React.ComponentType<any>; elsePath: string } & RouteProps) => {
	const { userSignedInWithEmailVerified } = useUserStatus()

	return (
		<Route
			{...restProps}
			render={(routeProps) =>
				!userSignedInWithEmailVerified ? <RouteComponent {...routeProps} /> : <Redirect to={elsePath} />
			}
		/>
	)
}
