export enum API_RESPONSE_STATUS {
	SUCCESS = 200,

	UNEXPECTED_ERROR = 500,

	NOT_FOUND = 431,

	BAD_REQUEST = 411,

	SPECIFIC_ERROR = 403,

	FORBIDDEN = 402,

	UNAUTHORIZED = 401,
}

export enum ERROR_CODE {
	UNEXPECTED_ERROR = "UNEXPECTED_ERROR",

	PARAM_INVALID = "PARAM_INVALID",
	PARAM_REQUIRED = "PARAM_REQUIRED",

	FORBIDDEN = "FORBIDDEN",
	UNAUTHORIZED = "UNAUTHORIZED",
	//USER_EXISTS = 'USER_EXISTS',
	//USER_NOT_EXIST = 'USER_NOT_EXIST',
	//TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
	//NOT_FOUND = 'NOT_FOUND',
	//OTP_INCORRECT = 'OTP_INCORRECT',
	//OTP_NOT_VALID = 'OTP_NOT_VALID',
}

export interface ErrorData {
	error: ERROR_CODE
	message: string
}

export interface ApiResponse<T> {
	status: API_RESPONSE_STATUS
	data: T | ErrorData
}
