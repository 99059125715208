import { useCallback, useEffect, useReducer } from "react"
import * as server from "../server-api"
import { RESPONSE_CODE } from "../server-api/response-codes"
import { ProgressStatus } from "./progress-status"

export interface State {
	status: ProgressStatus
	response?: server.SignInAnonymouslyResponse
}

const initialState: State = {
	status: ProgressStatus.START,
}

enum ACTION {
	STARTED,
	FINISHED,
}

const reducer = (
	prevState: State,
	action:
		| {
				type: ACTION.STARTED
		  }
		| {
				type: ACTION.FINISHED
				payload: server.SignInAnonymouslyResponse
		  }
) => {
	switch (action.type) {
		case ACTION.STARTED:
			return {
				...prevState,
				status: ProgressStatus.WORKING,
			}
		case ACTION.FINISHED:
			return {
				...prevState,
				status: ProgressStatus.DONE,
				response: action.payload,
			}
	}
}

/**
 * Anonymously signs in as an anonymous user.
 * If there is already an anonymous user signed in, that user will be returned;
 * otherwise, a new anonymous user identity will be created and returned.
 * You must enable Anonymous auth in the Firebase Console.
 * Hook's purpose is to introduce state variable to make possible to render
 * UI depending on server method call progress.
 * If you do need state variablet o rerender UI or need custom realization -  use server API (../server-api).
 */
export const useSignInAnonymously = (
	onSuccess?: (response?: server.SignInAnonymouslyResponse) => void,
	onError?: (response?: server.SignInAnonymouslyResponse) => void
) => {
	const [signInAnonymouslyState, dispatch] = useReducer(reducer, initialState)

	const signInAnonymously = useCallback(async () => {
		try {
			dispatch({ type: ACTION.STARTED })

			const response = await server.signInAnonymously()

			dispatch({ type: ACTION.FINISHED, payload: response })
		} catch (err) {
			dispatch({
				type: ACTION.FINISHED,
				payload: {
					code: RESPONSE_CODE.UNEXPECTED_ERROR,
					message: `Unexpected error while signInWithOTP ${err.message ?? ""}`,
				},
			})
		}
	}, [])

	useEffect(() => {
		if (signInAnonymouslyState.response) {
			if (signInAnonymouslyState.response.code === RESPONSE_CODE.SUCCESS) {
				if (onSuccess) {
					onSuccess(signInAnonymouslyState.response)
				}
			} else if (signInAnonymouslyState.response.code === RESPONSE_CODE.UNEXPECTED_ERROR) {
				if (onError) {
					onError(signInAnonymouslyState.response)
				}
			}
		}
	}, [signInAnonymouslyState.response, onSuccess, onError])

	return {
		signInAnonymously,
		signInAnonymouslyState,
	}
}
