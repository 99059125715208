import CircleAnimation from "@assets/lotties/LandingDeltaCircle.json"
import LinesAnimation from "@assets/lotties/LandingDeltaLines.json"
import TextAnimation from "@assets/lotties/LandingDeltaText.json"
import TextAnimationMan from "@assets/lotties/LandingDeltaTextMan.json"
import { Trans } from "@lingui/macro"
import { useMemo, useState } from "react"
import Lottie, { Options as LottieOptions } from "react-lottie"
import { ActionButton } from "~/components/buttons"
import { Appearance } from "~/components/buttons/action-button/action-button"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"

import { Wrapper, Background, LandingWrapper, ActionButtonWrapper } from "./delta-landing.styles"

const linesOptions: LottieOptions = {
	loop: false,
	autoplay: true,
	animationData: LinesAnimation,
	rendererSettings: {
		preserveAspectRatio: "none meet", // More info about the setting https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
	},
}
const circleOptions: LottieOptions = {
	loop: false,
	autoplay: false,
	animationData: CircleAnimation,
	rendererSettings: {
		preserveAspectRatio: "none slice",
	},
}

const lottieStyle: React.CSSProperties = {
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
}

export interface IDeltaLandingProps {
	onNext: () => void
	onboardingType: string | null
}

export const DeltaLanding = (props: IDeltaLandingProps) => {
	const [isStoppedCircleAnimation, setIsStoppedCircleAnimation] = useState<boolean>(true)
	const layoutType = useWindowLayout()

	const textOptions = useMemo(
		() => ({
			loop: false,
			autoplay: true,
			animationData: !props.onboardingType ? TextAnimation : TextAnimationMan,
			rendererSettings: {
				preserveAspectRatio: "xMidyMid meet",
				clearCanvas: true,
				hideOnTransparent: true,
			},
		}),
		[props.onboardingType]
	)

	return (
		<Wrapper>
			<Background />
			<LandingWrapper style={{ position: "relative" }}>
				<Lottie
					options={linesOptions}
					isPaused={false}
					isStopped={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
					eventListeners={[
						{
							eventName: "complete",
							callback: () => {
								setIsStoppedCircleAnimation(false)
							},
						},
					]}
					width="100%"
					height="100%"
				/>
				<Lottie
					options={textOptions}
					isStopped={false}
					isPaused={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
				/>
				<Lottie
					options={circleOptions}
					isStopped={isStoppedCircleAnimation}
					isPaused={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
					eventListeners={[
						{
							eventName: "complete",
							callback: () => props.onNext(),
						},
					]}
				/>
				<ActionButtonWrapper>
					<ActionButton
						size={layoutType}
						appearance={Appearance.Blue}
						onClick={() => {
							amplitudeService.logEvent("[All] Skip Loader")
							props.onNext()
						}}
					>
						<Trans>Skip</Trans>
					</ActionButton>
				</ActionButtonWrapper>
			</LandingWrapper>
		</Wrapper>
	)
}
