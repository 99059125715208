import React, { ReactElement, useCallback, useContext } from "react"
import { IYandexMetrikaService } from "../services/service/yandex-metrika-service"

const YandexMetrikaContext = React.createContext<Pick<IYandexMetrikaService, "ymTrackPage">>({
	ymTrackPage: () => undefined,
})

type YandexMetrikaProviderProps = {
	children: ReactElement
	service: IYandexMetrikaService
}

export function YandexMetrikaProvider({ children, service }: YandexMetrikaProviderProps): ReactElement {
	const ymTrackPage = useCallback(
		(trackingPage: string) => {
			service.ymTrackPage(trackingPage)
		},
		[service]
	)

	return (
		<>
			{service.getYMInitializer()}
			<YandexMetrikaContext.Provider value={{ ymTrackPage }}>{children}</YandexMetrikaContext.Provider>
		</>
	)
}

export const useYandexMetrika = () => {
	const yandexMetrikaProvider = useContext(YandexMetrikaContext)

	if (yandexMetrikaProvider === undefined) {
		throw new Error("useYandexMetrika must be used within YandexMetrikaProvider")
	}

	return yandexMetrikaProvider
}
