import { useEffect, useReducer } from "react"
// import { firebaseApp } from "~/services/firebase/firebase-app"
import { firebaseApp } from "~/services/firebase/firebase-app"
import * as server from "../server-api"
import { RESPONSE_CODE } from "../server-api/response-codes"
import * as localStorage from "../utils/local-storage"
import { EMAIL_FOR_SIGN_IN } from "./use-send-signin-otp"
import { ProgressStatus } from "."

export interface State {
	status: ProgressStatus
	response?: server.GetSignInTokenResponse
}

const initialState: State = {
	status: ProgressStatus.START,
}

enum ACTION {
	STARTED,
	FINISHED,
}

const reducer = (
	prevState: State,
	action:
		| {
				type: ACTION.STARTED
		  }
		| {
				type: ACTION.FINISHED
				payload: server.GetSignInTokenResponse
		  }
) => {
	switch (action.type) {
		case ACTION.STARTED:
			return {
				...prevState,
				status: ProgressStatus.WORKING,
			}
		case ACTION.FINISHED:
			return {
				...prevState,
				status: ProgressStatus.DONE,
				response: action.payload,
			}
	}
}

/**
 *  Requests the server for a token with one time password to sign in existing user.
 *  No one should be signed in at the moment of signInWithOTP method.
 *  The following 4 error codes should be proccessed, in other cases throw an error.
 *  Do not forget to watch after data.waitTime in case 2 and 3.
 *  1. RESPONSE_CODE.NOT_VALID
 *  2. RESPONSE_CODE.TOO_MANY_REQUESTS
 *  3. RESPONSE_CODE.INCORRECT
 *  4. RESPONSE_CODE.SUCCESS
 */
export const useSignInWithOTP = (
	onSuccess?: (response?: server.GetSignInTokenResponse) => void,
	onError?: (response?: server.GetSignInTokenResponse) => void
) => {
	const [signInWithOTPState, dispatch] = useReducer(reducer, initialState)

	const signInWithOTP = async (code: string) => {
		try {
			dispatch({ type: ACTION.STARTED })

			let email = (await localStorage.getFromLocalStorage(EMAIL_FOR_SIGN_IN)) || ""
			if (!email) {
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again. For example:
				//TODO: make a call back function for it and email varification (maybe separate screen)
				email = window.prompt("Please, provide your email for confirmation.") || ""
			}
			const response = await server.getSignInToken({ email, code })
			//alert(response.message);
			if (response.data && response.data.token) {
				await firebaseApp.auth().signInWithCustomToken(response.data.token)
				await localStorage.removeFromLocalStorage(EMAIL_FOR_SIGN_IN)
			}

			dispatch({ type: ACTION.FINISHED, payload: response })
		} catch (err) {
			dispatch({
				type: ACTION.FINISHED,
				payload: {
					code: RESPONSE_CODE.UNEXPECTED_ERROR,
					message: `Unexpected error while signInWithOTP ${(err as any).message ?? ""}`,
				},
			})
		}
	}

	useEffect(() => {
		if (signInWithOTPState.response) {
			if (signInWithOTPState.response.code === RESPONSE_CODE.SUCCESS) {
				if (onSuccess) {
					onSuccess(signInWithOTPState.response)
				}
			} else {
				if (onError) {
					onError(signInWithOTPState.response)
				}
			}
		}
	}, [signInWithOTPState.response, onSuccess, onError])

	return {
		signInWithOTP,
		signInWithOTPState,
	}
}
