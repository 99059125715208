import { Identify } from "amplitude-js"

const monthNames = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
]

export function cohortIdentify(identify: Identify) {
	const date = new Date()
	const month = date.getMonth() //months from 1-12
	const day = date.getDate()
	const year = date.getFullYear()

	const janFirst = new Date(date.getFullYear(), 0, 1)
	const week = Math.ceil(((date.getTime() - janFirst.getTime()) / 86400000 + janFirst.getDay() + 1) / 7)

	const cohortDate = year + "/" + month + "/" + day

	identify
		.set("Cohort Date", cohortDate)
		.set("Cohort Day", day)
		.set("Cohort Month", monthNames[month])
		.set("Cohort Week", week)
		.set("Cohort Year", year)
}
