import * as server from "~/subscriptions-web/server-api"
import { paypalSubscriptionCancel } from "../paypal/paypal-subscription-cancel"
import { Subscription, SubscriptionProvider } from "./subscription"

export const stripeSubscriptionCancel = async (
	subscription: Subscription
): Promise<{
	success: boolean
	error?: any
}> => {
	if (subscription.provider === SubscriptionProvider.Paypal) {
		try {
			const result = await paypalSubscriptionCancel({
				subscriptionId: subscription.id,
				domain: process.env.REACT_APP_DOMAIN ?? "course",
			})
			return {
				success: result,
			}
		} catch (err) {
			return {
				success: false,
				error: err,
			}
		}
	} else if (subscription.provider === SubscriptionProvider.Stripe) {
		const { success, error } = await server.stripeCancelSubscription({
			cancelAtPeriodEnd: true,
			domain: process.env.REACT_APP_DOMAIN ?? "course",
		})
		return {
			success,
			error,
		}
	}
	return {
		success: false,
		error: "",
	}
}
