import { AnalyticsInstance, AnalyticsPlugin } from "analytics"

export type AnalyticsEventNames<M extends Record<string, any>> = Extract<keyof M, string> | (string & {})

type NonVoidEvents<M extends Record<string, any>> = {
	[E in keyof M]: M[E] extends void ? E : never
}[keyof M]
export type TypedAnalytics<M extends Record<string, any>> = Omit<AnalyticsInstance, "track"> & {
	track<E extends keyof M>(eventName: E, payload: M[E]): void
	track<E extends NonVoidEvents<M>>(eventName: E): void
}

export type EventsMapper<
	Generic extends Record<string, any>,
	Specific extends Record<string, any>,
	EventsMap extends Record<Extract<keyof Generic, string>, Extract<keyof Specific, string>>
> =
	| ({
			[E in Extract<keyof Generic, string>]?:
				| EventsMap[E]
				| (string & {})
				| { (properties: Generic[E]): [EventsMap[E] | (string & {}), Specific[EventsMap[E]]?] }
	  } & {
			rest?: (
				event: AnalyticsEventNames<Generic>,
				properties: any
			) => [EventsMap[keyof EventsMap] | (string & {}), Specific[string]?]
	  })
	| {
			(event: AnalyticsEventNames<Generic>, properties: any): [
				EventsMap[keyof EventsMap] | (string & {}),
				Specific[string]?
			]
	  }
export type EventSimpleMapper<Events extends string, Payload extends object> = (
	properties: any
) => [Events | (string & {}), Payload?]
export function trackMapped<Generic extends Record<string, any>, Specific extends Record<string, any>>() {
	return <
		EventsMap extends Record<Extract<keyof Generic, string>, Extract<keyof Specific, string>>,
		Config extends object
	>(
		eventsMap: EventsMapper<Generic, Specific, EventsMap>,
		track: (config: Config, event: EventsMap[keyof EventsMap] | (string & {}), payload?: Specific[string]) => void
	) => {
		const cache = (
			event: AnalyticsEventNames<Generic>
		): EventSimpleMapper<EventsMap[keyof EventsMap], Specific[string]> => {
			if (typeof eventsMap === "function") {
				return eventsMap.bind(null, event) as any
			} else if (event === "rest") {
				return eventsMap.rest?.bind(null, "rest") ?? ((props: any) => ["rest", props])
			} else if (event in eventsMap) {
				const value = eventsMap[event as any]!
				if (typeof value === "string") {
					return (props: any) => [value, props]
				} else {
					return value as any
				}
			} else {
				return eventsMap.rest?.bind(null, event) ?? ((props: any) => [event, props])
			}
		}
		return ({
			config,
			payload: { event, properties },
		}: {
			config: Config
			payload: { event: AnalyticsEventNames<Generic>; properties: any }
		}) => {
			track(config, ...cache(event)(properties))
		}
	}
}
export function pluginTrackWrap<Generic extends Record<string, any>, Specific extends Record<string, any>>() {
	return <
		EventsMap extends Record<Extract<keyof Generic, string>, Extract<keyof Specific, string>>,
		Config extends object
	>(
		plugin: AnalyticsPlugin,
		eventsMap: EventsMapper<Generic, Specific, EventsMap>
	) => {
		return {
			...plugin,
			track: trackMapped<Generic, Specific>()<EventsMap, Config>(eventsMap, (config, event, properties) => {
				;(plugin as any).track?.({ config, payload: { event, properties } })
			}),
		}
	}
}
