import firebase from "firebase/app"
import { PaypalSubscriptionStatus } from "../domain/paypal-subscription-status"

export interface PaypalSubscriptionDoc {
	/* eslint-disable camelcase */
	userId: string
	analytics?: {
		email?: string
		fbc?: string
		fbp?: string
		callerIp?: string
		userAgent?: string
	}
	subscriptionId: string
	subscriptionStatus: PaypalSubscriptionStatus
	startTime: firebase.firestore.Timestamp
	updated: firebase.firestore.Timestamp
	planId: string
	last_payment_time?: firebase.firestore.Timestamp
	next_billing_time?: firebase.firestore.Timestamp
	/* eslint-enable camelcase */
}

export enum PaypalSubscriptionDocField {
	/* eslint-disable camelcase */
	userId = "userId",
	analytics = "analytics",
	subscriptionId = "subscriptionId",
	subscriptionStatus = "subscriptionStatus",
	startTime = "startTime",
	updated = "updated",
	planId = "planId",
	last_payment_time = "last_payment_time",
	next_billing_time = "next_billing_time",
	/* eslint-enable camelcase */
}

export const PaypalSubscriptionDocConverter: firebase.firestore.FirestoreDataConverter<PaypalSubscriptionDoc> = {
	toFirestore: (modelObj: Partial<PaypalSubscriptionDoc>): firebase.firestore.DocumentData => {
		return modelObj
	},
	fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot): PaypalSubscriptionDoc => {
		return snapshot.data() as PaypalSubscriptionDoc
	},
}

export interface PaypalSubscriptionDocContainer {
	id: string
	doc: PaypalSubscriptionDoc
}
