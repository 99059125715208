import styled from "styled-components"

export interface ISpacerProps {
	height: string
}

const StyledSpacer = styled.div<ISpacerProps>`
	padding: 0;
	margin: 0;

	width: 100%;
	height: ${({ height }) => height ?? "0px"};

	border-width: 0px;
	box-sizing: border-box;
`

export const Spacer = ({ height }: ISpacerProps) => {
	return <StyledSpacer height={height} />
}
