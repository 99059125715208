import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"

import styled from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	width: 100%;
	height: ${({ size }) => (size === "Mobile" ? "100%" : "calc(100% - 64px)")};
	top: 0;
	left: 0;
`

export const Screen = styled.div`
	position: absolute;

	margin: 0;
	padding: 0;

	bottom: 0;
	left: 0;

	width: 100%;
	height: 100%;
	overflow-y: scroll;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;

	transition: left 0.4s;

	background-color: ${background.app};
	/* #32237D; */
`

export const ScreenContent = styled.div`
	position: relative;

	margin: 0;
	padding: 0;

	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	box-sizing: border-box;
`

export const ProgressListDiv = styled.div<{ z?: number }>`
	position: absolute;

	left: 0;
	top: 242px;
	/* animation to 192px */

	padding: 0 ${spacing.padding.horizontalSmall}px;
	margin: 0;

	width: 100%;
	height: auto;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: ${({ z }) => z ?? 2};

	opacity: 0;
	/* animation to 1 */

	transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
`
export const ProgressListContentDiv = styled.div`
	position: relative;

	padding: 0;
	margin: auto;

	width: auto;
	height: auto;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const ProgressItemDiv = styled.div<{ isLast: boolean }>`
	position: relative;

	width: 100%;
	height: ${({ isLast }) => (isLast ? "48px" : "61px")};
	box-sizing: border-box;

	margin-bottom: ${({ isLast }) => (isLast ? "0px" : "2px")};
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
`

export const ProgressItemTextSpan = styled.span<{ size: keyof typeof LayoutType }>`
	margin: 10px 0 0 64px;
	padding: 0 0;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? `${typography.fontSize.bm1}px` : `${typography.fontSize.b1}px`)};
	line-height: ${({ size }) => (size === "Mobile" ? `${typography.lineSize.bm1}px` : `${typography.lineSize.b1}px`)};
	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.rain};
	/* #5856d6 */

	transition: color 2s;
`

export const TitleContainerDiv = styled.div<{ z?: number }>`
	position: absolute;
	left: 0;
	top: 106px;
	/* animation to 56px */

	width: 100%;
	height: auto;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: ${({ z }) => z ?? 2};

	opacity: 0;
	/* animation to 1 */

	transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
`

export const TitleParagraph = styled.p<{ size?: keyof typeof LayoutType }>`
	margin: 0;
	padding: 0 ${spacing.padding.horizontalSmall}px;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${({ size }) => (size === "Mobile" ? typography.fontWeight.bold : typography.fontWeight.bolder)};
	font-size: ${({ size }) => (size === "Mobile" ? `${typography.fontSize.hm2}px` : `${typography.fontSize.h2}px`)};
	line-height: 110%;
	text-align: center;
	letter-spacing: ${({ size }) =>
		size === "Mobile" ? `${typography.letterSpacing.b}em` : `${typography.letterSpacing.h}em`};

	width: ${({ size }) => size === "Desktop" && "544px"};
	margin-top: ${({ size }) => size === "Desktop" && "-20px"};

	color: ${color.black};
`
