import { useCallback } from "react"
import { useHistory } from "react-router"
import { amplitudeService } from "~/services/amplitude"
import { BravoLanding } from "./bravo-landing"

export const BravoLandingContainer = () => {
	const history = useHistory()

	const onClickCourse = useCallback(
		(buttonText: string) => {
			amplitudeService.logEvent("[All] Button | Get course", { name: buttonText })

			history.push("/personal-course")
		},
		[history]
	)

	const onClickSkinCourse = useCallback(() => onClickCourse("Healthy skin for good"), [onClickCourse])
	const onClickYoungerCourse = useCallback(() => onClickCourse("Look younger for good"), [onClickCourse])

	return <BravoLanding onClickSkinCourse={onClickSkinCourse} onClickYoungerCourse={onClickYoungerCourse} />
}
