import OrangeStars from "@assets/OrangeStars.svg"
import { LayoutType } from "@components/shared/layouts"
import { defineMessage } from "@lingui/macro"
import { forwardRef, PropsWithChildren } from "react"
import { i18nCustom } from "~/services/service/i18n-service"
import * as R from "./review.styles"

export interface IReviewProps {
	isStretch?: boolean
	size?: keyof typeof LayoutType
	title: string
	review: string
	created: string
	author: string
}

export const Review = forwardRef<unknown, PropsWithChildren<IReviewProps & JSX.IntrinsicElements["div"]>>(
	({ size = "Mobile", isStretch = false, ...rest }, ref: any) => {
		defineMessage({ message: rest.review })

		return (
			<R.Wrapper ref={ref} size={size} isStretch={isStretch} {...rest}>
				<R.TitleContainer>
					<R.Title>{rest.title}</R.Title>
					<R.Created>{rest.created}</R.Created>
				</R.TitleContainer>
				<R.TitleContainer>
					<img alt="" src={OrangeStars} />
					<R.Author>{rest.author}</R.Author>
				</R.TitleContainer>
				<R.Spacer height={"6px"} />
				<R.Text>{i18nCustom._(rest.review)}</R.Text>
			</R.Wrapper>
		)
	}
)
