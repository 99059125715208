/**
 * Documentation https://developer.paypal.com/docs/api/subscriptions/v1/#subscriptions-get-response
 */

export enum PaypalSubscriptionStatus {
	APPROVAL_PENDING = "APPROVAL_PENDING", // The subscription is created but not yet approved by the buyer.
	APPROVED = "APPROVED", // The buyer has approved the subscription.
	ACTIVE = "ACTIVE", // The subscription is active.
	SUSPENDED = "SUSPENDED", // The subscription is suspended.
	CANCELLED = "CANCELLED", // The subscription is cancelled.
	EXPIRED = "EXPIRED", // The subscription is expired.
	CLOSED = "CLOSED", // My custom status, means the subscription is closed by task runner after cancel.
}

export const isPaypalSubscriptionCurrent = (status?: PaypalSubscriptionStatus) => {
	return status === PaypalSubscriptionStatus.ACTIVE || status === PaypalSubscriptionStatus.CANCELLED
}
