export const typography = {
	family: {
		inter: '"Inter", "Helvetica Neue", Helvetica, Arial, sans-serif',
	},
	fontStyle: {
		default: "normal",
	},
	fontWeight: {
		regular: "400",
		bold: "500",
		bolder: "600",
		extrabold: "700",
		black: "800",
	},
	fontSize: {
		// desktop sizes:
		h1: 64,
		h2: 40,
		b1: 24,
		b2: 18,

		// mobile sizes:
		hm1: 40,
		hm2: 28,
		hm3: 32,
		bm1: 20,
		bm2: 18,
		bm3: 16,
	},
	lineSize: {
		// desktop values:
		h1: 70,
		h2: 44,
		h3: 40,
		h4: 38,
		b1: 34,
		b2: 28,
		b3: 26,

		// mobile values:
		hm1: 44,
		hm2: 30,
		hm3: 34,
		bm1: 28,
		bm2: 26,
		bm3: 24,
		bm4: 22,
	},
	letterSpacing: {
		t: -0.06,
		h: -0.04,
		b: -0.03,
	},
	transparency: {
		opaque: 1,
		half: 0.6,
		almost: 0.4,
	},
} as const
