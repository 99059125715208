import { Assign } from "./types"

type Group<I extends string> = ReadonlyArray<I | readonly [I, string] | Partial<Record<I, string>>>
type GroupsScheme<T extends object> = Readonly<Record<string, Group<Extract<keyof T, string>>>>
type ConfigBase<T extends object> = {
	allowMerge?: boolean | void
	rest?: string | boolean | void
	predefinedKeys?: Array<Extract<keyof T, string> | (string & {})>
}

export function destructuringWithGroupsHelper<TBase extends object>() {
	return <GS extends GroupsScheme<TBase>>(groupsScheme: GS) => {
		return configurator
		function configurator<Rest extends Extract<keyof GS, string>>(
			config: Assign<ConfigBase<TBase>, { allowMerge: true; rest: Rest }>
		): <T extends TBase>(obj: T) => ExtractGroups<T, GS, Rest>
		function configurator<Rest extends true | string>(
			config: Assign<ConfigBase<TBase>, { allowMerge?: false; rest: Rest }>
		): <T extends TBase>(obj: T) => ExtractGroups<T, GS, true extends Rest ? "rest" : Rest>
		function configurator(
			config?: Assign<ConfigBase<TBase>, { allowMerge?: false; rest?: string }>
		): <T extends TBase>(obj: T) => ExtractGroups<T, GS, undefined>
		function configurator({ allowMerge = false, rest = false, predefinedKeys }: ConfigBase<any> = {}) {
			const restGroup = typeof rest === "boolean" ? (rest && "rest") || undefined : rest
			if (restGroup && !allowMerge && restGroup in groupsScheme) {
				throw new Error(
					"Destructuring with groups helper: rest group does not supposed to be in groups scheme, until 'allowMerge' is true"
				)
			}
			const groups: Map<string, Array<readonly [string, string]>> = new Map()
			const keysSet = new Set(predefinedKeys)
			const restKeysSet = new Set(predefinedKeys)
			for (const groupName of Object.keys(groupsScheme)) {
				const group: Array<readonly [string, string]> = []
				groups.set(groupName, group)
				function addKey(input: string, output: string) {
					group.push([input, output])
					keysSet.add(input)
					restKeysSet.delete(input)
				}
				const keys = groupsScheme[groupName]
				for (const key of keys) {
					if (typeof key === "string") {
						addKey(key, key)
					} else if (Array.isArray(key)) {
						addKey(key[0], key[1])
					} else {
						for (const input of Object.keys(key)) {
							addKey(input, (key as any)[input])
						}
					}
				}
			}
			let restKeys: Array<readonly [string, string]> | undefined
			if (restGroup) {
				restKeys = groups.get(restGroup)
				if (!restKeys) {
					restKeys = []
					groups.set(restGroup, restKeys)
				}
				for (const key of restKeysSet) {
					restKeys.push([key, key])
				}
			}
			return (obj: any) => {
				if (restGroup) {
					for (const key of Object.keys(obj)) {
						if (Object.prototype.hasOwnProperty.call(obj, key)) {
							if (!keysSet.has(key)) {
								restKeys!.push([key, key])
							}
						}
					}
				}
				const resultEntries = [] as Array<[string, any]>
				for (const [groupName, keys] of groups) {
					const groupEntries = [] as Array<[string, any]>
					for (const [input, output] of keys) {
						if (Object.prototype.hasOwnProperty.call(obj, input)) {
							groupEntries.push([output, obj[input]])
						}
					}
					resultEntries.push([groupName, Object.fromEntries(groupEntries)])
				}
				return Object.fromEntries(resultEntries)
			}
		}
	}
}

type InferGroupKeyInput<K extends Group<string>[number]> = K extends Readonly<Record<infer I, string>>
	? I
	: K extends readonly [infer I, string]
	? I
	: K extends string
	? K
	: never
type InferGroupInputKeys<G extends Group<string>> = InferGroupKeyInput<G[number]>
// const testGroupInputKeysInfer: InferGroupInputKeys<["1", ["2", "naah"], { "3": "blah" }]> = null as any as
// 	| "1"
// 	| "2"
// 	| "3"

type InferGroupKeyOutput<K extends Group<string>[number]> = K extends Readonly<Record<string, infer O>>
	? O
	: K extends readonly [string, infer O]
	? O
	: K extends string
	? K
	: never
type InferGroupOutputKeys<G extends Group<string>> = InferGroupKeyOutput<G[number]>
// const testGroupOutputKeysInfer: InferGroupOutputKeys<["1", ["2", "naah"], { "3": "blah" }]> = null as any as
// 	| "1"
// 	| "naah"
// 	| "blah"

type InferGroupKeyInputByOutput<K extends Group<string>[number], O extends string> = K extends Readonly<
	Record<string, string>
>
	? {
			[I in keyof K]: K[I] extends O ? I : never
	  }[keyof K]
	: K extends readonly [string, string]
	? K extends readonly [infer I, O]
		? I
		: never
	: K extends string
	? O extends K
		? O
		: never
	: unknown
type InferGroupInputByOutput<G extends Group<string>, O extends string> = InferGroupKeyInputByOutput<G[number], O>
// const testRecordGroupOutputInfer: InferGroupInputByOutput<["1", ["naah", "2"], { blah: "3" }], "3"> =
// 	null as any as "blah"
// const testRenamedArrayGroupOutputInfer: InferGroupInputByOutput<["1", ["naah", "2"], { blah: "3" }], "2"> =
// 	null as any as "naah"
// const testSimpleArrayGroupOutputInfer: InferGroupInputByOutput<["1", ["naah", "2"], { blah: "3" }], "1"> =
// 	null as any as "1"

type PickGroupFromObject<T extends object, G extends Group<string>> = {
	[O in Extract<InferGroupOutputKeys<G>, string>]: T[Extract<InferGroupInputByOutput<G, O>, keyof T>]
}
// const testPickGroupFromObject1: PickGroupFromObject<{ a: 1; b: 2 }, ["a"]> = null as any as { a: 1 }
// const testPickGroupFromObject2: PickGroupFromObject<{ a: 1; b: 2 }, ["a", "b"]> = null as any as { a: 1; b: 2 }
// const testPickGroupFromObject3: PickGroupFromObject<{ a: 1; b: 2 }, ["a", ["b", "c"]]>
// 	= null as any as { a: 1; c: 2 }
// const testPickGroupFromObject4: PickGroupFromObject<{ a: 1; b: 2 }, ["a", { b: "c" }]>
// 	= null as any as { a: 1; c: 2 }

type InferGroupsInputKeys<GS extends GroupsScheme<any>> = {
	[G in keyof GS]: InferGroupInputKeys<GS[G]>
}[keyof GS]
// type TestGroup = { a: ["1"]; b: [["2", "blah"], { "3": "naah" }] }
// const testGroupsInputKeysInfer1: InferGroupsInputKeys<TestGroup> = null as any as "1" | "2" | "3"

type ExtractGroups<T extends object, GS extends GroupsScheme<any>, Rest> = {
	[G in keyof GS]: PickGroupFromObject<T, GS[G]>
} &
	{
		[R in Extract<Rest, string>]: Omit<T, Extract<InferGroupsInputKeys<GS>, string>>
	}

// type TestObject = { "1": 1; "2": 2; "3": 3; "4": 4 }
// type TestGroup = { a: ["1"]; b: [["2", "_2"], { "3": "_3" }] }
// const testGroupsInputKeysInfer1: ExtractGroups<TestObject, TestGroup, "rest"> = null as any as {
// 	a: { "1": 1 }
// 	b: { _2: 2; _3: 3 }
// 	rest: { "4": 4 }
// }
// const testGroupsInputKeysInfer2: ExtractGroups<TestObject, TestGroup, "customRest"> = null as any as {
// 	a: { "1": 1 }
// 	b: { _2: 2; _3: 3 }
// 	customRest: { "4": 4 }
// }
// const testGroupsInputKeysInfer3: ExtractGroups<TestObject, TestGroup, "a"> = null as any as {
// 	a: { "1": 1; "4": 4 }
// 	b: { _2: 2; _3: 3 }
// }
