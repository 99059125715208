import { LayoutType } from "@components/shared/layouts"
import { Trans } from "@lingui/macro"
import { StyledInput, InputContainer, InputError, StyledInputWrapper } from "./email-input.styles"

export interface IEmailInputProps {
	size: keyof typeof LayoutType
	value: string
	placeholder?: string
	isStretch?: boolean
	onChange: (newValue: string, isValid: boolean) => void
	errorPosition?: "close" | "far"
	showError?: boolean
}

export const EmailInput = ({
	size = "Mobile",
	placeholder = "",
	isStretch = false,
	errorPosition = size === "Mobile" ? "far" : "close",
	showError = false,
	onChange,
	...rest
}: IEmailInputProps) => {
	const checkIsNotValid = (val: string) => {
		return (
			val.length < 0 ||
			!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/.test(
				val
			)
		)
	}

	const onInputChange = (evt?: React.FormEvent<HTMLInputElement>) => {
		const val = evt?.currentTarget.value ?? ""
		onChange(val, checkIsNotValid(val))
	}

	return (
		<StyledInputWrapper>
			<InputContainer>
				<StyledInput
					type="email"
					layout={size}
					placeholder={placeholder}
					isStretch={isStretch}
					isError={checkIsNotValid(rest.value)}
					onChange={onInputChange}
					{...rest}
				/>
				{showError && checkIsNotValid(rest.value) && (
					<InputError errorPosition={errorPosition} layout={size} isStretch={isStretch}>
						<Trans>Enter the correct email</Trans>
					</InputError>
				)}
			</InputContainer>
		</StyledInputWrapper>
	)
}
