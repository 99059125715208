import { LayoutType } from "@components/shared/layouts"
import { forwardRef, PropsWithChildren } from "react"

import { Wrapper, Line } from "./progress-line.styles"

export interface IProgressLineProps {
	isStretch?: boolean
	size?: keyof typeof LayoutType
	progress: number
}

export const ProgressLine = forwardRef<unknown, PropsWithChildren<IProgressLineProps & JSX.IntrinsicElements["div"]>>(
	({ isStretch = false, progress = 0, size = "Mobile", ...rest }, ref: any) => {
		return (
			<Wrapper size={size} isStretch={isStretch} ref={ref} progress={progress} {...rest}>
				<Line progress={progress} />
			</Wrapper>
		)
	}
)
