import { color, background } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import { typography } from "@components/shared/typography"

import styled, { css } from "styled-components"

export const DesktopWrapper = styled.div`
	position: relative;
	width: 100%;

	min-height: 100vh;
	max-height: 100vh;
	overflow: hidden;

	display: flex;
	flex-direction: row;
`

export const CenterContent = styled.div`
	width: 40%;
	height: 100%;
	padding: 0 ${spacing.padding.horizontalSmall}px;

	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: 900;
`

export const LeftHumansWrapper = styled.div`
	position: relative;
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	z-index: 800;
`

export const RightHumansWrapper = styled.div`
	position: relative;
	width: 30%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	z-index: 800;
`

const Humans = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	/* flex-wrap: wrap; */
	width: 100%;
	height: auto;

	> span {
		display: flex;
		flex-direction: column;
		width: 50%;

		> img {
			width: calc(100% - 20px);
			margin: 14px 10px;
			height: auto;
			background: ${background.white};
			box-shadow: 0px 6px 44px rgba(0, 0, 0, 0.24);
			border-radius: ${spacing.borderRadius.medium}px;
		}
	}
`

export const LeftHumans = styled(Humans)`
	> span:first-child {
		margin-top: -14px;

		> img:first-child {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	> span:last-child {
		margin-top: 54px;
	}
`

export const RightHumans = styled(Humans)`
	align-items: flex-end;
	justify-content: flex-end;

	> span:first-child {
		margin-bottom: 54px;
	}

	> span:last-child {
		margin-bottom: -14px;

		> img:last-child {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
`

export const Logo = styled.img<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? 94 : 120)}px;
	height: auto;
	margin: 0;
`

export const Title = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.fontSize.h2)}px;
	line-height: ${({ size }) => (size === "Mobile" ? `${typography.lineSize.h3}px` : "110%")};

	text-align: center;
	letter-spacing: ${typography.letterSpacing.h}em;

	color: ${color.white};

	margin: ${({ size }) => (size === "Mobile" ? "8px 0" : "32px 0 16px")};
`

export const Description = styled.p<{ size: keyof typeof LayoutType }>`
	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.bold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.bm1 : typography.fontSize.b1)}px;
	line-height: ${({ size }) => (size === "Mobile" ? typography.lineSize.b2 : typography.lineSize.b1)}px;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.b}em;

	color: ${color.milk};

	opacity: 0.4;
	margin: 0;
`

export const FashionLogos = styled.div<{ size: keyof typeof LayoutType }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	${({ size }) =>
		size === "Mobile"
			? css`
					margin: 17px 0;

					> img {
						height: 22px;
						width: auto;
						margin: 0 10px;
					}
			  `
			: css`
					margin: 28px 0 46px;

					> img {
						height: 30px;
						width: auto;
						margin: 0 10px;
					}
			  `};
`

export const Background = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: ${background.meteorite};

	z-index: 100;
`

export const Line1Wrapper = styled.img`
	position: absolute;
	top: 0;
	right: 14%;

	width: 700px;
	height: auto;

	z-index: 3;
`

export const Line2Wrapper = styled.img`
	position: absolute;
	top: 0;
	right: 0;

	width: 300px;
	height: auto;

	z-index: 2;
`

export const Line3Wrapper = styled.img`
	position: absolute;
	bottom: 0;
	right: 24%;

	width: 800px;
	height: auto;
`

// MOBILE DESIGN

export const MobileScreen = styled.div<{ windowHeight: number }>`
	position: relative;
	width: 100%;
	height: ${({ windowHeight }) => windowHeight}px;
	min-height: ${({ windowHeight }) => windowHeight}px;

	overflow: hidden;

	display: flex;
	flex-direction: row;

	box-sizing: border-box;

	z-index: 1;

	margin: 0;
`
// export const MobileScreen = styled.div`
// 	position: relative;
// 	width: 100%;
// 	height: 100vh;
// 	min-height: -webkit-fill-available;
// 	max-height: -webkit-fill-available;

// 	overflow: hidden;

// 	display: flex;
// 	flex-direction: row;

// 	box-sizing: border-box;

// 	z-index: 1;

// 	margin: 0;
// `

export const MobileBackground = styled.div`
	position: absolute;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	box-sizing: border-box;
	left: 0;
	background: ${background.meteorite};

	z-index: 100;

	overflow: hidden;

	display: flex;
	flex-direction: row;
	justify-content: center;
`

export const MobileHumansWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	height: auto;

	max-width: 500px;

	padding: 0 ${spacing.padding.horizontalSmall}px;
	box-sizing: border-box;

	> span {
		display: flex;
		flex-direction: column;
		width: 50%;
		margin-top: 10px;

		&:nth-child(2) {
			margin-top: 50px;
		}

		> img {
			width: calc(100% - 20px);
			margin: 10px 8px;
			height: auto;
			background: ${background.white};
			box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.24);
			border-radius: ${spacing.borderRadius.medium}px;
		}
	}
`

/* export const MobileShadow = styled.div<{ device: "android" | "ios" }>` */
export const MobileShadow = styled.div<{ windowHeight: number; agreed?: boolean }>`
	position: absolute;
	bottom: ${({ agreed }) => (agreed ? 0 : 100)}px;
	left: 0;
	width: 100%;
	height: auto;
	/* height: 48%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(50, 35, 125, 0) 0%, #32237d 37.87%);
	z-index: 9200;
	padding: 40px ${spacing.padding.horizontalSmall}px 0;
	box-sizing: border-box;

	transition: bottom 1.5s;

	/* padding-bottom: 150px; */
	/* padding-bottom: ${spacing.padding.horizontalSmall}px; */

	/* @media (max-height: 736px) { */
	/* padding-top: 0; */
	/* } */

	> span {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		margin: 0;
	}
`

export const MobileLine1Wrapper = styled.img`
	position: absolute;
	width: 90%;
	height: auto;
	top: 0;
	left: 0%;
	opacity: 0.5;
`

export const MobileLine2Wrapper = styled.img`
	position: absolute;
	width: 24%;
	height: auto;
	top: 0;
	right: -20px;
`

export const MobileLine3Wrapper = styled.img`
	position: absolute;
	width: 100%;
	height: auto;
	bottom: 20px;
	left: -20px;
	opacity: 0.3;
`

// export const MobileActionButtonWrapper = styled.div`
// 	bottom: 0;
// 	height: 12vh;
// 	max-height: 12vh;
// 	width: 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	position: fixed;
// 	bottom: 0;
// 	left: 0;
// 	z-index: 9999;
// 	box-sizing: border-box;
// `

export const MobileActionButtonWrapper = styled.div<{ agreed?: boolean }>`
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* position: ${({ agreed }) => (agreed ? "absolute" : "fixed")}; */
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 9000;
	background: #32237d;
`
