import Slide1 from "@assets/Slide1.png"
import Slide2 from "@assets/Slide2.png"
import Slide3 from "@assets/Slide3.png"
import Slide4 from "@assets/Slide4.png"
import { t } from "@lingui/macro"
import { useTransition } from "@react-spring/web"
import { useState } from "react"
import { Carousel, CarouselItemProps } from "~/components2/atoms/carousel"

import * as A from "./atoms"

export const bigSlides = [
	{
		main: true,
		img: Slide1,
		title: t`My Personal Course`,
		subtitle: t`day 3, week 1`,
		descriptionTitle: t`Main Course`,
		description: t`Follow the soothing voice and clear video explanations on your personalised Face Yoga journey `,
	},
	{
		main: false,
		img: Slide3,
		title: t`Boost Your Hair Growth`,
		subtitle: t`Improve blood flow`,
		descriptionTitle: t`Head and Scalp Massage`,
		description: t`A scalp massage increases hair thickness and stimulates the follicles to produce thicker hair. It`,
	},
	{
		main: false,
		img: Slide4,
		title: t`Buccal Massage`,
		subtitle: t`Lifting the lower third of the face`,
		descriptionTitle: t`Intraoral massage`,
		description: t`Great way to de-stress and relieve tension around the mouth and jaw area. `,
	},
	{
		main: false,
		img: Slide2,
		title: t`Face Yoga Meditation`,
		subtitle: t`Relax your mind and muscles`,
		descriptionTitle: t`Face Yoga Meditation`,
		description: t`Meditation will help you to cope with stress, accept yourself, and tune in to positive emotions.`,
	},
]

export type BigSlideProps = CarouselItemProps<typeof bigSlides[number]>
export function BigSlide({ item: { main, img, title, subtitle }, selectItem }: BigSlideProps) {
	return (
		<A.BigSlide main={main} onClick={selectItem}>
			<img alt="" src={img} />
			<A.BigSlideInfo main={main}>
				<A.BigSlideTitle main={main}>{title}</A.BigSlideTitle>
				<A.BigSlideSubTitle main={main}>{subtitle}</A.BigSlideSubTitle>
			</A.BigSlideInfo>
		</A.BigSlide>
	)
}

export function BigCarousel() {
	const [value, setValue] = useState(0)
	const transitions = useTransition(value, {
		from: { opacity: 0 },
		enter: { opacity: 1 },
		leave: { opacity: 0 },
	})

	return (
		<A.BigCarouselContainer>
			<Carousel
				items={bigSlides}
				keyGetter={(item) => item.title}
				renderItem={(props) => <BigSlide {...props} />}
				itemSize={{ width: 288, height: 312 }}
				dots
				dots-style={{ marginTop: 0 }}
				value={value}
				onValueChanged={setValue}
			/>
			<A.BigCarouselFeatures>
				{transitions(({ opacity }, value) => (
					<A.BigCarouselFeature style={{ opacity }}>
						<A.FeatureTitle>{bigSlides[value].descriptionTitle}</A.FeatureTitle>
						<A.FeatureDescription>{bigSlides[value].description}</A.FeatureDescription>
					</A.BigCarouselFeature>
				))}
			</A.BigCarouselFeatures>
		</A.BigCarouselContainer>
	)
}
