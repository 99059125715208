import { Trans, t } from "@lingui/macro"
import React, { useEffect } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"

import BlueCircle from "~/assets/BlueCircle.svg"
import BlueCircleDesktop from "~/assets/BlueCircleDesktop.svg"
import OrangeStar from "~/assets/OrangeStar.svg"
import { useYandexMetrika } from "~/contexts/yandex-metrika-context"
import { Button } from "../../atoms/Button"
import { Header } from "../../atoms/Header"
import { TextHead } from "../../atoms/Text"

const Wrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	overflow: hidden;
`

const Content = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	bottom: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	z-index: 50;

	> p {
		@media (orientation: portrait) {
			width: calc(100% - 40px);
		}
		text-align: left;
	}

	@media (orientation: landscape) {
		align-items: flex-start;
		margin-left: 80px;
	}
`

const Background = styled.div`
	position: absolute;
	width: 100%;
	height: auto;
	bottom: 0;
	left: 0;

	z-index: 10;
`

const CircleWrapper = styled.img`
	position: relative;
	width: 100%;
	height: auto;
	bottom: -10px;
	left: 0;

	@media (orientation: landscape) {
		display: none;
	}
`

const DesktopCircleWrapper = styled.img`
	position: relative;
	width: 90%;
	height: auto;
	bottom: -10px;
	left: 0;

	@media (orientation: portrait) {
		display: none;
	}
`

const StarWrapper = styled.img`
	position: absolute;
	width: 107px;
	height: auto;
	bottom: 60%;
	right: 30.5px;

	@media (orientation: landscape) {
		right: auto;
		left: 65%;
	}
`

const ButtonWrapper = styled.div`
	position: absolute;
	bottom: 44px;
	left: 20px;
	width: calc(100% - 40px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	@media (orientation: landscape) {
		position: relative;
		bottom: 0;
		left: 0;
		margin-top: 40px;
		width: auto;
		align-items: flex-start;
	}
`

export function NotFoundPage() {
	const history = useHistory()
	const yandexMetrika = useYandexMetrika()

	useEffect(() => {
		yandexMetrika.ymTrackPage("/page-not-found")
	}, [yandexMetrika])

	return (
		<Wrapper>
			<Header lightBurger desktopSimplified />
			<Background>
				<CircleWrapper alt="" src={BlueCircle} />
				<DesktopCircleWrapper alt="" src={BlueCircleDesktop} />
				<StarWrapper alt="" src={OrangeStar} />
			</Background>
			<Content>
				<TextHead color="#ffffff" size="big">
					<Trans>404 error.</Trans>
					<br />
					<Trans>Page not found</Trans>
				</TextHead>
				<ButtonWrapper>
					<Button
						color="pink"
						title={t`Back to Home`}
						onClick={() => {
							history.push("/")
						}}
					/>
				</ButtonWrapper>
			</Content>
		</Wrapper>
	)
}
