import Carousel, {
	centeredPlugin,
	clickToChangePlugin,
	fastSwipePlugin,
	slidesToShowPlugin,
} from "@brainhubeu/react-carousel"
import { Key, ReactElement, useMemo, useEffect } from "react"
import styled from "styled-components"
import { destructuringWithGroupsHelper } from "~/utils/destructuring-with-groups-helper"
import { StylableProps } from "~/utils/types"
import * as A from "./atoms"
import { CarouselItemProps, CarouselValueProps, Size } from "./types"

import "@brainhubeu/react-carousel/lib/style.css"

const StyledCarousel = styled(Carousel)<{ height: number }>`
	height: ${({ height }) => (height !== undefined ? `${height}px` : "100%")};
`

export type CarouselItemsProps<T> = {
	items: T[]
	keyGetter?: (item: T, index: number) => Key
	renderItem: (props: CarouselItemProps<T>) => ReactElement
	wrapperWidth: number
	itemSize: Size
	itemMargin?: number
	infinity?: boolean
} & CarouselValueProps &
	StylableProps &
	StylableProps<"item-">
export function CarouselItems<T>({
	items,
	keyGetter,
	renderItem,
	wrapperWidth,
	itemSize,
	itemMargin = 0,
	infinity = true,
	...props
}: CarouselItemsProps<T>) {
	const { styleProps, valueProps, itemProps } = useMemo(() => {
		return destructuringWithGroupsHelper<typeof props>()({
			valueProps: ["value", "onValueChanged"],
			styleProps: ["className", "style"],
			itemProps: [{ "item-className": "className", "item-style": "style" }],
		} as const)()
	}, [])(props)

	const visibleOffset = useMemo(() => {
		const leftAfterCenter = (wrapperWidth - itemSize.width) / 2 // - itemMargin
		return leftAfterCenter / itemSize.width
	}, [itemSize.width, wrapperWidth])
	const numberOfSlides = useMemo(() => 1 + visibleOffset * 2, [visibleOffset])
	const fakeInfinityItems = useMemo(() => {
		const left = Math.ceil(visibleOffset)
		return [...items.slice(-left), ...items.slice(0, -left)]
	}, [items, visibleOffset])
	useEffect(() => {
		valueProps.onValueChanged(1)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueProps.onValueChanged])
	return (
		<A.Items {...styleProps} height={itemSize.height}>
			<StyledCarousel
				value={valueProps.value}
				onChange={valueProps.onValueChanged}
				height={itemSize.height}
				draggable
				plugins={[
					{
						resolve: slidesToShowPlugin,
						options: { numberOfSlides },
					},
					{
						resolve: clickToChangePlugin,
					},
					{
						resolve: centeredPlugin,
					},
					// {
					// 	resolve: infinitePlugin,
					// },
					{
						resolve: fastSwipePlugin,
					},
				]}
				slides={fakeInfinityItems.map((item, i) => {
					const element = renderItem({
						index: i,
						item,
						size: itemSize,
						selectItem: () => valueProps.onValueChanged(i),
					})
					const key = keyGetter?.(item, i) ?? element.key ?? i
					return (
						<A.Item key={key} {...itemProps}>
							{element}
						</A.Item>
					)
				})}
			/>
		</A.Items>
	)

	// const valueRef = useRef({ prev: value, curr: value, anim: value })
	// const setValueAnim = useCallback(
	// 	(value: number) => {
	// 		const bounded = ((value % items.length) + items.length) % items.length
	// 		valueRef.current.anim = bounded
	// 		return bounded
	// 	},
	// 	[items.length]
	// )
	// const setValueCurr = useCallback(
	// 	(value: number) => {
	// 		const bounded = setValueAnim(value)
	// 		valueRef.current.prev = valueRef.current.curr
	// 		valueRef.current.curr = bounded
	// 		return bounded
	// 	},
	// 	[setValueAnim]
	// )
	// const setValue = useCallback(
	// 	(value: number) => {
	// 		const bounded = setValueCurr(value)
	// 		onValueChanged?.(bounded)
	// 		return bounded
	// 	},
	// 	[onValueChanged, setValueCurr]
	// )
	// const itemsHalf = useMemo(() => Math.ceil((items.length - 1) / 2), [items.length])
	// const indexOffset = useCallback(
	// 	(value: number, i: number) => {
	// 		let offset = i - value
	// 		if (infinity) {
	// 			if (Math.abs(offset) > itemsHalf) {
	// 				offset -= (offset < 0 ? -1 : 1) * items.length
	// 			}
	// 		}
	// 		return offset
	// 	},
	// 	[infinity, items.length, itemsHalf]
	// )
	// const [offsets, offsetsSprings] = useSprings(items.length, (i) => ({
	// 	offset: indexOffset(value, i),
	// 	immediate: true,
	// 	config: { mass: 1, tension: 50 },
	// }))

	// const baseController = useMemo(() => {
	// 	function beforeAnimation() {}
	// 	function animation(value: number, i: number, ctrl: Controller<{ offset: number }>) {
	// 		const prev = ctrl.get().offset
	// 		const prevVisible = Math.abs(prev) < visibleOffset
	// 		const next = indexOffset(value, i)
	// 		const nextVisible = Math.abs(next) < visibleOffset
	// 		return { prev, prevVisible, next, nextVisible }
	// 	}
	// 	function afterAnimation(results: AsyncResult<Controller<{ offset: number }>>[]) {}
	// 	return { beforeAnimation, animation, afterAnimation }
	// }, [indexOffset, visibleOffset])
	// const exactController = useMemo(() => {
	// 	function beforeAnimation() {
	// 		baseController.beforeAnimation()
	// 	}
	// 	function animation(value: number, i: number, ctrl: Controller<{ offset: number }>) {
	// 		let { prev, prevVisible, next, nextVisible } = baseController.animation(value, i, ctrl)
	// 		if (prev < 0 !== next < 0 && prev !== 0 && next !== 0) {
	// 			// FIXME: not proper animation
	// 			console.log("3 SWIPE fix", prev, next)
	// 		}
	// 		return { prev, prevVisible, next, nextVisible }
	// 	}
	// 	function afterAnimation(results: AsyncResult<Controller<{ offset: number }>>[]) {
	// 		baseController.afterAnimation(results)
	// 	}
	// 	return { beforeAnimation, animation, afterAnimation }
	// }, [baseController])
	// const plusOneController = useMemo(() => {
	// 	let scheduledNext: { i: number; offset: number } | null = null
	// 	function beforeAnimation() {
	// 		baseController.beforeAnimation()
	// 		if (scheduledNext !== null) {
	// 			const { i, offset } = scheduledNext
	// 			const ctrl = offsetsSprings.current[i]
	// 			console.log("SWIPE was incomplete", i, offset)
	// 			ctrl.set({ offset })
	// 			scheduledNext = null
	// 		}
	// 	}
	// 	function animation(value: number, i: number, ctrl: Controller<{ offset: number }>) {
	// 		let { prev, prevVisible, next, nextVisible } = baseController.animation(value, i, ctrl)
	// 		if (prevVisible && !nextVisible) {
	// 			nextVisible = true
	// 			if (prev < 0 !== next < 0) {
	// 				console.log("SWIPE schedule", i, next)
	// 				scheduledNext = { i, offset: next }
	// 				next = prev + (prev < next ? -1 : 1)
	// 			}
	// 		}
	// 		if (!prevVisible && nextVisible) {
	// 			prevVisible = true
	// 			if (prev < 0 !== next < 0) {
	// 				prev = next + (prev < next ? 1 : -1)
	// 				ctrl.set({ offset: prev })
	// 			}
	// 		}
	// 		return { prev, prevVisible, next, nextVisible }
	// 	}
	// 	async function afterAnimation(results: AsyncResult<Controller<{ offset: number }>>[]) {
	// 		baseController.afterAnimation(results)
	// 		if (scheduledNext === null) return
	// 		const { i, offset } = scheduledNext
	// 		const { finished } = await results[i]
	// 		if (finished) {
	// 			console.log("SWIPE completing", i, offset)
	// 			const ctrl = offsetsSprings.current[i]
	// 			ctrl.set({ offset })
	// 			scheduledNext = null
	// 		} else {
	// 			console.log("SWIPE was interrupted", i, offset)
	// 		}
	// 	}
	// 	return { beforeAnimation, animation, afterAnimation }
	// }, [offsetsSprings, baseController])
	// const controller = useMemo(() => {
	// 	let controller = baseController
	// 	const itemsNotVisible = items.length - 1 - Math.ceil(visibleOffset - 1) * 2
	// 	if (itemsNotVisible === 0) {
	// 		controller = exactController
	// 	} else if (itemsNotVisible === 1) {
	// 		controller = plusOneController
	// 	}
	// 	return controller
	// }, [baseController])

	// const moveOffsets = useCallback(
	// 	(value: number, animate: boolean = false) => {
	// 		offsetsSprings.start((i) => {
	// 			return { offset: indexOffset(value, i), immediate: !animate }
	// 		})
	// 	},
	// 	[indexOffset, offsetsSprings]
	// )

	// const swipeValue = useCallback(
	// 	(direction: -1 | 1) => {
	// 		const bounded = setValue(value + direction)
	// 		controller.beforeAnimation()
	// 		const results = offsetsSprings.start((i, ctrl) => {
	// 			const { prevVisible, next, nextVisible } = controller.animation(bounded, i, ctrl)
	// 			return { offset: next, immediate: !(prevVisible && nextVisible) }
	// 		})
	// 		controller.afterAnimation(results)
	// 	},
	// 	[controller, offsetsSprings, setValue, value]
	// )

	// const slideValue = useCallback(
	// 	(value: number, from: -1 | 1) => {
	// 		value = setValue(value)
	// 		offsetsSprings.set({ offset: from * visibleOffset })
	// 		offsetsSprings.start((i) => {
	// 			const next = indexOffset(value, i)
	// 			const nextVisible = Math.abs(next) < visibleOffset
	// 			return { offset: next, immediate: !nextVisible }
	// 		})
	// 	},
	// 	[indexOffset, offsetsSprings, setValue, visibleOffset]
	// )

	// const animateValue = useCallback(
	// 	(next: number) => {
	// 		moveOffsets(setValue(next), true)
	// 		// const prev = valueRef.current
	// 		// const right = (next + items.length - prev) % items.length
	// 		// const left = (prev + items.length - next) % items.length
	// 		// console.log({ next, prev, right, left })
	// 		// if (right <= 1) {
	// 		// 	swipeValue(1)
	// 		// } else if (left <= 1) {
	// 		// 	swipeValue(-1)
	// 		// } else if (right === left) {
	// 		// 	slideValue(next, next > prev ? 1 : -1)
	// 		// } else {
	// 		// 	slideValue(next, right < left ? 1 : -1)
	// 		// }
	// 	},
	// 	[moveOffsets, setValue]
	// )

	// useEffect(() => {
	// 	if (value !== undefined && value !== valueRef.current.anim) {
	// 		animateValue(value)
	// 	}
	// }, [animateValue, offsetsSprings, value])

	// const disableNextClick = useRef(false)
	// const swipeBind = useGesture(
	// 	{
	// 		onDrag: ({ active, movement: [xMov] }) => {
	// 			const move = -xMov / itemSize.width
	// 			if (active) {
	// 				moveOffsets(setValueAnim(valueRef.current.curr + move))
	// 			} else {
	// 				animateValue(Math.round(valueRef.current.curr + move))
	// 				disableNextClick.current = true
	// 			}
	// 		},
	// 		onClickCapture: ({ event }) => {
	// 			if (!disableNextClick.current) return
	// 			event.stopPropagation()
	// 			disableNextClick.current = false
	// 		},
	// 		onWheel: ({ event, active, movement: [xMov] }) => {
	// 			event.preventDefault()
	// 			const move = xMov / itemSize.width
	// 			if (active) {
	// 				moveOffsets(setValueAnim(valueRef.current.curr + move))
	// 			} else {
	// 				animateValue(Math.round(valueRef.current.curr + move))
	// 			}
	// 		},
	// 	},
	// 	{ drag: { axis: "x", experimental_preventWindowScrollY: true }, wheel: { axis: "x" } }
	// )
	// const firstItemOffset = useMemo(() => (wrapperWidth - itemSize.width) / 2, [itemSize.width, wrapperWidth])
	// return (
	// 	<A.Items {...styleProps} {...swipeBind()} height={itemSize.height}>
	// 		{offsets.map(({ offset }, i) => {
	// 			const translateX = offset.to((offset) => offset * (itemSize.width + itemMargin) + firstItemOffset)
	// 			const element = renderItem({
	// 				index: i,
	// 				item: items[i],
	// 				size: itemSize,
	// 				selectItem: () => animateValue(i),
	// 			})
	// 			const key = keyGetter?.(items[i], i) ?? element.key ?? i
	// 			return (
	// 				<A.Item
	// 					key={key}
	// 					{...itemProps}
	// 					size={itemSize}
	// 					style={{ ...itemProps.style, x: translateX }}
	// 					data-index={i}
	// 				>
	// 					{element}
	// 				</A.Item>
	// 			)
	// 		})}
	// 	</A.Items>
	// )
}
