import { ScreenLayout } from "@components/layouts"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { QuoteDesktopScreen } from "./quote-desktop-screen"
import { QuoteMobileScreen } from "./quote-mobile-screen"
import { MobileScreen, DesktopScreen, ScreenContent } from "./quote-screen.styles"

export interface IQuoteScreenProps {
	background: string
	label: string
	author: string
	onNext: () => void
	isShown: boolean
}

export const QuoteScreen = ({ background, label, author, onNext, isShown }: IQuoteScreenProps) => {
	const size = useWindowLayout()

	if (!isShown) {
		if (size === "Mobile") {
			return (
				<ScreenLayout size={size}>
					<MobileScreen>
						<ScreenContent></ScreenContent>
					</MobileScreen>
				</ScreenLayout>
			)
		}
		return (
			<ScreenLayout size={size}>
				<DesktopScreen></DesktopScreen>
			</ScreenLayout>
		)
	}

	if (size === "Mobile") {
		return <QuoteMobileScreen background={background} label={label} author={author} onNext={onNext} isShown={isShown} />
	}
	return <QuoteDesktopScreen background={background} label={label} author={author} onNext={onNext} isShown={isShown} />
}
