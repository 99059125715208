import { PaypalSubscriptionStatus } from "~/subscriptions-web/paypal/domain/paypal-subscription-status"
import {
	PaypalSubscriptionDoc,
	PaypalSubscriptionDocContainer,
} from "~/subscriptions-web/paypal/firestore/paypal-subscription-doc"
import { Subscription, SubscriptionProvider, SubscriptionStatus } from "../subscription"

const subscriptionStatusFromPaypal = (paypalSubscriptionDoc: PaypalSubscriptionDoc): SubscriptionStatus => {
	switch (paypalSubscriptionDoc.subscriptionStatus) {
		case PaypalSubscriptionStatus.CLOSED:
			return SubscriptionStatus.CLOSED

		case PaypalSubscriptionStatus.CANCELLED:
			return SubscriptionStatus.CANCELLED

		case PaypalSubscriptionStatus.APPROVAL_PENDING:
			const nowTime = new Date()
			const startTime = paypalSubscriptionDoc.startTime.toDate()
			const expireTime = new Date(0, 0, 0, 1, 0, 0, 0)

			if (nowTime.valueOf() - startTime.valueOf() >= expireTime.valueOf()) {
				return SubscriptionStatus.INCOMPLETE_EXPIRED
			}
			return SubscriptionStatus.INCOMPLETE

		case PaypalSubscriptionStatus.ACTIVE:
			return SubscriptionStatus.ACTIVE
		// if () {
		//     return SubscriptionStatus.ACTIVE;
		// }
		// return SubscriptionStatus.TRIALING;

		case PaypalSubscriptionStatus.EXPIRED:
			return SubscriptionStatus.UNPAID

		case PaypalSubscriptionStatus.SUSPENDED:
			return SubscriptionStatus.PAST_DUE

		default:
			return SubscriptionStatus.ERROR
	}
}

export const subscriptionFromPaypal = (paypalSubscriptionDoc: PaypalSubscriptionDocContainer): Subscription => {
	const subscription: Subscription = {
		id: paypalSubscriptionDoc.id,
		periodStart: paypalSubscriptionDoc.doc.startTime.toDate(),
		periodEnd: paypalSubscriptionDoc.doc.next_billing_time?.toDate(),
		status: subscriptionStatusFromPaypal(paypalSubscriptionDoc.doc),
		provider: SubscriptionProvider.Paypal,
		planId: paypalSubscriptionDoc.doc.planId,
		// TODO: think about productId
	}

	return subscription
}
