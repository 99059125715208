import { useCallback } from "react"
import { useHistory } from "react-router"
import { amplitudeService } from "~/services/amplitude"

import { AlphaLanding } from "./alpha-landing"

export const AlphaLandingContainer = () => {
	const history = useHistory()

	const onClickCourse = useCallback(
		(gender: "Male" | "Female") => {
			amplitudeService.logEvent(`[All] Button | Get course | ${gender}`)
			amplitudeService.logEvent("[All] Button | Get course", { name: gender.toLowerCase() })

			localStorage.setItem("gender", gender.toLowerCase())
			history.push("/personal-course")
		},
		[history]
	)

	const onClickMaleCourse = useCallback(() => onClickCourse("Male"), [onClickCourse])
	const onClickFemaleCourse = useCallback(() => onClickCourse("Female"), [onClickCourse])

	return <AlphaLanding onClickMaleCourse={onClickMaleCourse} onClickFemaleCourse={onClickFemaleCourse} />
}
