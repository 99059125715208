export const spacing = {
	padding: {
		horizontalExtraSmall: 16,
		horizontalSmall: 20,
		horizontalBig: 24,
		horizontalHuge: 32,
		verticalSmall: 14,
		verticalBig: 20,
		verticalHuge: 24,
	},
	borderRadius: {
		small: 16,
		medium: 22,
		big: 24,
		huge: 40,
		enormous: 50,
	},
} as const
