import { LayoutType } from "@components/shared/layouts"
import { forwardRef, PropsWithChildren } from "react"
import * as QuestionOverlapLayout from "./overlap-layout.styles"

export interface IOverlapLayoutProps {
	children: React.ReactNode
	src: string
	size?: keyof typeof LayoutType
	withAdditionalMargin?: boolean
}

export const OverlapLayout = forwardRef<unknown, PropsWithChildren<IOverlapLayoutProps & JSX.IntrinsicElements["div"]>>(
	({ size = "Mobile", withAdditionalMargin = true, ...rest }, ref: any) => {
		return (
			<QuestionOverlapLayout.Wrapper size={size} ref={ref}>
				<QuestionOverlapLayout.Top size={size}>
					<img alt="" src={rest.src} />
				</QuestionOverlapLayout.Top>
				<QuestionOverlapLayout.Bottom size={size} withAdditionalMargin={withAdditionalMargin}>
					{rest.children}
				</QuestionOverlapLayout.Bottom>
			</QuestionOverlapLayout.Wrapper>
		)
	}
)
