import { ScreenLayout } from "@components/layouts"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { QuoteListDesktopScreen } from "./quote-list-desktop-screen"
import { QuoteListMobileScreen } from "./quote-list-mobile-screen"
import { MobileScreen, DesktopScreen, ScreenContent } from "./quote-list-screen.styles"

export interface IQuoteListScreenProps {
	background: string
	label: string
	title2: string
	quote1: string
	author1: string
	quote2: string
	author2: string
	onNext: () => void
	isShown: boolean
}

export const QuoteListScreen = ({
	background,
	label,
	title2,
	quote1,
	author1,
	quote2,
	author2,
	onNext,
	isShown,
}: IQuoteListScreenProps) => {
	const size = useWindowLayout()

	if (!isShown) {
		if (size === "Mobile") {
			return (
				<ScreenLayout size={size}>
					<MobileScreen>
						<ScreenContent></ScreenContent>
					</MobileScreen>
				</ScreenLayout>
			)
		}
		return (
			<ScreenLayout size={size}>
				<DesktopScreen></DesktopScreen>
			</ScreenLayout>
		)
	}

	if (size === "Mobile") {
		return (
			<QuoteListMobileScreen
				background={background}
				label={label}
				title2={title2}
				quote1={quote1}
				author1={author1}
				quote2={quote2}
				author2={author2}
				onNext={onNext}
				isShown={isShown}
			/>
		)
	}
	return (
		<QuoteListDesktopScreen
			background={background}
			label={label}
			title2={title2}
			quote1={quote1}
			author1={author1}
			quote2={quote2}
			author2={author2}
			onNext={onNext}
			isShown={isShown}
		/>
	)
}
