import { LayoutType } from "@components/shared/layouts"
import { useWindowDimensions } from "./use-window-dimensions"

export const useWindowLayout = () => {
	const { width } = useWindowDimensions()

	if (width >= 320 && width <= 992) {
		return LayoutType.Mobile
	} else {
		return LayoutType.Desktop
	}
}
