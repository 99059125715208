import React, { ReactElement, useContext, useEffect, useState } from "react"
import { IDConfig, IDConfigService } from "~/services/config/config-types"
import { useI18nService } from "./i18n-service-context"

const ConfigContext = React.createContext<IDConfig | undefined | null>(undefined)

export interface IConfigProvider {
	children: ReactElement
	service: IDConfigService
}

/**
 * ConfigProvider must be within I18nServiceProvider to translation data
 */
export function ConfigProvider({ children, service }: IConfigProvider): ReactElement {
	const [config, setConfig] = useState<IDConfig | null>(null)
	const i18nService = useI18nService()
	const langCode = i18nService.currentCode

	useEffect(() => {
		service.fetchConfig(langCode).then((value) => {
			setConfig(value)
		})
	}, [service, langCode])

	return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}

/**
 * @returns IDConfig or null.
 * IDConfig - when the remote config is fetched.
 * null - when the remote config is still fetching.
 */
export const useConfig = () => {
	const config = useContext(ConfigContext)

	if (config === undefined) {
		throw new Error("useConfig must be used within ConfigProvider")
	}

	return config
}
