import { defineMessage, Trans } from "@lingui/macro"
import styled from "styled-components"
import OrangeStars from "~/assets/OrangeStars.svg"
import { Bubble } from "~/components2/atoms/Bubble"
import { Carousel, CarouselProps } from "~/components2/atoms/carousel"
import { Flexed } from "~/components2/atoms/Flexed"
import { i18nCustom } from "~/services/service/i18n-service"

export interface IReview {
	title: string
	date: string
	author: string
	comment: string
}

export interface IReviewsProps {
	items: IReview[]
}

const StyledReviews = styled.div`
	width: 100%;
	height: auto;

	@media (orientation: landscape) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	> div {
		margin: 12px 0;
		@media (orientation: landscape) {
			width: calc(50% - 12px);

			&:nth-child(odd) {
				margin: 6px 6px 6px 0;
			}
			&:nth-child(even) {
				margin: 6px 0 6px 6px;
			}
		}

		> div > div {
			height: 46px;
			margin-bottom: 14px;

			:last-child {
				> p:last-child {
					display: block;
					width: 130%;
					text-align: right;
				}
			}
		}
	}
`

const Title = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;

	display: inline;

	margin: 0;
`

const AdditionalInfo = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 26px;

	display: inline;

	margin: 0;
	color: #828691;
`

const Comment = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 26px;

	margin: 0;
`

const CarouselWrapper = styled.div`
	@media (orientation: landscape) {
		width: 110%;
	}

	@media (orientation: portrait) {
		width: 100%;
	}
`

export function Reviews({ items }: IReviewsProps) {
	return (
		<StyledReviews>
			{items.map((item, index) => (
				<Review key={index} {...item} />
			))}
		</StyledReviews>
	)
}

export function ReviewsCarousel({
	itemSize,
	...carouselProps
}: IReviewsProps & Omit<CarouselProps<IReview>, "keyGetter" | "renderItem">) {
	return (
		<CarouselWrapper>
			<Carousel
				itemMargin={10}
				{...carouselProps}
				keyGetter={(item) => item.author}
				renderItem={({ item }) => <Review {...item} />}
				itemSize={{ height: 262, ...itemSize }}
				items-style={{ height: 270 }}
			/>
		</CarouselWrapper>
	)
}

const ReviewBubble = styled(Bubble)`
	min-width: 320px;
	height: 262px;
	padding: 20px;
	margin: 0;
`

export function Review({ title, date, author, comment }: IReview) {
	defineMessage({ message: title })
	defineMessage({ message: author })
	defineMessage({ message: comment })

	return (
		<ReviewBubble>
			<Flexed>
				<Flexed width="65%" direction="column" justify="space-between" align="flex-start">
					<Title>
						<Trans>{i18nCustom._(title)}</Trans>
					</Title>
					<img alt="" src={OrangeStars} />
				</Flexed>
				<Flexed width="35%" direction="column" justify="space-between" align="flex-end">
					<AdditionalInfo>{date}</AdditionalInfo>
					<AdditionalInfo>
						<Trans>{i18nCustom._(author)}</Trans>
					</AdditionalInfo>
				</Flexed>
			</Flexed>
			<Comment>
				<Trans>{i18nCustom._(comment)}</Trans>
			</Comment>
		</ReviewBubble>
	)
}
