import { LayoutType } from "@components/shared/layouts"
import { spacing } from "@components/shared/spacing"
import styled, { css } from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: relative;
	height: 100%;
	max-height: 100%;

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	${({ size }) =>
		size === LayoutType.Mobile
			? css`
					width: 100%;
					max-width: 544px;
			  `
			: css`
					width: 544px;
					max-width: 544px;
			  `};
`

export const Top = styled.div<{ size: keyof typeof LayoutType }>`
	position: relative;
	width: 100%;

	${({ size }) =>
		size === LayoutType.Mobile
			? css`
					min-height: 220px;
					max-height: 220px;
			  `
			: css`
					min-height: 280px;
					max-height: 280px;
			  `};

	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	box-sizing: border-box;
	border-radius: ${({ size }) => size === LayoutType.Desktop && `${spacing.borderRadius.big}px`};

	> img {
		min-width: 100%;
		min-height: 100%;
	}
`

export const Bottom = styled.div<{ size: keyof typeof LayoutType; withAdditionalMargin: boolean }>`
	position: absolute;
	left: 0;
	top: ${({ size }) => (size === LayoutType.Mobile ? "192px" : "240px")};
	/* margin-top: -28px; */
	width: 100%;
	height: auto;
	z-index: 200;
	box-sizing: border-box;

	display: flex;
	flex-direction: column;
	align-items: center;

	padding: ${({ size }) =>
		size === LayoutType.Mobile ? `0 ${spacing.padding.horizontalSmall}px` : `0 ${spacing.padding.horizontalHuge}px`};

	padding-bottom: ${({ size, withAdditionalMargin }) =>
		// eslint-disable-next-line no-nested-ternary
		size === LayoutType.Mobile && withAdditionalMargin ? "100px" : size === LayoutType.Mobile ? "30px" : "90px"};
`
