import { color } from "@components/shared/colors"
import { LayoutType } from "@components/shared/layouts"
import { typography } from "@components/shared/typography"
import styled from "styled-components"

export const Wrapper = styled.div<{ size: keyof typeof LayoutType }>`
	position: absolute;
	width: 100%;
	height: ${({ size }) => (size === "Mobile" ? "100%" : "calc(100% - 64px)")};
	display: flex;
	flex-direction: column;
	align-items: center;

	-webkit-transform: translateZ(0);
`

export const Question = styled.p<{ size: keyof typeof LayoutType }>`
	width: ${({ size }) => (size === "Mobile" ? "100%" : "544px")};
	box-sizing: border-box;
	padding: 0 20px;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: ${({ size }) => (size === "Mobile" ? typography.fontSize.hm2 : typography.fontSize.h2)}px;
	line-height: 110%;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.h}em;

	color: ${color.black};

	margin: ${({ size }) => (size === "Mobile" ? "12px 0 24px" : "40px 0 32px")};
`

export const Options = styled.div`
	position: relative;
	height: 100%;
	padding-bottom: 100px;
	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}
`

export const Shadow = styled.div`
	position: fixed;
	left: 0;
	bottom: 0;

	width: 100%;
	height: 100px;

	background: linear-gradient(180deg, rgba(246, 246, 246, 0) 0%, #f6f6f6 62%);

	display: flex;
	justify-content: center;
	align-items: center;

	z-index: 9000;
`
