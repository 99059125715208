import React from "react"
import styled, { css } from "styled-components"
import { InferStyledComponentProps, StylableProps } from "~/utils/types"

import { Flexed } from "../Flexed"

type TTextSize = "big" | "small"

interface IText {
	size?: TTextSize
	color?: string
	inline?: boolean
	centered?: boolean
	margin?: string
	crossed?: boolean
	bold?: boolean
	onClick?: () => void
}

export interface ITextProps extends IText {
	children: React.ReactNode
}

export interface ITextWithIcon extends IText {
	children: React.ReactNode
	icon: string
}

export const InterText = styled.p<Pick<IText, "margin" | "centered" | "inline" | "color" | "crossed">>`
	margin: ${({ margin }) => margin ?? "6px 0"};
	font-style: normal;
	font-family: Inter, sans-serif;

	text-align: ${({ centered }) => (centered ? "center" : "left")};
	${({ inline }) =>
		inline
			? css`
					display: inline;
			  `
			: css`
					display: block;
			  `};

	color: ${({ color }) => (color ? color : "#000000")};
	text-decoration: ${({ crossed }) => (crossed ? "line-through" : "none")};

	pointer-events: none;
`

const StyledHead = styled(InterText)<IText>`
	${({ size }) =>
		size && size === "small"
			? css`
					font-size: 28px;
					line-height: 30px;

					@media (orientation: landscape) {
						font-size: 40px;
						line-height: 44px;
					}
			  `
			: css`
					font-size: 40px;
					line-height: 44px;

					@media (orientation: landscape) {
						font-size: 64px;
						line-height: 70px;
					}
			  `};

	@media (max-width: 320px) {
		font-size: 28px;
		line-height: 30px;
	}

	font-weight: 700;
	letter-spacing: -0.04em;
`

const StyledBody = styled(InterText)<IText>`
	font-weight: ${({ bold }) => (bold ? 600 : 500)};

	${({ size }) =>
		size && size === "small"
			? css`
					font-size: 18px;
					line-height: 28px;
			  `
			: css`
					font-size: 20px;
					line-height: 28px;

					@media (orientation: landscape) {
						font-size: 24px;
						line-height: 34px;
					}
			  `};

	letter-spacing: -0.03em;
`

const ColoredText = styled.span<{ color?: string }>`
	color: ${({ color }) => color || "#5856EA"};
`

const TextWithIconWrapper = styled.div<ITextWithIcon>`
	position: relative;
	box-sizing: content-box;

	margin: ${({ margin }) => margin || 0};

	img {
		position: relative;
		margin: 0px 8px -5px 0;
		display: inline;
	}

	p {
		display: inline-block;
		margin: ${({ centered }) => (centered ? "0 0 0 -14px" : "0 0 0 48px")};
		box-sizing: border-box;
		position: relative;
		max-width: ${({ centered }) => (centered ? "80%" : "100%")};
		height: auto;

		text-indent: ${({ centered }) => (centered ? "" : "-1.7em")};
	}
`

export function TextHead(props: ITextProps & StylableProps) {
	return <StyledHead {...props}>{props.children}</StyledHead>
}

export function TextBody(props: ITextProps & StylableProps) {
	return <StyledBody {...props}>{props.children}</StyledBody>
}

export function Colored(props: ITextProps & StylableProps) {
	return <ColoredText color={props.color}>{props.children}</ColoredText>
}

export function TextWithIcon(props: InferStyledComponentProps<typeof TextWithIconWrapper>) {
	return (
		<TextWithIconWrapper {...props}>
			<Flexed justify={props.centered ? "center" : "flex-start"} align="flex-start">
				<span>
					<TextBody inline centered={props.centered} size="big" color={props.color}>
						<img alt="" src={props.icon} />
						{props.children}
					</TextBody>
				</span>
			</Flexed>
		</TextWithIconWrapper>
	)
}
