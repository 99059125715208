import DataFile from "@assets/lotties/curve-lower-2.json"
import Lottie, { LottieRefCurrentProps } from "lottie-react"
import React, { useEffect, useRef } from "react"

export const LowerLineLottie2 = ({
	isStopped,
	z,
	onFinished,
	opacity,
}: {
	isStopped: boolean
	z?: number
	onFinished?: () => void
	opacity: number
}) => {
	const lottieRef = useRef<LottieRefCurrentProps>(null)

	const lottieStyle: React.CSSProperties = {
		position: "absolute",
		left: "0px",
		bottom: "0px",
		zIndex: z ?? 30,
		width: "100%",
		height: "auto",
		opacity: opacity,
		transition: "opacity 0.3s ease-in-out",
	}

	useEffect(() => {
		if (!isStopped && lottieRef.current) {
			lottieRef.current.play()
		}
	}, [isStopped, lottieRef])

	return (
		<Lottie
			lottieRef={lottieRef}
			animationData={DataFile}
			renderer={"svg"}
			loop={false}
			autoplay={false}
			rendererSettings={{
				preserveAspectRatio: "xMidYMid meet",
			}}
			onComplete={onFinished}
			style={lottieStyle}
		/>
	)
}
