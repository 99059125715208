import styled from "styled-components"
import { StylableProps } from "~/utils/types"
import { ITextProps, TextBody } from "../Text"

export const Footer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	margin: 44px 0 126px 0;
`
export const AgreementsLink = styled.a`
	color: #1c1b47;
	cursor: pointer;
	text-decoration: none;
	&:link,
	&:visited {
		color: #1c1b47;
	}
`
function AgreementsLinksBody(props: ITextProps & StylableProps) {
	return <TextBody size="small" centered {...props} />
}
export const AgreementsLinks = styled(AgreementsLinksBody)`
	line-height: 22px;
	width: 100%;
	pointer-events: auto;
	a:not(:last-child) {
		margin-right: 16px;
	}
`

export const Copyrights = styled(TextBody).attrs({
	size: "small",
	centered: true,
})`
	line-height: 26px;
	width: 100%;
	color: #828691;
`
