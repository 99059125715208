import React from "react"
import styled from "styled-components"

import { Flexed } from "../Flexed"
import { TextBody } from "../Text"

export interface IPrice {
	title: string
	oldPrice: string
	newPrice: string
}

export interface IPricesProps {
	items: IPrice[]
	color?: string
}

const Wrapper = styled.div<{ length: number }>`
	width: 100%;
	height: auto;
	padding: 0;

	> div {
		width: 100%;
		height: auto;
		padding: 14px 20px;
		margin: 0;
		box-sizing: border-box;

		box-shadow: inset 0px 1px 0px #ebebeb;

		> p {
			/* border: 2px solid red; */
			@media (max-width: 320px) {
				font-size: 16px !important;
				line-height: 24px;
				margin: 0;
			}
		}

		> div {
			/* border: 2px solid red; */
			text-align: right;
			float: right;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}

		> div > p {
			/* border: 2px solid green;
			float: right;
			text-align: right;
			:first-child {
				border: 2px solid blue;
				text-align: right;
				float: right;
				clear: both;
			} */
			margin: 0 0 0 8px;
			@media (max-width: 320px) {
				font-size: 16px !important;
				line-height: 24px;
			}
		}

		:first-child {
			box-shadow: none;
		}
	}
`

const Title = styled(TextBody)`
	width: 50%;
`

const OldPrice = styled.span`
	text-decoration: line-through;
`

export function Prices(props: IPricesProps) {
	return (
		<Wrapper length={props.items.length}>
			{props.items.map((price) => (
				<Flexed key={price.title} align="flex-start">
					<Title margin="0" color={props.color || "#828691"} size="big">
						{price.title}
					</Title>
					<Flexed width="45%">
						<TextBody color={props.color || "#828691"} size="big">
							{price.newPrice}
						</TextBody>
						<TextBody size="big" color="rgba(130,134,145,0.4)">
							<OldPrice>{price.oldPrice}</OldPrice>
						</TextBody>
					</Flexed>
				</Flexed>
			))}
		</Wrapper>
	)
}
