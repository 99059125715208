import { callFirebaseFunction } from "./call-firebase-function"
import { RESPONSE_CODE } from "./response-codes"

export interface SendSignInOTPParams {
	email?: string
	send?: "LINK" | "CODE"
	redirect?: string
	lang?: string
}
export interface SendSignInOTPResponse {
	// This sub enum helps us to control possible error codes returns by the function
	// and provide front-end developers with consitent data.
	code:
		| RESPONSE_CODE.FORBIDDEN
		| RESPONSE_CODE.BAD_REQUEST
		| RESPONSE_CODE.USER_NOT_EXIST
		| RESPONSE_CODE.TOO_MANY_REQUESTS
		| RESPONSE_CODE.OTP_SENT
		| RESPONSE_CODE.UNEXPECTED_ERROR

	message: string

	data?: {
		waitTime?: number
	}
}

/**
 * Mails to provided email a one time password (code or link) to sign in and verify a user.
 * User should exist and no one user should not to be signed in.
 * If user does not exist or any one is already signed in one time password will not be sent.
 * If one time password sent successfully the return code is RESPONSE_CODE.OTP_SENT = 201
 * Process these responces and in other cases - throw an error.
 *  RESPONSE_CODE.USER_NOT_EXIST
 *  RESPONSE_CODE.TOO_MANY_REQUESTS
 *  RESPONSE_CODE.OTP_SENT
 */
export const sendSignInOTP = async (params: SendSignInOTPParams): Promise<SendSignInOTPResponse> => {
	const response = await callFirebaseFunction<SendSignInOTPParams, SendSignInOTPResponse>("sendSignInOTP", params)

	return response
}
