import DesktopLine3 from "@assets/DesktopLine3.svg"
import DesktopLine4 from "@assets/DesktopLine4.svg"
import { ActionButton } from "@components/buttons"
import { Trans } from "@lingui/macro"
import { useCallback, useEffect, useRef } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { Quote } from "../components/quote"
import { IQuoteScreenProps } from "./quote-screen"
import {
	DesktopScreen,
	DesktopShadow,
	DesktopLineWrapper1,
	DesktopLineWrapper2,
	DesktopQuote,
	Mask,
} from "./quote-screen.styles"

export const QuoteDesktopScreen = ({ background, label, author, onNext }: IQuoteScreenProps) => {
	const size = useWindowLayout()

	const titleRef = useRef<HTMLDivElement>(null)
	const buttonRef = useRef<HTMLDivElement>(null)
	const desktopQuoteRef = useRef<HTMLDivElement>(null)
	const desktopButtonWrapperRef = useRef<HTMLDivElement>(null)
	const desktopButtonRef = useRef<HTMLButtonElement>(null)
	const desktopLine1Ref = useRef<HTMLDivElement>(null)
	const desktopLine2Ref = useRef<HTMLDivElement>(null)

	const onTitleAnimationFinished = useCallback(() => {
		if (buttonRef.current) {
			buttonRef.current.style.opacity = "1"
			// until now the button is disabled
			// as we do not want user to press the button
			// until it appears
			buttonRef.current.style.pointerEvents = "auto"
		}
	}, [buttonRef])

	useEffect(() => {
		setTimeout(() => {
			if (desktopQuoteRef.current) {
				desktopQuoteRef.current.style.opacity = "1"
			}
			if (desktopLine1Ref.current && desktopLine2Ref.current) {
				desktopLine1Ref.current.style.opacity = "1"
				desktopLine2Ref.current.style.opacity = "1"
			}
		}, 700)
		setTimeout(() => {
			if (desktopButtonWrapperRef.current) {
				desktopButtonWrapperRef.current.style.bottom = "0"
			}
			if (desktopButtonRef.current) {
				desktopButtonRef.current.style.opacity = "1"
			}
		}, 800)
	}, [])

	return (
		<DesktopScreen>
			<Mask>
				<img alt="" src={background} />
				<DesktopLineWrapper1 ref={desktopLine1Ref}>
					<img alt="" src={DesktopLine3} />
				</DesktopLineWrapper1>
				<DesktopLineWrapper2 ref={desktopLine2Ref}>
					<img alt="" src={DesktopLine4} />
				</DesktopLineWrapper2>
				<DesktopQuote ref={desktopQuoteRef}>
					<Quote
						size={size}
						z={9000}
						ref={titleRef}
						quote={label}
						author={author}
						onFinish={onTitleAnimationFinished}
					/>
				</DesktopQuote>
			</Mask>
			<DesktopShadow ref={desktopButtonWrapperRef}>
				<ActionButton ref={desktopButtonRef} size={size} onClick={onNext}>
					<Trans>Continue</Trans>
				</ActionButton>
			</DesktopShadow>
		</DesktopScreen>
	)
}
