import { useCallback } from "react"
import styled, { css } from "styled-components"

const DivBackgroundScrollable = styled.div<{
	backgroundColor?: string
	showScrollbars?: boolean
	z?: number
}>`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: calc(100% - 100px);
	padding: 0;
	margin: 0;

	overflow-y: scroll;
	${({ showScrollbars }) =>
		showScrollbars
			? ""
			: css`
					&::-webkit-scrollbar {
						width: 0;
						height: 0;
					}

					&::-webkit-scrollbar-track {
						background: none;
					}

					&::-webkit-scrollbar-thumb {
						background: none;
						outline: none;
						border-radius: 50px;
					}
			  `}

	${({ backgroundColor }) =>
		backgroundColor
			? css`
					background: ${backgroundColor};
			  `
			: ""};

	z-index: ${({ z }) => z ?? 100};

	box-sizing: border-box;
`
const DivBackgroundChildrenContainer = styled.div<{
	padding?: string
	backgroundColor?: string
	alignItems?: string
	justifyContent?: string
}>`
	position: relative;

	width: auto;
	height: auto;
	min-height: 100%;
	padding: ${({ padding }) => padding ?? 0};
	margin: 0;

	${({ backgroundColor }) =>
		backgroundColor
			? css`
					background: ${backgroundColor};
			  `
			: ""};

	display: flex;
	flex-direction: column;
	align-items: ${({ alignItems }) => alignItems ?? "center"};
	justify-content: ${({ justifyContent }) => justifyContent ?? "flex-start"};

	box-sizing: border-box;
`

export interface IContentScrollable {
	onScroll?: (offsetTop: number) => void
	children: JSX.Element | JSX.Element[]

	padding?: string
	backgroundColor?: string
	alignItems?: string
	justifyContent?: string
	showScrollbars?: boolean

	z?: number
}

export const ContentScrollable = ({
	onScroll,
	children,

	padding,
	backgroundColor,
	alignItems,
	justifyContent,
	showScrollbars,
	z,
}: IContentScrollable) => {
	const onScrollLocal = useCallback(() => {
		const divBackgroundScrollable = document.getElementById("divBackgroundScrollable")
		if (divBackgroundScrollable && !!onScroll) {
			onScroll(divBackgroundScrollable.scrollTop)
		}
	}, [onScroll])

	return (
		<DivBackgroundScrollable
			id="divBackgroundScrollable"
			onScroll={onScrollLocal}
			showScrollbars={showScrollbars}
			backgroundColor={backgroundColor}
			z={z}
		>
			<DivBackgroundChildrenContainer
				backgroundColor={backgroundColor}
				padding={padding}
				alignItems={alignItems}
				justifyContent={justifyContent}
			>
				{children}
			</DivBackgroundChildrenContainer>
		</DivBackgroundScrollable>
	)
}
