import { Trans } from "@lingui/macro"
import styled from "styled-components"
import AndroidIcon from "~/assets/Android.svg"
import IosIcon from "~/assets/BlackIos.svg"
import BlueStars4 from "~/assets/BlueStars4.svg"
import BlueStars5 from "~/assets/BlueStars5.svg"
import { Bubble } from "../Bubble"
import { Flexed } from "../Flexed"
import { TextBody } from "../Text"

export interface IPlatformMarketProps {
	platform: "ios" | "android"
}

const Wrapper = styled.div`
	width: 100%;
	box-sizing: border-box;

	p {
		display: inline;
		margin: 4px 0;
	}

	img {
		margin-top: 4px;
	}

	@media (orientation: landscape) {
		width: 50%;
	}
`

const Icon = styled.img`
	margin-top: 0 !important;
	margin-right: 12px;
`

const MainInfo = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 40px;
	line-height: 38px;

	letter-spacing: -0.04em;
`

const MainRatingInfo = styled(MainInfo)`
	color: #bcbec3;
	font-size: 24px;
`

const Rating = styled.span`
	font-size: 40px;
	color: #6866da;
`

const Subtitle = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 22px;

	color: #828691;
`

const platformsData = {
	ios: {
		title: "App Store",
		icon: IosIcon,
		stars: BlueStars5,
		rating: "4.7",
		reviews: "65K",
		downloads: "10M+",
		fiveStar: "50K",
	},
	android: {
		title: "Google Play",
		icon: AndroidIcon,
		stars: BlueStars4,
		rating: "4.3",
		reviews: "170K",
		downloads: "10M+",
		fiveStar: "100K",
	},
}

export function PlatformMarket(props: IPlatformMarketProps) {
	return (
		<Wrapper>
			<Bubble height={262} padding="16px 20px 24px">
				<Flexed direction="column" justify="space-between" align="flex-start" height="262px">
					<Flexed justify="flex-start">
						<Icon alt="" src={platformsData[props.platform].icon} />
						<TextBody size="big">{platformsData[props.platform].title}</TextBody>
					</Flexed>
					<Flexed direction="row" align="flex-start">
						<Flexed direction="column" align="flex-start" width="50%">
							<MainRatingInfo>
								<Rating>{platformsData[props.platform].rating}</Rating>/5
							</MainRatingInfo>
							<img alt="" src={platformsData[props.platform].stars} />
						</Flexed>
						<Flexed direction="column" align="flex-start" width="50%">
							<MainInfo>{platformsData[props.platform].downloads}</MainInfo>
							<Subtitle>
								<Trans>Downloads</Trans>
							</Subtitle>
						</Flexed>
					</Flexed>
					<Flexed direction="row" align="flex-start">
						<Flexed direction="column" align="flex-start" width="50%">
							<MainInfo>{platformsData[props.platform].reviews}</MainInfo>
							<Subtitle>
								<Trans>Reviews</Trans>
							</Subtitle>
						</Flexed>
						<Flexed direction="column" align="flex-start" width="50%">
							<MainInfo>{platformsData[props.platform].fiveStar}</MainInfo>
							<Subtitle>
								<Trans>5-star reviews</Trans>
							</Subtitle>
						</Flexed>
					</Flexed>
				</Flexed>
			</Bubble>
		</Wrapper>
	)
}
