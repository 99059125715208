import { useEffect, useReducer } from "react"
import * as server from "../server-api"
import { RESPONSE_CODE } from "../server-api/response-codes"
import * as localStorage from "../utils/local-storage"
import { ProgressStatus } from "./progress-status"

export const EMAIL_FOR_SIGN_IN = "USE_EMAIL_FOR_SIGN_IN"

export interface State {
	status: ProgressStatus
	response?: server.SendSignInOTPResponse
}

const initialState: State = {
	status: ProgressStatus.START,
}

enum ACTION {
	STARTED,
	FINISHED,
}

const reducer = (
	prevState: State,
	action:
		| {
				type: ACTION.STARTED
		  }
		| {
				type: ACTION.FINISHED
				payload: server.SendSignInOTPResponse
		  }
) => {
	switch (action.type) {
		case ACTION.STARTED:
			return {
				...prevState,
				status: ProgressStatus.WORKING,
			}
		case ACTION.FINISHED:
			return {
				...prevState,
				status: ProgressStatus.DONE,
				response: action.payload,
			}
	}
}

/**
 * Hook for mailing a one time password (code or link) to verify user email and then sign in.
 * User should exist and no one user should be signed in.
 * If user does not exist or any one is already signed in one time password will not be sent.
 * If one time password sends successfully the return code is RESPONSE_CODE.OTP_SENT = 201
 * You will need to process 3 response codes, in other cases throw an error.
 * Do not forget to watch after data.waitTime in case 1 and 3.
 * 1. RESPONSE_CODE.OTP_SENT
 * 2. RESPONSE_CODE.USER_NOT_EXIST
 *  3. RESPONSE_CODE.TOO_MANY_REQUESTS
 */
export const useSendSignInOTP = (
	onSuccess?: (response?: server.SendSignInOTPResponse) => void,
	onError?: (response?: server.SendSignInOTPResponse) => void
) => {
	const [sendSignInOTPState, dispatch] = useReducer(reducer, initialState)

	const sendSignInOTP = async (email: string, send: "LINK" | "CODE", callbackPagePath?: string) => {
		try {
			dispatch({ type: ACTION.STARTED })
			const response = await server.sendSignInOTP({
				email,
				send,
				redirect: send === "LINK" ? `${process.env.REACT_APP_FIREBASE_AUTH_CALLBACK_DOMAIN}${callbackPagePath}` : "",
				lang: "EN",
			})
			if (response.code === RESPONSE_CODE.OTP_SENT) {
				await localStorage.saveToLocalStorage(EMAIL_FOR_SIGN_IN, email)
			}
			dispatch({ type: ACTION.FINISHED, payload: response })
		} catch (err) {
			dispatch({
				type: ACTION.FINISHED,
				payload: {
					code: RESPONSE_CODE.UNEXPECTED_ERROR,
					message: `Unexpected error while signInWithOTP ${err.message ?? ""}`,
				},
			})
		}
	}

	useEffect(() => {
		if (sendSignInOTPState.response) {
			if (sendSignInOTPState.response.code === RESPONSE_CODE.OTP_SENT) {
				if (onSuccess) {
					onSuccess(sendSignInOTPState.response)
				}
			} else {
				if (onError) {
					onError(sendSignInOTPState.response)
				}
			}
		}
	}, [sendSignInOTPState.response, onSuccess, onError])

	return {
		sendSignInOTP,
		sendSignInOTPState,
	}
}
