// import { firestore } from "~/services/firebase/firebase-app"

import { firestore } from "~/services/firebase/firebase-app"

/**
 * Reads the document referred to by this `collectionPath` and `documentPath`
 * Returns null if document does not exist.
 * @param collectionPath A slash-separated path to a collection.
 * @param documentPath A slash-separated path to a document.
 */
export const getFirestoreDocument = async <T>(collectionPath: string, documentPath: string) => {
	const collectionRef = firestore.collection(collectionPath)
	const document = await collectionRef.doc(documentPath).get()

	if (document.exists) {
		return document.data() as T
	}

	return null
}
